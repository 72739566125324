<template>
  <div class="select__info">
    <div
      v-if="label"
      class="info__label"
    >
      {{ label }}
    </div>

    <div
      v-if="error"
      class="info__error"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'select-label',

  props: {
    error: {
      type: Boolean,
      default: false,
    },

    errorMessage: {
      type: String,
      default: '',
    },

    label: {
      type: String,
      required: false,
    },
  },
}
</script>

<style lang="stylus" scope>
  .select__info
    display: flex
    justify-content: space-between
    align-items: center

    .info__label
      color: $sh-dark-5
      font-size: 0.9rem

    .info__error
      color: $sh-red-2
      font-size: 0.8rem
</style>
