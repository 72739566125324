import {projectChannel} from '../../../../../socket'

// colors in rgb
const green = [53,155,54]
const superLightGreen = [174,200,174]
const blue = [52,148,152]
const superLightBlue = [179,219,219]
const yellow = [253,201,86]
const red = [220,80,62]
const gray = [169,169,169]

// color in quasar variables
const fullySupported = 'sh-bar-fully'
const moderateSupport = 'sh-bar-moderate'
const workaroundSupport = 'sh-bar-workaround'
const additionalSupport = 'sh-bar-additional'
const integrationSupport = 'sh-bar-integration'
const customSupport = 'sh-bar-custom'
const notSupported = 'sh-bar-not'
const answerNo = 'sh-bar-no'
const answerYes = 'sh-bar-yes'

const state = {
  productDetailsPage: false,
  implementationOverview: {},
  supportMatrix: [
    {
      score: 100,
      description: 'This feature is comprehensively supported out of the box with industry-leading capabilities and is immediately available after installation, without needing any additional modules, integrations, or custom development.',
      text: 'Fully Supported Out of the Box',
      slug: 'fully_supported_out_of_the_box',
      color: green,
      quasarColor: fullySupported,
      chartsGroupKey: null
    },
    {
      score: 85,
      description: 'This feature is moderately supported out of the box and is immediately available after installation, without needing any additional modules, integrations, or custom development.',
      text: 'Moderately Supported Out of the Box',
      slug: 'moderately_supported_out_of_the_box',
      color: superLightGreen,
      quasarColor: moderateSupport,
      chartsGroupKey: null
    },
    {
      score: 70,
      description: 'This feature is not directly available in the software but can be accomplished using other built-in features or any other workarounds, without any additional cost.',
      text: 'Supported with Workarounds',
      slug: 'supported_with_workarounds',
      color: blue,
      quasarColor: workaroundSupport,
      chartsGroupKey: null
    },
    {
      score: 60,
      description: 'This feature is available through additional modules or products from the vendor at an additional cost.',
      text: 'Supported with Additional Modules',
      slug: 'supported_with_additional_modules',
      color: superLightBlue,
      quasarColor: additionalSupport,
      chartsGroupKey: null
    },
    {
      score: 50,
      description: 'This feature is available through additional integrations, plugins, or marketplace applications from a third-party vendor at an additional cost.',
      text: 'Supported with Partner Integrations',
      slug: 'supported_with_partner_integrations',
      color: yellow,
      quasarColor: integrationSupport,
      chartsGroupKey: null
    },
    {
      score: 25,
      description: 'This feature is not built-in, and cannot be added by purchasing additional modules or integrations, but can be custom developed using the APIs, libraries, extensions, and development framework supported by the software, with or without any additional cost.',
      text: 'Supported with Custom Development',
      slug: 'supported_with_custom_development',
      color: red,
      quasarColor: customSupport,
      chartsGroupKey: null
    },
    {
      score: 0,
      description: 'This feature is not supported.',
      text: 'Not Supported',
      slug: 'not_supported',
      color: gray,
      quasarColor: notSupported,
      chartsGroupKey: null
    },
    {
      score: 100,
      description: 'This requirement accurately describes the product or vendor.',
      text: 'Yes',
      slug: 'yes',
      color: green,
      quasarColor: answerYes,
      chartsGroupKey: null
    },
    {
      score: 0,
      description: 'This requirement DOES NOT accurately describe the product or vendor.',
      text: 'No',
      slug: 'no',
      color: red,
      quasarColor: answerNo,
      chartsGroupKey: null
    }
  ],

  priorityMatrix: [
    {
      value: 2,
      label: 'High priority',
      color: '#359b36'
    },
    {
      value: 1,
      label: 'Medium priority',
      color: '#F6BD42'
    },
    {
      value: 0,
      label: 'Low priority',
      color: '#D24E2E'
    }
  ],

  completeScorecardStickyClosedProducts: []
}

const actions = {
  calculateImplementationData ({ commit, state, getters, rootGetters, rootState }) {
    return new Promise ((resolve, reject) => {
      const scorecardProductSHIds = rootGetters.scorecardProductSHIds

      // Structure of implementationData
      // {
      //  prodSHId: {
      //   groupId: {
      //     subGroupId: {
      //       answerSlug: count,
      //       answerSlug: count
      //     }
      //     answerSlug: count,
      //     answerSlug: count
      //   }
      //   answerSlug: count,
      //   answerSlug: count
      //  }
      // }
      const implementationData = {}
      scorecardProductSHIds.forEach(prodSHId => {
        const productAnswers = getters.getProductAnswers(prodSHId)
        let answersAcc = {}
        let answersByGroupsAcc = {}
        for (const productAnswerKey in productAnswers) {
          if (!productAnswers.hasOwnProperty(productAnswerKey)) {
            return
          }

          const answerObj = productAnswers[productAnswerKey]
          // vendor_score.group_key
          const chartsGroupKey = answerObj[3]
          const answerSlug = answerObj.answerSlug
          commit('setSupportItemGroupKey', {chartsGroupKey, answerSlug})

          if (chartsGroupKey === rootState.constants.PRODUCT_DETAILS_CHARTS_GROUP_KEY) {

            if (!answersAcc[answerObj.answerSlug]) {
              answersAcc[answerObj.answerSlug] = 0
            }

            answersAcc[answerObj.answerSlug]++

            // Check if answersByGroupsAcc has groupId key
            if (!answersByGroupsAcc[answerObj.group.id]) {
              answersByGroupsAcc[answerObj.group.id] = {}
            }

            // Check if answersByGroupsAcc->groupId has subGroupId key
            if (!answersByGroupsAcc[answerObj.group.id][answerObj.subGroup.id]) {
              answersByGroupsAcc[answerObj.group.id][answerObj.subGroup.id] = {}
            }

            // Check if answersByGroupsAcc->groupId has answerSlug key
            if (!answersByGroupsAcc[answerObj.group.id][answerObj.answerSlug]) {
              answersByGroupsAcc[answerObj.group.id][answerObj.answerSlug] = 0
            }
            answersByGroupsAcc[answerObj.group.id][answerObj.answerSlug]++

            // Check if answersByGroupsAcc->groupId->subGroupId has answerSlug key
            if (!answersByGroupsAcc[answerObj.group.id][answerObj.subGroup.id][answerObj.answerSlug]) {
              answersByGroupsAcc[answerObj.group.id][answerObj.subGroup.id][answerObj.answerSlug] = 0
            }
            answersByGroupsAcc[answerObj.group.id][answerObj.subGroup.id][answerObj.answerSlug]++
          }
        }


        implementationData[prodSHId] = Object.assign({}, answersByGroupsAcc, answersAcc)
      })
      commit('setImplementationData', implementationData)
      resolve()
    })
  },

  markCompleteScorecardStickyClosed ({commit, state}, productSHId) {
    const productsArr = state.completeScorecardStickyClosedProducts.slice()
    productsArr.push(productSHId)
    projectChannel.push('project:contact_info:update', {complete_scorecard_closed_products: productsArr})
      .receive('ok', () => {
        commit('setCompleteScorecardStickyClosedProducts', productsArr)
      })
  }
}

const mutations = {
  setSupportItemGroupKey (state, {chartsGroupKey, answerSlug}) {
    let supportObjIndex = state.supportMatrix.findIndex(supportItem => {
      return supportItem.slug === answerSlug
    })

    if(supportObjIndex >= 0) {
      const keyLowerCase = chartsGroupKey.toLowerCase()
      state.supportMatrix[supportObjIndex].chartsGroupKey = keyLowerCase
    }
  },

  setProductDetailsPage (state, bool) {
    state.productDetailsPage = bool
  },

  setImplementationData (state, payload) {
    state.implementationOverview = payload
  },

  setCompleteScorecardStickyClosedProducts (state, productsSHIDs) {
    state.completeScorecardStickyClosedProducts = productsSHIDs
  }
}

const getters = {
  getProductRatingAverage: () => product => {
    let rating = null
    if (product.extended_reviews_count) {
      rating = product.extended_rating_average
    }
    else {
      rating = product.rating_average
    }

    // Avoid trailing zeros by converting back to number by `+`
    return +rating.toFixed(2)
  },

  supportAnswersModel: (state, getters, rootState) => {
    const answersModel = []
    state.supportMatrix.forEach(supportObj => {
      if (supportObj.chartsGroupKey === rootState.constants.PRODUCT_DETAILS_CHARTS_GROUP_KEY) {
        answersModel.push({
          value: supportObj.score,
          label: supportObj.text,
          slug: supportObj.slug,
          color: supportObj.color,
          quasarColor: supportObj.quasarColor,
        })
      }
    })
    return answersModel
  },

  getProductAnswers: (state, getters, rootState) => productSHId => {
    let productAnswers = {}
    Object.keys(rootState.scorecard.scorecardAnswers)
      .filter(key => key.includes(productSHId))
      .forEach(key => {
        const answerObj = rootState.scorecard.scorecardAnswers[key]
        const requirementObj = rootState.requirements.all.find(req => req.pathKey === key.replace(`${productSHId}-`, ''))
        const subGroupObj = rootState.subGroups.all.find(subGroup => subGroup.id === requirementObj.subGroupId)
        const groupObj = rootState.groups.all.find(group => group.id === subGroupObj.groupId)
        const supportObj = state.supportMatrix.find(supportObj => supportObj.text === answerObj[1])
        const answerSlug = supportObj ? supportObj.slug : ''
        productAnswers[key] = Object.assign({}, answerObj, { group: groupObj }, { subGroup: subGroupObj }, { answerSlug: answerSlug })
      })
    return productAnswers
  },

  getPieChartPercentageData: state => (model = [], values = []) => {
    const allCount = values.length

    return model.map(modelItem => {
      const count = values.filter(val => val === modelItem.value).length
      return {
        label: modelItem.label,
        color: modelItem.color,
        value: modelItem.value,
        count: count,
        percentage: Math.round(count / allCount * 100)
      }
    })
  }
}

export default {
  actions,
  mutations,
  state,
  getters
}
