import { mapNotification } from '../config/notificationsConfig'

export default {
  methods: {
    showProductRemovedNotification(productData, showDescription = true) {
      let message = `${productData.productName} was removed.`
      if (showDescription) {
        message += `<br>You will find it in the Add product list if needed.`
      }

      this.showNotification({ message: message, productSHId: productData.productSHId })
    },

    showNotification(params) {
      const notificationConfig = {
        timeout: 3000,
        title: '',
        type: 'info',
        undoButtonPlease: true
      }

      return mapNotification(Object.assign({}, params, notificationConfig))
    }
  }
}
