<template>
  <div class="button-cta__container">
    <a
      class="button-cta"
      :href="url"
      @click="handleClick"
    >
     <bookmark-icon class="bookmark__icon" />

      <div class="cta-label">
        {{categoryName}} Comparison Report
      </div>
    </a>

    <div class="tooltip__container">
      <div class="tip-helper"></div>
      
      Just drag this link to the bookmark bar.
    </div>
  </div>
</template>

<script>
import BookmarkIcon from '@/components/icons/Bookmark'

export default {
  name: 'bookmark-cta',

  components: {
    BookmarkIcon
  },

  created () {
    // SEO window.gon
    this.categoryName = window.gon.category_name
    window.addEventListener('ungated_leaderboard_data_ready', this.getSeoTopAlternativesFormProducts)
  },

  beforeDestroy () {
    window.removeEventListener('ungated_leaderboard_data_ready', this.getSeoTopAlternativesFormProducts)
  },

  data () {
    return {
      url: null,
      categoryName: null
    }
  },

  methods: {
    getSeoTopAlternativesFormProducts () {
      const topAlternativesForm = document.getElementById('top-alternatives-form')

      if (topAlternativesForm) {
        const formData = new FormData(topAlternativesForm)
        const altProdsQuery = Array.from(formData)
          .filter(e => e[0].includes('alt_prod'))
          .map(e => e.join('='))
          .join('&')

        let joinSign = "?"
        if (window.location.search.length) {
          joinSign = "&"
        }

        this.url = [window.location.href, altProdsQuery].join(joinSign)
      }
    },

    handleClick (e) {
      e.preventDefault()
    }
  }
}
</script>

<style lang="stylus" scoped>
@import '~variables'

.button-cta__container
  position: relative
  margin-right: 1vw

  .bookmark__icon
    width: 1.4vw

  .button-cta
    display: flex
    align-items: center
    justify-content: center
    height: 2vw
    width: 2vw
    background-color: $sh-dark !important
    border-radius: 100%
    cursor: pointer
    
    &:hover
      +.tooltip__container
        display: block

    >>>path
      fill: #fb9237

    .cta-label
      height: 0
      width: 0
      overflow: hidden

.tooltip__container
  display: none
  position: absolute
  right: 0
  top: 2.2em
  height: auto
  width: 20vw
  background-color: white
  padding: 0.7em 0.8vw
  color: #000000
  border-radius: 0.3em
  z-index: 102

  .tip-helper
    width: 100%
    position: absolute
    top: 0

    &:after
      top: -1.2em
      right: 6%
      border: solid transparent
      content: " "
      height: 0
      width: 0
      position: absolute
      pointer-events: none

    &:after
      border-color: rgba(255, 255, 255, 0)
      border-bottom-color: #ffffff
      border-width: 0.6em
      margin-left: -0.3em
</style>
