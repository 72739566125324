import Vue from 'vue'
import { templateChannel } from '../../../../../socket'
import constants from 'src/store/modules/constants'

export const state = {
  requirementTemplate: {
    id: 0,
    description: null,
    included: true,
    position: 0,
    priority: 0,
    subGroupId: null,
    title: null
  },

  all: [],
  dryRunMatchedRequirementsFromServerCount: null,

  initialLoad: true,
  sortOrder: constants.state.SCORECARD_SORT_ORDER_TITLE,
  displayRequirementsExpandSettingObject: {},
  displayRequirementsExpandSetting: constants.state.REQUIREMENT_EXPAND,
  displayRequirementsExpandOptions: [
    {
      label: 'Expanded view',
      value: constants.state.REQUIREMENT_EXPAND,
      checkedIcon: 'radio_button_checked'
    },
    {
      label: 'Collapsed view',
      value: constants.state.REQUIREMENT_COLLAPSE,
      checkedIcon: 'radio_button_checked'

    }
  ]
}

export const actions = {
  downloadRequirements ({state, commit}) {
    return new Promise((resolve, reject) => {
      templateChannel.push('requirement:load', [])
        .receive('ok', data => {
          const downloadedReqs = data.requirements.map(req => {
            return {
              id: req.id,
              createdBySH: req.recommended_by_selecthub,
              description: req.description,
              included: req.included,
              position: req.position,
              priority: req.priority,
              subGroupId: req.group_id,
              title: req.name,
              pathKey: req.path_key
            }
          })
          commit('loadRequirements', downloadedReqs)

          // Simulating templateChannel.once
          if (state.initialLoad) {
            templateChannel.on('requirements:included', data => {
              commit('updateIncluded', data.included)
            })
          }
          commit('_markJoin')
          resolve()
        })
        .receive('error', reason => {
          console.log('Unable to download requirements', reason)
          reject(reason)
        })
    })
  },

  createRequirement ({commit}, newRequirement) {
    return new Promise((resolve, reject) => {
      templateChannel.on('requirement:created', (payload) => {
        templateChannel.off('requirement:created')
        Object.assign(newRequirement, {
          id: payload.id,
          description: payload.description,
          included: payload.included,
          position: payload.position,
          priority: payload.priority,
          subGroupId: payload.group_id,
          title: payload.name
        })
        resolve()
      })
      templateChannel.on('requirement:created:errors', (errors) => {
        templateChannel.off('requirement:created:errors')
        console.log(errors)
        reject(new Error(errors))
      })

      templateChannel.push(
        'requirement:create', {
          description: newRequirement.description,
          group_id: newRequirement.subGroupId,
          included: newRequirement.included,
          position: newRequirement.position,
          priority: newRequirement.priority,
          name: newRequirement.title
        })
    })
  },

  updateRequirement ({commit}, userPayload) {
    const editedRequirement = userPayload.newRequirement
    const oldRequirement = userPayload.oldRequirement
    return new Promise((resolve, reject) => {
      templateChannel.on('requirement:updated', (payload) => {
        templateChannel.off('requirement:updated')
        const updatedRequirement = {
          id: payload.id,
          createdBySH: payload.recommended_by_selecthub,
          description: payload.description,
          included: payload.included,
          title: payload.name,
          position: payload.position,
          priority: payload.priority,
          subGroupId: payload.group_id,
          pathKey: payload.path_key
        }

        commit('replaceRequirement', {
          newRequirement: updatedRequirement,
          oldRequirement: oldRequirement
        })

        resolve(updatedRequirement)
      })

      templateChannel.on('requirement:updated:errors', (errors) => {
        templateChannel.off('requirement:updated:errors')
        console.log(errors)
        reject(new Error(errors))
      })

      templateChannel.push('requirement:update', {
        id: editedRequirement.id,
        description: editedRequirement.description,
        group_id: editedRequirement.subGroupId,
        included: editedRequirement.included,
        name: editedRequirement.title,
        priority: editedRequirement.priority,
        position: editedRequirement.position
      })
    })
  },

  dryRunUpdateMatchedRequirementCounters ({commit, getters}, count) {
    const newCount =
      count +
         getters.userCreatedIncludedRequirementsInIncludedSubGroupsInIncludedGroups.length
    commit('dryRunUpdateMatchedRequirementCounters', newCount)
  }
}

export const mutations = {
  addToRequirementList (state, requirement) {
    state.all.unshift(requirement)
  },

  removeFromRequirementList (state, requirement) {
    const index = state.all.indexOf(requirement)
    if (index !== -1) {
      state.all.splice(index, 1)
    }
  },

  loadRequirements (state, requirements) {
    state.all = requirements
  },

  replaceRequirement (state, payload) {
    const newRequirement = payload.newRequirement
    const oldRequirement = payload.oldRequirement
    
    const oldRequirementIndex = state.all.findIndex(req => {
      return req.id === oldRequirement.id
    })
    Vue.set(state.all, oldRequirementIndex, newRequirement)

    if (payload.isCancel) {
      return true
    }

    // Please leave this commented - it can help restore tracking quicker in the future.
    // if (oldRequirement.priority !== newRequirement.priority) {
    //   window.heap.track(`Changed priority - ${oldRequirement.included ? 'included' : 'excluded'}`)
    // }

    if (!oldRequirement.createdBySH) {
      return true
    }

    // Track SH requirements changes
    // Please leave this commented - it can help restore tracking quicker in the future.
    // window.heap.track(`Edited SH requirement - ${oldRequirement.included ? 'included' : 'excluded'}`)

    // if (oldRequirement.included && !newRequirement.included) {
    //   window.heap.track(`Excluded SH Requirement`)
    // }

    // if (!oldRequirement.included && newRequirement.included) {
    //   window.heap.track(`Included SH Requirement`)
    // }
  },

  updateIncluded (state, updatedIds) {
    state.all.forEach(requirement => {
      if (updatedIds.find(requirement.id)) {
        Vue.set(requirement, 'included', true)
      //
      } else {
        Vue.set(requirement, 'included', true)
      }
    })
  },

  dryRunUpdateMatchedRequirementCounters (state, count) {
    state.dryRunMatchedRequirementsFromServerCount = count
  },

  _markJoin (state) {
    state.initialLoad = false
  },

  setSortOrder (state, newSortOrder) {
    state.sortOrder = newSortOrder
  },

  changedRequirementsExpandSetting (state, setting) {
    state.displayRequirementsExpandSetting = setting
  },

  changeRequirementsExpandSettingObject (state, payload) {
    Vue.set(
      state.displayRequirementsExpandSettingObject,
      payload.subGroupId,
      payload.setting)
  }
}

export const getters = {
  requirementTemplate: (state) => (objAttrs = {id: 0}) => {
    return Object.assign({}, state.requirementTemplate, objAttrs)
  },

  findRequirement: state => (requirementId) => {
    return state.all.find(requirement => requirement.id === parseInt(requirementId))
  },

  requirementsForSubGroup: state => (subGroupId) => {
    return state.all.filter(requirement => requirement.subGroupId === parseInt(subGroupId))
  },

  requirementsForSubGroupSorted: state => (subGroupId) => {
    return state.all.filter(requirement =>
      requirement.subGroupId === parseInt(subGroupId)).sort((a, b) => {
      if (a.included && b.included) {
        return 0
      //
      } else if (a.included && !b.included) {
        return -1
      //
      } else {
        return 1
      }
    })
  },

  includedRequirementsInIncludedSubGroupsInIncludedGroups: (state, getters, rootState, rootGetters) => {
    const groupIds = rootGetters.includedGroups().map(g => g.id)
    const subGroupIds =
      [].concat(
        ...groupIds
          .map(gId => {
            return rootGetters
              .includedSubGroupsForGroup(gId)
              .map(sg => sg.id)
          }))
    return [].concat(
      ...subGroupIds.map(sbId => getters.includedRequirementsForSubGroup(sbId)))
  },

  includedRequirementsForSubGroup: state => (subGroupId) => {
    return state.all.filter(requirement =>
      requirement.included && requirement.subGroupId === parseInt(subGroupId))
  },

  excludedRequirementsForSubGroup: state => (subGroupId) => {
    return state.all.filter(requirement =>
      !requirement.included && requirement.subGroupId === parseInt(subGroupId))
  },

  requirementsForGroup: (state, getters, rootState, rootGetters) => (groupId) => {
    const subGroupIds = rootGetters.subGroupsForGroup(groupId).map(subGroup => subGroup.id)
    const requirementsMap = subGroupIds.map(id => getters.requirementsForSubGroup(id))
    return [].concat.apply([], requirementsMap)
  },

  // DISREGARDING SUBGROUP INCLUSION!
  includedRequirementsForGroup: (state, getters, rootState, rootGetters) => (groupId) => {
    const subGroupIds =
      rootGetters.subGroupsForGroup(groupId).map(sg => sg.id)
    return [].concat(
      ...subGroupIds.map(sbId => getters.includedRequirementsForSubGroup(sbId)))
  },

  includedRequirementsForGroupInIncludedSubGroups: (state, getters, rootState, rootGetters) => (groupId) => {
    const subGroupIds =
      rootGetters.includedSubGroupsForGroup(groupId).map(sg => sg.id)
    return [].concat(
      ...subGroupIds.map(sbId => getters.includedRequirementsForSubGroup(sbId)))
  },

  // DISREGARDING SUBGROUP INCLUSION!
  excludedRequirementsForGroup: (state, getters, rootState, rootGetters) => (groupId) => {
    const subGroupIds =
      rootGetters.subGroupsForGroup(groupId).map(sg => sg.id)
    return [].concat(
      ...subGroupIds.map(sbId => getters.excludedRequirementsForSubGroup(sbId)))
  },

  allRequirementsInIncludedGroups: (state, getters, rootState, rootGetters) => {
    const requirementsMap = rootGetters.includedGroups().map(group => {
      return getters.requirementsForGroup(group.id)
    })
    return [].concat.apply([], requirementsMap)
  },

  allRequirementsInExcludedGroups: (state, getters, rootState, rootGetters) => {
    const requirementsMap = rootGetters.excludedGroups().map(group => {
      return getters.requirementsForGroup(group.id)
    })
    return [].concat.apply([], requirementsMap)
  },

  includedRequirementsInIncludedGroups: (state, getters) => {
    return getters.allRequirementsInIncludedGroups.filter(r => r.included)
  },

  includedRequirementsInExcludedGroups: (state, getters) => {
    return getters.allRequirementsInExcludedGroups.filter(r => r.included)
  },

  allRequirementsInSubGroupWithoutOne: (state, getters) => (requirement) => {
    return getters
      .requirementsForSubGroup(requirement.subGroupId)
      .filter(r => r !== requirement)
  },

  userCreatedRequirements: (state) => {
    return state.all.filter(r => !r.createdBySH)
  },

  userCreatedIncludedRequirements: (state, getters) => {
    return getters.userCreatedRequirements.filter(r => r.included)
  },

  userCreatedIncludedRequirementsInIncludedSubGroupsInIncludedGroups: (state, getters, rootState, rootGetters) => {
    const includedSubGroupIds = rootGetters.includedSubGroupsInIncludedGroups.map(sG => sG.id)
    return getters.userCreatedIncludedRequirements.filter(r => includedSubGroupIds.find(iD => iD === r.subGroupId))
  },

  dryRunTotalRequirementsCount: (state) => {
    return state.all.length
  },

  dryRunMatchedRequirementsCount: (state, getters) => {
    return state.dryRunMatchedRequirementsFromServerCount
  },

  includedRequirementsCount: (state, getters) => {
    return getters.includedRequirementsInIncludedGroups.length
  },

  sortOrder (state) {
    return state.sortOrder
  },

  displayRequirementsExpandOptions (state) {
    return state.displayRequirementsExpandOptions
  },

  displayRequirementsExpandSetting (state) {
    return state.displayRequirementsExpandSetting
  },

  displayRequirementsExpandSettingObject (state) {
    return state.displayRequirementsExpandSettingObject
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
