<template>
  <div class="btn__container">
    <q-btn
      class="btn btn--margin"
      label="Remove from scorecard"
      icon-right="r_close"
      color="white"
      text-color="btn"
      padding="0.4em 0.7em"
      @click="$emit('click', shId)"
    />
  </div>
</template>

<script>
  export default {
    name: 'scorecard-remove-button',
    props: {
      shId: {
        type: Number,
        default: 0,
        required: true
      }
    }
  }
</script>

<style lang='stylus' scoped>
  .text-btn
    color: #727272

  .btn__container
    text-align: center

    .btn
      transition: color 0.1s
      text-transform: initial
      font-size: 0.7em
      &:hover,
      &:focus,
      &:active
        color: #000000
      &.btn--margin
        margin-bottom: 0.5em
</style>
