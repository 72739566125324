import { templateChannel } from '../../../../../socket'
import Vue from 'vue'

const sortGroups = (groups, sortField, sortDir) => {
  if (sortField === null) {
    sortField = 'position'
  }

  if (sortDir === null) {
    sortDir = 'ASC'
  }

  let sortMult = sortDir === 'ASC' ? -1 : 1

  return groups.sort((a, b) => {
    let res = (a[sortField] < b[sortField]) ? -1 : (a[sortField] > b[sortField]) ? 1 : 0

    if (sortField === 'name') {
      const aName = a[sortField].toLowerCase()
      const bName = b[sortField].toLowerCase()
      res = (aName < bName) ? -1 : (aName > bName) ? 1 : 0
    }
    return res * sortMult
  })
}

export const state = {
  groupTemplate: {
    id: 0,
    included: true,
    name: '',
    position: 0
  },

  all: [],

  displayGroups: 'included',

  displayGroupsOptions: [
    {
      label: 'Show all',
      value: 'all',
      checkedIcon: 'radio_button_checked'
    },
    {
      label: 'Show included only',
      value: 'included',
      checkedIcon: 'radio_button_checked'
    },
    {
      label: 'Show excluded only',
      value: 'excluded',
      checkedIcon: 'radio_button_checked'
    }
  ],

  displayGroupsChangedByUser: false,

  initialLoad: true,
  loading: true,
  groupListPage: false,
  groupWithSubGroupListPage: false
}

export const actions = {
  loadGroups ({commit, state}) {
    return new Promise((resolve, reject) => {
      templateChannel.push('group_container:load', [])
        .receive('ok', data => {
          commit('loadGroups', data.group_containers)

          // Simulating templateChannel.once
          if (state.initialLoad) {
            templateChannel.on('requirements:included', data => {
              commit('updateIncluded', data.included)
            })
          }

          commit('_markJoin')
          if (!state.displayGroupsChangedByUser) {
            // If there are any excluded groups...
            if (state.all.filter(g => !g.included).length) {
              commit('changeDisplayGroups', 'all')
            }
          }
          resolve()
        })

        .receive('error', reason => {
          console.log('Unable to download groups', reason)
          reject(reason)
        })
    })
  },

  createGroup ({commit}, newGroup) {
    return new Promise((resolve, reject) => {
      templateChannel.on('group_container:created', (payload) => {
        templateChannel.off('group_container:created')
        Object.assign(newGroup, {
          id: payload.id,
          included: payload.included,
          name: payload.name,
          position: payload.position
        })
        resolve()
      })
      templateChannel.on('group_container:created:errors', (errors) => {
        templateChannel.off('group_container:created:errors')
        console.log(errors)
        reject(new Error(errors))
      })

      templateChannel.push(
        'group_container:create', {
          included: newGroup.included,
          name: newGroup.name,
          position: newGroup.position
        })
    })
  },

  updateGroup ({commit}, userPayload) {
    const editedGroup = userPayload.newGroup
    const oldGroup = userPayload.oldGroup

    return new Promise((resolve, reject) => {
      templateChannel.on('group_container:updated', (payload) => {
        templateChannel.off('group_container:updated')
        const updatedGroup = {
          id: payload.id,
          created_by_selecthub: payload.created_by_selecthub,
          included: payload.included,
          name: payload.name,
          position: payload.position
        }

        commit('replaceGroup', {
          newGroup: updatedGroup,
          oldGroup: oldGroup
        })

        resolve(updatedGroup)
      })
      templateChannel.on('group_container:updated:errors', (errors) => {
        templateChannel.off('group_container:updated:errors')
        console.log(errors)
        reject(new Error(errors))
      })

      templateChannel.push('group_container:update', {
        id: editedGroup.id,
        included: editedGroup.included,
        name: editedGroup.name,
        position: editedGroup.position
      })
    })
  }
}

export const mutations = {
  markGroupListPage (state) {
    state.groupListPage = true
  },

  unmarkGroupListPage (state) {
    state.groupListPage = false
  },

  setGroupWithSubGroupListPage (state, bool) {
    state.groupWithSubGroupListPage = bool
  },

  addToGroupList (state, newGroup) {
    state.all.unshift(newGroup)
  },

  removeFromGroupList (state, group) {
    const index = state.all.indexOf(group)
    if (index !== -1) {
      state.all.splice(index, 1)
    }
  },

  changeDisplayGroups (state, groupScope) {
    state.displayGroups = groupScope
  },

  loadGroups (state, groups) {
    state.all = groups
    state.loading = false
  },

  replaceGroup (state, payload) {
    const newGroup = payload.newGroup
    const oldGroup = payload.oldGroup

    const oldGroupIndex = state.all.findIndex(group => {
      return group.id === oldGroup.id
    })
    Vue.set(state.all, oldGroupIndex, newGroup)

    if (payload.isCancel) {
      return true
    }

    if (!oldGroup.created_by_selecthub) {
      return true
    }

    // Track SH group changes
    // Please leave this commented - it can help restore tracking quicker in the future.
    // if (oldGroup.included && !newGroup.included) {
    //   window.heap.track(`Excluded SH Group`)
    // }

    // if (!oldGroup.included && newGroup.included) {
    //   window.heap.track(`Included SH Group`)
    // }

    // if (oldGroup.name !== newGroup.name) {
    //   window.heap.track(`Renamed SH Group - ${oldGroup.included ? 'included' : 'excluded'}`)
    // }
  },

  updateIncluded (state, updatedIds) {
    state.all.forEach(group => {
      if (updatedIds.find(group.id)) {
        Vue.set(group, 'included', true)
      }
      else {
        Vue.set(group, 'included', true)
      }
    })
  },

  markGroupsChangedByUser (state) {
    state.displayGroupsChangedByUser = true
  },

  _markJoin (state) {
    state.initialLoad = false
  }
}

export const getters = {
  displayGroupsOptions (state) {
    return state.displayGroupsOptions
  },

  groupListPage: (state) => {
    return state.groupListPage
  },

  groupTemplate: (state) => (objAttrs = {}) => {
    return Object.assign({}, state.groupTemplate, objAttrs)
  },

  allGroups: (state) => {
    return state.all
  },

  allGroupsSorted: state => (sortField, sortDir) => {
    return sortGroups(state.all, sortField, sortDir)
  },

  allGroupsSortedByTwoFields: (state, getters) => (sortFields, sortDirs) => {
    const sortedByFirst = getters.allGroupsSorted(sortFields[0], sortDirs[0])
    return sortGroups(sortedByFirst, sortFields[1], sortDirs[1])
  },

  allGroupsWithoutOne: (state, getters) => (group) => {
    return getters.allGroups.filter(g => g !== group)
  },

  activeGroup: (state, getters, rootState) => {
    const sidebarActiveRequirementGroupId = rootState.layoutStore.sidebarActiveRequirementGroupId
    return getters.findGroup(sidebarActiveRequirementGroupId)
  },

  findGroup: (state, getters) => (groupId) => {
    return getters.allGroups.find(group => group.id === parseInt(groupId))
  },

  findGroupBySubGroup: (state, getters, rootState, rootGetters) => (subGroupId) => {
    return new Promise((resolve, reject) => {
      const subGroup = rootGetters.findSubGroup(subGroupId)
      if (subGroup) resolve(getters.findGroup(subGroup.groupId))
      reject()
    })
  },

  includedGroups: (state, getters) => (sortField, sortDir) => {
    let groups = getters.allGroups.filter(item => item.included)
    return sortGroups(groups, sortField, sortDir)
  },

  includedGroupsWithRequirementsCount (state, getters) {
    const includedGroups = getters.includedGroups()
    return includedGroups.map(group => {
      const reqs = getters.includedRequirementsForGroupInIncludedSubGroups(group.id)
      const requirementsCount = {
        all: {
          reqs: reqs.length || 0,
        },
        high: {
          reqs: reqs.filter(req => req.priority === 2).length || 0,
          color: 'sh-bar-fully',
          trackColor: 'sh-bar-fully',
        },
        medium: {
          reqs: reqs.filter(req => req.priority === 1).length || 0,
          color: 'sh-circle-yellow',
          trackColor: 'sh-circle-yellow',
        },
        low: {
          reqs: reqs.filter(req => req.priority === 0).length || 0,
          color: 'sh-circle-red',
          trackColor: 'sh-circle-red',
        },
      }
      return Object.assign(group, { requirements_count: requirementsCount }, {})
    })
  },

  excludedGroups: (state, getters) => (sortField, sortDir) => {
    let groups = getters.allGroups.filter(item => !item.included)
    return sortGroups(groups, sortField, sortDir)
  },

  groupPieChartGroupIds: (state, getters) => {
    return getters.includedGroups().filter(g => g.pie_chart_enabled).map(g => g.id)
  },

  getPriorityChartData: (state, getters) => (priority) => {
    return getters.includedGroupsWithRequirementsCount
      .filter(groupWithReqs => groupWithReqs.requirements_count[priority].reqs > 0)
      .map(groupWithReqs => {
        return {
          label: groupWithReqs.name,
          value: Math.round(groupWithReqs.requirements_count[priority].reqs / groupWithReqs.requirements_count.all.reqs * 100),
          color: groupWithReqs.requirements_count[priority].color,
          trackColor: groupWithReqs.requirements_count[priority].trackColor,
        }
      })
      .sort((a, b) => b.value - a.value)
      .slice(0, 5)
  },

  barChartsData (state, getters) {
    return [
      {
        bars: getters.getPriorityChartData('high'),
        label: 'Requirements with high priority',
      },
      {
        bars: getters.getPriorityChartData('medium'),
        label: 'Requirements with medium priority',
      },
      {
        bars: getters.getPriorityChartData('low'),
        label: 'Requirements with low priority',
      },
    ]
  },

  displayGroups (state) {
    return state.displayGroups
  },

  loading (state) {
    return state.loading
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
