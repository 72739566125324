const state = {
  activeCtaType: null,
  all: [],
  ctaModel: {
    label: null,
    landing_page_url: null,
    text_primary: null,
    text_secondary: null
  },
  ctasPositions: {
    dropdown: [
      'dropdown_1',
      'dropdown_2'
    ],

    banner: [
      'banner_1',
      'banner_2'
    ],

    button: [
      'button_1'
    ],

    icon: [
      'icon_1'
    ],

    demo_price: [
      'demo_price_1'
    ]
  },
  modalLPUrl: null,
  modalLPUrlQuery: null,
  showCtaModal: false,
  shouldShowDemoPriceHeader: true,
  shouldShowSubmitButton: true
}

const mutations = {
  closeCtaModal (state) {
    state.showCtaModal = false
    state.modalLPUrl = null
    state.modalLPUrlQuery = null
    state.activeCtaType = null
    state.shouldShowDemoPriceHeader = true
    state.shouldShowSubmitButton = true
  },

  openCtaModal (state, cta) {
    const topAlternativesForm = document.getElementById('top-alternatives-form')
    if (topAlternativesForm) {
      const inputs = Array.from(topAlternativesForm.getElementsByTagName('input'))

      state.modalLPUrlQuery = inputs
        .filter(input => {
          const prodId = input.dataset.productId
          return (prodId && input.checked) || !prodId
        })
        .map(input => {
          return `${input.name}=${input.value}`
        }).join('&')
    }

    state.showCtaModal = true
    state.modalLPUrl = cta.landing_page_url
    state.activeCtaType = cta.type
  },

  setCtas (state, ctas) {
    state.all = ctas
  },

  setShouldShowDemoPriceHeader (state, bool) {
    state.shouldShowDemoPriceHeader = bool
  },

  setShouldShowSubmitButton (state, bool) {
    state.shouldShowSubmitButton = bool
  }
}

const getters = {
  getCtaByPosition: state => ctaPosition => {
    return state.all.find(cta => {
      return cta.position === ctaPosition
    }) || {...state.ctaModel, position: ctaPosition}
  }
}

export default {
  getters,
  mutations,
  state
}
