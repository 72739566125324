import Vue from 'vue'

import './styles/quasar.styl'
import 'quasar/dist/quasar.ie.polyfills'
import '@quasar/extras/material-icons-round/material-icons-round.css'

import {
  Quasar, 
  QBtn,
  QCard,
  QCardSection,
  QCheckbox,
  QIcon,
  QItem,
  QItemSection,
  QLinearProgress,
  QList,
  QScrollArea,
  QSelect,
  QSpinner
} from 'quasar'

Vue.use(Quasar, {
  config: {},
  components: {
    QBtn,
    QCard,
    QCardSection,
    QCheckbox,
    QIcon,
    QItem,
    QItemSection,
    QLinearProgress,
    QList,
    QScrollArea,
    QSelect,
    QSpinner
  },
  directives: {
  },
  plugins: {
  }
 })
 