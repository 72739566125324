import Vue from 'vue'
import Vuex from 'vuex'

import constants from './modules/constants'
import cards from './modules/cards/cards'
import cardsLayoutStore from './modules/cards/cards_layout_store'

import abTestingThemeStore from './modules/main_app/ab_testing_theme_store'
import callRequestStore from './modules/main_app/call_request_store'
import currentUser from './modules/main_app/current_user'
import layoutStore from './modules/main_app/layout_store'
import onboarding from './modules/main_app/onboarding'

import groups from './modules/main_app/requirements/groups'
import requirements from './modules/main_app/requirements/requirements'
import subGroups from './modules/main_app/requirements/subgroups'

import productReviews from './modules/main_app/product_list/product_reviews'
import suggestProductsModal from './modules/main_app/product_list/suggest_products_modal'
import products from './modules/main_app/product_list/products'

import productDetails from './modules/main_app/product_details/product_details'

import scorecard from './modules/main_app/scorecard/scorecard'

import projects from './modules/main_app/projects'
import projectModal from './modules/main_app/project_modal/project_modal'
import projectPreview from './modules/main_app/project_preview'

import headerTitles from './modules/main_app/header_titles'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    abTestingThemeStore,
    callRequestStore,
    cards,
    cardsLayoutStore,
    constants,
    currentUser,
    groups,
    headerTitles,
    layoutStore,
    onboarding,
    productDetails,
    productReviews,
    products,
    projectModal,
    projectPreview,
    projects,
    requirements,
    scorecard,
    subGroups,
    suggestProductsModal
  }
})
