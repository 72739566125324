// import socket from '../../../../socket'
import Vue from 'vue'
import { templateChannel } from '../../../../../socket'

export const state = {
  subGroupTemplate: {
    id: 0,
    groupId: 0,
    included: true,
    name: '',
    position: 0
  },

  all: [],

  displaySubGroupsOptions: [
    {
      label: 'Show all',
      value: 'all',
      checkedIcon: 'radio_button_checked'
    },
    {
      label: 'Show included only',
      value: 'included',
      checkedIcon: 'radio_button_checked'
    },
    {
      label: 'Show excluded only',
      value: 'excluded',
      checkedIcon: 'radio_button_checked'
    }
  ],
  displayGroupId: null,
  initialLoad: true
}

export const actions = {
  loadSubGroups ({commit}) {
    return new Promise((resolve, reject) => {
      templateChannel.push('group:load', [])
        .receive('ok', data => {
          const groupList =
            data.groups.map(group => {
              return {
                id: group.id,
                createdBySH: group.created_by_selecthub,
                included: group.included,
                name: group.name,
                groupId: group.group_container_id,
                position: group.position
              }
            })

          commit('loadSubGroups', groupList)

          // Simulating templateChannel.once
          if (state.initialLoad) {
            templateChannel.on('requirements:included', data => {
              commit('updateIncluded', data.included)
            })
          }
          commit('_markJoin')
          resolve()
        })
        .receive('error', reason => {
          console.log('Unable to download subgroups', reason)
          reject(reason)
        })
    })
  },

  createSubGroup ({commit}, newSubGroup) {
    return new Promise((resolve, reject) => {
      templateChannel.on('group:created', (payload) => {
        templateChannel.off('group:created')
        Object.assign(newSubGroup, {
          id: payload.id,
          included: payload.included,
          name: payload.name,
          position: payload.position,
          groupId: payload.group_container_id
        })
        resolve()
      })

      templateChannel.on('group:created:errors', (errors) => {
        templateChannel.off('group:created:errors')
        console.log(errors)
        reject(new Error(errors))
      })

      templateChannel.push(
        'group:create', {
          included: newSubGroup.included,
          name: newSubGroup.name,
          position: newSubGroup.position,
          group_container_id: parseInt(newSubGroup.groupId)
        })
    })
  },

  updateSubGroup ({commit}, userPayload) {
    const editedSubGroup = userPayload.newSubGroup
    const oldSubGroup = userPayload.oldSubGroup

    return new Promise((resolve, reject) => {
      templateChannel.on('group:updated', (payload) => {
        templateChannel.off('group:updated')
        const updatedSubGroup = {
          id: payload.id,
          createdBySH: payload.created_by_selecthub,
          included: payload.included,
          groupId: payload.group_container_id,
          name: payload.name,
          position: payload.position
        }

        commit('replaceSubGroup', {
          newSubGroup: updatedSubGroup,
          oldSubGroup: oldSubGroup
        })

        resolve(updatedSubGroup)
      })

      templateChannel.on('group:updated:errors', (errors) => {
        templateChannel.off('group:updated:errors')
        console.log(errors)
        reject(new Error(errors))
      })

      templateChannel.push('group:update', {
        id: editedSubGroup.id,
        included: editedSubGroup.included,
        group_container_id: editedSubGroup.groupId,
        name: editedSubGroup.name,
        position: editedSubGroup.position
      })
    })
  }
}

export const mutations = {
  changeDisplayGroupId (state, groupId) {
    state.displayGroupId = groupId
  },

  addToSubGroupList (state, newSubGroup) {
    state.all.unshift(newSubGroup)
  },

  removeFromSubGroupList (state, subGroup) {
    const index = state.all.indexOf(subGroup)
    if (index !== -1) {
      state.all.splice(index, 1)
    }
  },

  loadSubGroups (state, subGroups) {
    state.all = subGroups
  },

  replaceSubGroup (state, payload) {
    const newSubGroup = payload.newSubGroup
    const oldSubGroup = payload.oldSubGroup

    const oldSubGroupIndex = state.all.findIndex(subGroup => {
      return subGroup.id === oldSubGroup.id
    })
    Vue.set(state.all, oldSubGroupIndex, newSubGroup)

    if (payload.isCancel) {
      return true
    }

    if (!oldSubGroup.createdBySH) {
      return true
    }

    // Track SH group changes
    // Please leave this commented - it can help restore tracking quicker in the future.
    // if (oldSubGroup.included && !newSubGroup.included) {
    //   window.heap.track(`Excluded SH Sub-Group`)
    // }

    // if (!oldSubGroup.included && newSubGroup.included) {
    //   window.heap.track(`Included SH Sub-Group`)
    // }

    // if (oldSubGroup.name !== newSubGroup.name) {
    //   window.heap.track(`Renamed SH Sub-Group - ${oldSubGroup.included ? 'included' : 'excluded'}`)
    // }
  },

  updateIncluded (state, updatedIds) {
    state.all.forEach(subgroup => {
      if (updatedIds.find(subgroup.id)) {
        Vue.set(subgroup, 'included', true)
      }
      else {
        Vue.set(subgroup, 'included', false)
      }
    })
  },

  _markJoin (state) {
    state.initialLoad = false
  }
}

export const getters = {
  displaySubGroupsOptions (state) {
    return state.displaySubGroupsOptions
  },

  subGroupTemplate: (state) => (objAttrs = {}) => {
    return Object.assign({}, state.subGroupTemplate, objAttrs)
  },

  subGroupsForGroup: state => (groupId) => {
    let subGroups =
      state.all
        .filter(subGroup => subGroup.groupId === parseInt(groupId))
    return subGroups
  },

  includedSubGroupsForGroup: state => (groupId) => {
    let subGroups =
      state.all
        .filter(subGroup => subGroup.included && subGroup.groupId === parseInt(groupId))
    return subGroups
  },

  excludedSubGroupsForGroup: state => (groupId) => {
    let subGroups =
      state.all
        .filter(subGroup => !subGroup.included && subGroup.groupId === parseInt(groupId))
    return subGroups
  },

  allSubGroupsInGroupWithoutOne: (state, getters) => (subGroup) => {
    return getters.subGroupsForGroup(subGroup.groupId).filter(sG => sG !== subGroup)
  },

  subGroupsForCurrentGroup: (state) => {
    if (!state.displayGroupId) {
      return []
    }
    return state.all.find(subGroup => subGroup.groupId === state.displayGroupId)
  },

  // TODO: optimize!
  subGroupsForGroupSorted: (state) => (groupId) => {
    let subGroups =
      state.all
        .filter(item => item.groupId === parseInt(groupId))

    return subGroups.sort((a, b) => {
      if (a.included === true && b.included === false) {
        return -1
      }
      else if (a.included === false && b.included === true) {
        return 1
      }
      else { return 0 }
    })
  },

  findSubGroup: state => (subGroupId) => {
    return state.all.find(subGroup => subGroup.id === parseInt(subGroupId))
  },

  includedSubGroups: (state) => (groupId, sortField, sortDir) => {
    if (sortField == null) {
      sortField = 'position'
    }

    if (sortDir == null) {
      sortDir = 'ASC'
    }

    let sortMult = sortDir === 'ASC' ? 1 : -1

    let subGroups = state.all.filter(item => {
      return item.groupId === parseInt(groupId) && item.included === true
    })

    return subGroups.sort((a, b) => {
      let res = (a[sortField] < b[sortField]) ? -1 : (a[sortField] > b[sortField]) ? 1 : 0
      return res * sortMult
    })
  },

  includedSubGroupsInIncludedGroups: (state, getters, rootState, rootGetters) => {
    const includedGroupIds = rootGetters.includedGroups().map(g => g.id)
    const allIncludedSubGroupsNested = includedGroupIds.map(gId => getters.includedSubGroups(gId))
    return [].concat(...allIncludedSubGroupsNested)
  },

  excludedSubGroups: (state) => (groupId, sortField, sortDir) => {
    if (sortField == null) {
      sortField = 'position'
    }

    if (sortDir == null) {
      sortDir = 'ASC'
    }

    let sortMult = sortDir === 'ASC' ? 1 : -1

    let subGroups = state.all.filter(item => {
      return item.groupId === parseInt(groupId) && item.included === false
    })

    return subGroups.sort((a, b) => {
      let res = (a[sortField] < b[sortField]) ? -1 : (a[sortField] > b[sortField]) ? 1 : 0
      return res * sortMult
    })
  },

  allSubGroups (state) {
    return state.all
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
