export const state = {
  currentStep: null,
  steps: [0, 1, 2, 3, 4, 5],
  visitedSteps: [],
  groupsOnboardingFinished: false,
  showGroupsOnboarding: false
}

const addUniqueStep = (steps, step) => {
  return [...new Set([].concat(steps, [step]))]
}

export const actions = {
  advanceStep ({state, commit}) {
    commit('advanceStep')
    if (state.currentStep === state.steps.length) {
      commit('hideGroupsOnboarding')
      commit('markGroupsOnboardingFinished')
      // Please leave this commented - it can help restore tracking quicker in the future.
      // window.heap.track('Initial on-boarding finished')
    }
  },

  precedeStep ({state, commit}) {
    commit('precedeStep')
  }
}

export const getters = {
  currentOnboardingStep (state) {
    return state.currentStep
  },

  groupsOnboardingFinished (state) {
    return state.groupsOnboardingFinished
  },

  shouldShowGroupsOnboarding (state) {
    return state.showGroupsOnboarding
  },

  isStepVisited: (state) => (step) => {
    return !!state.visitedSteps.find(vS => vS === step)
  },

  visitedOnboardingSteps (state) {
    return state.visitedSteps
  }
}

export const mutations = {
  advanceStep (state) {
    if (state.currentStep === null) {
      state.currentStep = 1
    //
    } else {
      state.currentStep += 1
    }
    state.visitedSteps = addUniqueStep(state.visitedSteps, state.currentStep)
  },

  precedeStep (state) {
    state.currentStep -= 1
  },

  markGroupsOnboardingFinished (state) {
    state.groupsOnboardingFinished = true
  },

  showGroupsOnboarding (state) {
    state.showGroupsOnboarding = true
  },

  hideGroupsOnboarding (state) {
    state.showGroupsOnboarding = false
    state.currentStep = null
  },

  setStep (state, step) {
    state.currentStep = step
    state.visitedSteps = addUniqueStep(state.visitedSteps, step)
  }
}

export default {
  actions,
  getters,
  state,
  mutations
}
