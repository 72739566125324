<template>
  <div class="scorecard-page-container">
    <div
      :class="{'ie-11': isIE11,}"
      class="scorecard-table"
    >

      <!-- A/B testing contact component -->
      <template v-if="!isSeoPrerenderedScorecard">
        <component
          v-if="abTestingComponentCondition"
          :is="abTestingComponentName"
        />
      </template>

      <div
        :class="{'fullscreen-table-header': isFullScreen, 'ie-11': isIE11, 'mini-sidebar-table-header': leftAsideCollapsed}"
        class="scorecard-tr scorecard-table-header"
      >
        <div class="scorecard-options-container scorecard-th">
          <scorecard-options class="scorecard-options"/>
        </div>

        <div
          v-for="(_productColumn, index) in Array(scorecardNumberOfColumns)"
          :class="{'scorecard-product-details-fullscreen': isFullScreen, 'ie-11': isIE11}"
          :key="index"
          class="scorecard-product-details scorecard-th"
        >
          <scorecard-demo-or-pricing-tooltip
            v-if="awaitingDemoOrPricingOrReply(scorecardProductSHIds[index])"
            :sh-id="scorecardProductSHIds[index]"
          />

          <scorecard-remove-button
            v-else-if="scorecardProductSHIds[index]"
            :sh-id="scorecardProductSHIds[index]"
            @click="removeFromScorecard"
          />

          <scorecard-product
            v-if="scorecardProductSHIds[index]"
            :product-sh-id="scorecardProductSHIds[index]"
          />

          <div
            v-else-if="addingProduct && index == scorecardProductSHIds.length"
            class="spinner__container flex column justify-center items-center"
          >
            <q-spinner-ios
              color="primary"
              size="2em"
            />
          </div>

          <add-product
            v-else
            :product-column="index"
            class="scorecard-add-product"
          />
        </div>
      </div>

      <div id="group-list-offset-calculation-helper" />

      <scorecard-group
        v-for="(group, index) in includedGroups"
        :key="group.groupId"
        :id="`anchor-${group.id}`"
        :class="{'ie-11-helper': isIE11 && index === 0, 'fullscreen-ie-11-helper': isFullScreen}"
        :group="group"
      />
    </div>
  </div>
</template>

<script>
  import {
    mapActions,
    mapGetters,
    mapMutations,
    mapState
  } from 'vuex'

  import abTestingMixin from 'src/mixins/abTesting/main_app/Scorecard/abTestingMixin'
  const usedAbTestingMixin = process.env.VUE_APP_IS_SEO_PRERENDERED === 'true' ? {} : abTestingMixin
  import ScorecardRemoveButton from './scorecard_header/ScorecardRemoveButton'
  import Notifications from 'src/mixins/notifications'
  import ScorecardDemoOrPricingTooltip from './scorecard_header/ScorecardDemoOrPricingTooltip'
  import AddProduct from './scorecard_header/AddProduct'
  import ScorecardGroup from './scorecard_group/ScorecardGroup'
  import ScorecardOptions from './ScorecardOptions'
  import ScorecardProduct from './scorecard_product/ScorecardProduct'

  export default {
    name: 'scorecard',

    components: {
      AddProduct,
      ScorecardGroup,
      ScorecardOptions,
      ScorecardProduct,
      ScorecardRemoveButton,
      ScorecardDemoOrPricingTooltip
    },

    mixins: [ usedAbTestingMixin, Notifications ],

    /// /////////////////////////////////
    // Component cached with keep-alive
    // created runs once
    // destroyed never runs
    // Use router guards instead
    /// /////////////////////////////////

    beforeRouteEnter (to, from, next) {
      next(vm => {
        vm.setGeneratingScorecard(false)
        vm.markScorecardPage()
        if (vm.neverSeenScorecard) {
          vm.markScorecardSeen()
        }

        if (vm.dataDownloaded && vm.scorecardCacheDirty) {
          vm.downloadScorecardAnswers()
        }
      })
    },

    beforeRouteLeave (to, from, next) {
      if (this.$q.fullscreen.isActive) {
        this.$q.fullscreen.exit()
        this.unmarkFullScreen()
      }
      this.unmarkScorecardPage()

      next()
    },

    data () {
      return {
        showRequirements: true
      }
    },

    computed: {
      ...mapState({
        isIE11: state => state.layoutStore.isIE11,
        addingProduct: state => state.layoutStore.addingProduct,
        dataDownloaded: state => state.layoutStore.dataDownloaded,
        leftAsideCollapsed: state => state.layoutStore.leftAsideCollapsed,
        scorecardNumberOfColumns: state => state.scorecard.scorecardNumberOfColumns
      }),

      ...mapGetters([
        'isAwaitingDemoOrPricingOrReply',
        'isFullScreen',
        'isSeoPrerenderedScorecard',
        'neverSeenScorecard',
        'productBySHId',
        'scorecardCacheDirty',
        'isAwaitingDemoOrPricingOrReply',
        'scorecardProductSHIds',
        'productBySHId',
        'showScorecardContactModalDemo',
        'showScorecardContactModalPricing',
        'scorecardTableSetting',
        'SCORECARD_SORT_ORDER_TITLE',
        'SCORECARD_SORT_ORDER_PRIORITY'
      ]),

      includedGroups () {
        return this.$store.getters.includedGroups()
      },

      tableSettings: {
        get () {
          return this.scorecardTableSetting
        },
        set (selectedSetting) {
          this.changeScorecardTableSettings(selectedSetting)
        }
      }
    },

    methods: {
      ...mapActions([
        'downloadRequirements',
        'downloadScorecardAnswers',
        'markScorecardSeen',
        'saveAwaitingReplySHProductIds'
      ]),

      ...mapMutations([
        'markScorecardPage',
        'setGeneratingScorecard',
        'unmarkFullScreen',
        'unmarkScorecardPage'
      ]),

      awaitingDemoOrPricingOrReply (shId) {
        return this.isAwaitingDemoOrPricingOrReply(shId)
      },

      product (shId) {
        return this.productBySHId(shId)
      },

      removeFromScorecard (shId) {
        if (!process.env.VUE_APP_IS_SEO_PRERENDERED) {
          this.$store.dispatch('removeProductFromScorecard', shId)
            .then(() => {
              this.showProductRemovedNotification(
              { productName: this.product(shId).name, productSHId: shId })
              // Please leave this commented - it can help restore tracking quicker in the future.
              // window.heap.track('Product removed from scorecard - clicked X in scorecard product header')
            })
        } else {
          const updatedScorecardProductsIds = this.$store.state.scorecard.scorecardProductSHIds.filter(prodSHId => {
            return prodSHId !== shId
          })
          this.$store.commit('replaceScorecard', updatedScorecardProductsIds)
        }
      }

    }
  }
</script>

<style scoped lang="stylus">
@import '~variables'

  .scorecard-page-container
    padding: 0 2vw 15em

  .scorecard-fullscreen-controls
    text-align: right
    display: flex
    flex-direction: row
    justify-content: flex-end

    .q-icon-container
      height: 4em
      width: 3vw
      display: flex
      justify-content: center
      align-items: center

    .fullscreen-close
      font-size: 1.4em
      cursor: pointer

  .scorecard-table
    width: 100%

    .scorecard-tr
      display: flex
      flex-direction: row
      flex-wrap: nowrap
      justify-content: center

      .scorecard-product-details
        width: 15%
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        &.scorecard-product-details-fullscreen
          padding-top: 0.2em

      .scorecard-options-container
        width: 25%

    .scorecard-th
      display: inline-block

      .scorecard-th
        background: $sh-dark-1
        padding: 0 0.05vw 0
        height: 100%

  .scorecard-table-header
    background: white
    height: $scorecard-table-header-height
    top: $scorecard-table-header-top
    position: sticky
    position: -webkit-sticky
    z-index: 103

    &:before
      content: ''
      position: fixed
      top: 0
      left: 0
      width: 100%
      height: "calc(%s + %s)" % ($scorecard-table-header-height $scorecard-table-header-top)
      background: inherit
      z-index: -1

    &.ie-11
      position: fixed
      width: 80.7%

      &.fullscreen-table-header
        width: 96vw

      &.mini-sidebar-table-header
        // px due to quasar
        width: calc(96vw - 77px)

    &.fullscreen-table-header
      top: $fullscreen-scorecard-table-header-top

      &:before
        height: "calc(%s + %s)" % ($scorecard-table-header-height $fullscreen-scorecard-table-header-top)

  .scorecard-th-title
    margin-bottom: 1em
    font-weight: bold

  #group-list-offset-calculation-helper
    position: fixed
    top: 20.1em

  .ie-11-helper
    padding-top: $ie-scorecard-table-header-height

    &.fullscreen-ie-11-helper
      padding-top: $ie-fullscreen-scorecard-table-header-top

  .spinner__container
    height: 100%
</style>
