/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import Moment from 'moment-timezone'
import { extendMoment } from 'moment-range'

const moment = extendMoment(Moment)

class WorkingHoursFetcher {
  static initClass () {
    this.prototype.selecthubTimeZone = 'America/Chicago'
    this.prototype.businessOpensAtCST = 7
    this.prototype.businessClosesAtCST = 17
  }

  constructor () {
    this.initializeMoment()
    this.currentTime = moment()
    this.nameOfToday = this.currentTime.format('dddd')
    this.openingHourToday = moment({hour: this.businessOpensAtCST})
    this.closingHourToday = moment({hour: this.businessClosesAtCST})
    this.isWeekend = (this.nameOfToday === 'Saturday') || (this.nameOfToday === 'Sunday')
    this.isOutsideWorkingHours = !this.currentTime.isBetween(this.openingHourToday, this.closingHourToday)
    this.isAfterWork = this.currentTime.isBetween(this.closingHourToday, this.currentTime.clone().endOf('day'))
    this.setDaysToAdd()
    this.setUserTimeZone()
    this.userTimeZoneAbrrev = this.userTimeZone.abbr(this.currentTime)
  }

  initializeMoment () {
    moment.tz.setDefault(this.selecthubTimeZone)
  }

  setDaysToAdd () {
    if (this.nameOfToday === 'Thursday') {
      this.daysToAddForTomorrow = 1
      this.daysToAddForDayAfterTomorrow = 4
    }

    else if (this.nameOfToday === 'Friday') {
      this.daysToAddForTomorrow = 3
      this.daysToAddForDayAfterTomorrow = 4
    }

    else if (this.nameOfToday === 'Saturday') {
      this.daysToAddForTomorrow = 2
      this.daysToAddForDayAfterTomorrow = 3
    }

    else {
      this.daysToAddForTomorrow = 1
      this.daysToAddForDayAfterTomorrow = 2
    }
  }

  setUserTimeZone () {
    const guessedUserTimeZoneName = moment.tz.guess()
    const guessedUserTimeZoneObject = moment.tz.zone(guessedUserTimeZoneName)
    const guessedUserTimeZoneOffset = guessedUserTimeZoneObject.utcOffset(this.currentTime)
    this.userTimeZone = guessedUserTimeZoneObject
    if (this.timeZoneIsNotAmerican(guessedUserTimeZoneOffset)) {
      this.userTimeZone = moment.tz.zone(this.selecthubTimeZone)
    }
  }

  timeZoneIsNotAmerican (timeZoneOffset) {
    // It might be rough, but should include offsets for daylight saving time
    return (timeZoneOffset < 150) || (timeZoneOffset > 600)
  }

  hoursRange (startMoment, finishMoment) {
    return moment.range(startMoment, finishMoment)
  }

  hoursArray (hoursRange) {
    return Array.from(hoursRange.by('minute', {step: 15}))
  }

  validHours (hoursArray) {
    return hoursArray.filter(hour => {
      return hour.isSameOrAfter(this.currentTime)
    })
  }

  todayHours () {
    return this.validHours(
      this.hoursArray(
        this.hoursRange(this.openingHourToday, this.closingHourToday)
      )
    )
  }

  nextDayHours (daysToAdd) {
    // we need to create copys of @openingHourToday/@closingHourToday
    // because .add mutates the original object and would make them invalid
    const dayStart = moment(this.openingHourToday).add(daysToAdd, 'days')
    const dayEnd = moment(this.closingHourToday).add(daysToAdd, 'days')
    return this.hoursArray(
      this.hoursRange(dayStart, dayEnd)
    )
  }

  mappedTodayHours () {
    return this.mappedHours(this.todayHours())
  }

  mappedTomorrowHours () {
    return this.mappedHours(
      this.nextDayHours(this.daysToAddForTomorrow)
    )
  }

  mappedDayAfterTomorrowHours () {
    return this.mappedHours(
      this.nextDayHours(this.daysToAddForDayAfterTomorrow)
    )
  }

  mappedHours (hours) {
    return hours.map(hour => {
      return {
        unixTimestamp: hour.valueOf(),
        timeInZone: this.timeHourPart(hour) + this.timeTimezonePart(hour)
      }
    })
  }

  timeFromTimestamp (timestamp) {
    const hour = moment(parseInt(timestamp))
    return this.timeHourPart(hour) + this.timeTimezonePart(hour)
  }

  timeHourPart (hour) {
    return moment(hour).tz(this.userTimeZone.name).format('h:mm')
  }

  timeTimezonePart (hour) {
    return moment(hour).tz(this.userTimeZone.name).format('a') + ' ' + this.userTimeZoneAbrrev
  }

  prefixedDayName (timestamp) {
    const name = this.dayName(timestamp)

    if ((name === 'Today') || (name === 'Tomorrow')) {
      return name.toLowerCase()
    }
    else {
      return `on ${name}`
    }
  }

  dayName (timestamp) {
    const checkedHour = moment(parseInt(timestamp))
    const tomorrow = moment().add(1, 'days')
    if (checkedHour.isSame(this.currentTime, 'day')) {
      return 'Today'
    }

    else if (checkedHour.isSame(tomorrow, 'day')) {
      return 'Tomorrow'
    }

    else {
      return checkedHour.format('dddd')
    }
  }

  valueOf () {
    return moment().valueOf()
  }
}
WorkingHoursFetcher.initClass()

export default new WorkingHoursFetcher()
