<template>
  <div class="product-score">
    <svg
      class="product-score-svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 45 45"
    >
      <circle
        id="svg-circle"
        class="svg-circle"
        cx="50%"
        cy="50%"
        r="47%"
        :stroke="arcColor"
        stroke-width="3"
        fill="none"
        v-if="meaningfulRating"
      />

      <path
        stroke="#EEEEEE"
        stroke-width="3.5"
        fill="none"
        :d="describeArc(45/2, 45/2, 45 * 0.47, 3.6 * rating, 360)"
        v-if="meaningfulRating"
      />

      <circle
        id="svg-circle"
        cx="50%"
        cy="50%"
        r="47%"
        stroke="#EFEFEF"
        stroke-width="3"
        fill="#EFEFEF"
        v-else
      />


      <text
        :x="scoreTextX"
        :y="scoreTextY"
        text-anchor="start"
        :stroke="strokeColor"
        :font-size="meaningfulRating ? 20 : 15"
        :font-weight="meaningfulRating ? 300 : 200">
        {{meaningfulRating ? rating : 'n/a'}}
      </text>
    </svg>
  </div>
</template>

<script>
  function polarToCartesian (centerX, centerY, radius, angleInDegrees) {
    const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0

    return {
      x: centerX + (radius * Math.cos(angleInRadians)),
      y: centerY + (radius * Math.sin(angleInRadians))
    }
  }

  export default {
    name: 'product-score-svg',

    props: {
      rating: {
        type: Number,
        default: 0
      },

      meaningfulRating: {
        type: Boolean,
        required: false,
        default: true
      }
    },

    computed: {
      arcColor () {
        switch (true) {
          case (this.rating >= 90):
            return '#21BA45' // green
          case (this.rating >= 80 && this.rating < 90):
            return '#F6BD42' // yellow
          case (this.rating >= 50 && this.rating < 80):
            return '#E48638' // orange
          default:
            return '#D24E2E' // red
        }
      },

      scoreTextX () {
        if (this.meaningfulRating) {
          switch (true) {
            case (this.rating === 100):
              return '12%'
            case (this.rating > 9):
              return '25%'
            default:
              return '38%'
          }
        }
        else {
          return '27%'
        }
      },

      scoreTextY () {
        return this.meaningfulRating ? '66%' : '60%'
      },

      strokeColor () {
        return this.meaningfulRating ? '#000000' : '#747474'
      }
    },

    methods: {
      describeArc (x, y, radius, startAngle, endAngle) {
        const start = polarToCartesian(x, y, radius, endAngle)
        const end = polarToCartesian(x, y, radius, startAngle)

        const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1'

        const d = [
          'M', start.x, start.y,
          'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y
        ].join(' ')

        return d
      }
    }
  }
</script>

<style scoped lang="stylus">
  .product-scoring
    text-align: center
    padding-top: 0.25em
    padding-bottom: 0.2em

    .product-score-svg
      height: 1.2em
      font-size: 1.35em

    .product-score
      font-size: 2em
      font-weight: 500
      text-align: center

</style>
