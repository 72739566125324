import { Socket } from 'phoenix'

let _socket = {}
let _templateChannel = {}
let _cardsChannel = {}
let _projectChannel = {}
let _categoriesChannel = {}


if (process.env.VUE_APP_IS_SEO_PRERENDERED !== 'true' || process.env.VUE_APP_IS_SEO_PRERENDERED === undefined) {
  let socketEndpoint = 'ws://0.0.0.0:4000/socket'

  console.log("host", location.origin);

  if (location.origin.match('express-ge')) {
    socketEndpoint = 'wss://express-ge.selecthub.com/socket'
  }
  else if (location.origin.match('express-dev-ge')) {
    socketEndpoint = 'wss://express-dev-ge.selecthub.com/socket'
  }
  else if (location.origin.match('express-staging-ge')) {
    socketEndpoint = 'wss://express-staging-ge.selecthub.com/socket'
  }
  else if (location.origin.match('dev')) {
    socketEndpoint = 'wss://express-dev.selecthub.com/socket'
  }
  else if (location.origin.match('staging')) {
    socketEndpoint = 'wss://express-staging.selecthub.com/socket'
  }
  else if (location.origin.match('express-ge')) {
    socketEndpoint = 'wss://express-ge.selecthub.com/socket'
  }
  else if (location.origin.match('selecthub')) {
    socketEndpoint = 'wss://express.selecthub.com/socket'
  }
  else if (location.origin.match('localhost') || location.origin.match('10.0.2.2')) { // 10.0.2.2 is for VirtualBox
    socketEndpoint = `ws://localhost:${process.env.PORT_SH_PLATFORM || 3000}/socket`
  }
  else if (location.origin.match('scarce-embarrassed-majungatholus')) {
    socketEndpoint = 'wss://scarce-embarrassed-majungatholus.gigalixirapp.com/socket'
  }

  _socket = new Socket(socketEndpoint, {params: {token: window.userToken}})
  _socket.connect()

  const topic = 'template:lobby'
  _templateChannel = _socket.channel(topic, {})

  _templateChannel.join()
    .receive('error', reason => {
      console.log('Unable to join', reason)
    })

  _cardsChannel = _socket.channel('cards:flow', {})
  _cardsChannel.join()
    .receive('error', reason => {
      console.log('Unable to join cards', reason)
    })

  _projectChannel = _socket.channel('project:current', {})
  _projectChannel.join()
    .receive('error', reason => {
      console.log('Unable to join project', reason)
    })

  _categoriesChannel = _socket.channel('categories:lobby', {})
  _categoriesChannel.join()
    .receive('error', reason => {
      console.log('Unable to join categories', reason)
    })
}

export const socket = _socket
export const templateChannel = _templateChannel
export const cardsChannel = _cardsChannel
export const projectChannel = _projectChannel
export const categoriesChannel = _categoriesChannel

export default {
  socket, templateChannel, cardsChannel, projectChannel, categoriesChannel
}
