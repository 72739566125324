import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState
} from 'vuex'

export default {
  components: {
    PreferredWayToContactModal: () => import('components/main_app/ab_testing/preferred_way_to_contact_modal/components/PreferredWayToContactModal'),
    ContactBarContainer: () => import('components/main_app/ab_testing/contact_bar/components/ContactBarContainer')
  },

  computed: {
    ...mapGetters([
      'abTestingComponentCondition',
      'abTestingComponentName'
    ]),

    ...mapState({
      userSeenFirstReqWithoutAnswer: state => state.abTestingThemeStore.userSeenFirstReqWithoutAnswer,
      userSeenContactBarAfterScroll: state => state.abTestingThemeStore.userSeenContactBarAfterScroll
    })
  },

  methods: {
    ...mapActions([
      'markUserSeenContactBarAfterScroll'
    ]),

    ...mapMutations([
      'setShowContactBar'
    ])
  },

  watch: {
    userSeenFirstReqWithoutAnswer (newVal) {
      if (newVal && !this.userSeenContactBarAfterScroll) {
        this.setShowContactBar(true)
        this.markUserSeenContactBarAfterScroll()
      }
    }
  }
}
