import { categoriesChannel, projectChannel } from '../../../../socket'

const state = {
  activePreviewedProject: null,
  loadingPreview: false,
  shouldPreviewProject: false,
  previewedProjects: [],
  previewCategoryShId: null,
  previewCategoryDetails: {
    acronym: '',
    seo_category: {
      article_count: 0,
      url: '',
    },
  },
  previewCategoryDetailsFetchFinished: false,
  previewedProductsForCategory: [],
  loadingDashboard: false,
}

const actions = {
  async openPreview ({ commit, dispatch }, projectData) {
    commit('setLoadingPreview', true)

    const { id: projectId } = projectData
    const projectExists = await dispatch('checkIfPreviewedProjectExists', projectId)
    const project = await dispatch('createPreviewedProject', projectData)
    if (!projectExists) {
      commit('addPreviewedProject', project)
    }
    await dispatch('getActivePreviewedProject', projectId)

    commit('setShouldPreviewProject', true)
    commit('setLoadingPreview', false)
  },

  checkIfPreviewedProjectExists ({ state }, projectId) {
    return state.previewedProjects.some(project => project.projectData.id === projectId)
  },

  closePreviewedProject ({ commit, dispatch }, projectId) {
    if (state.previewedProjects.length === 1) {
      dispatch('closePreview')
      return
    }

    const isActive = state.activePreviewedProject.projectData.id === projectId
    const projectToCloseIndex = state.previewedProjects.findIndex(project => project.projectData.id === projectId)
    commit('closeProject', { index: projectToCloseIndex, isActive })
  },

  async createPreviewedProject ({ dispatch }, projectData) {
    const { id: projectId, selecthub_category_id: categoryShId } = projectData

    const scorecard = await dispatch('fetchPreviewScorecard', projectId)
    const categoryDetails = await dispatch('fetchPreviewCategoryDetails', categoryShId)
    return {
      scorecard,
      categoryDetails,
      projectData,
    }
  },

  getActivePreviewedProject ({ commit, state }, projectId) {
    const activeProject = state.previewedProjects.find(project => project.projectData.id === projectId)
    commit('setActivePreviewedProject', activeProject || [])
  },

  closePreview ({ commit }) {
    commit('setShouldPreviewProject', false)
    commit('setActivePreviewedProject', null)
    commit('clearPreviewedProjects')
  },

  fetchPreviewScorecard (_, projectId) {
    return new Promise((resolve, reject) => {
      projectChannel.push('project:get_scorecard', { project_id: projectId })
        .receive('ok', scorecard => {
          resolve(scorecard)
        })
        .receive('error', err => {
          console.error(err)
          reject(err)
        })
    })
  },

  fetchPreviewCategoryDetails ({ commit }, categoryShId) {
    return new Promise((resolve, reject) => {
      categoriesChannel.push('category:details', { selecthub_category_id: categoryShId })
        .receive('ok', category => {
          commit('setPreviewCategoryDetails', category)
          commit('setPreviewCategoryDetailsFetchFinished', true)
          resolve(category)
        })
        .receive('error', err => {
          console.error(err)
          reject(err)
        })
    })
  },

  downloadPreviewProducts ({ commit }, categoryShId) {
    projectChannel.push('category:products', { selecthub_category_id: categoryShId })
      .receive('ok', payload => {
        commit('setPreviewedProductsForCategory', payload.products)
      })
  },
}

const getters = {
  previewProjectId (state) {
    if (!state.activePreviewedProject) {
      return 0
    }
    return state.activePreviewedProject.projectData.id
  },

  previewProjectScorecard (state) {
    if (!state.activePreviewedProject) {
      return null
    }
    return state.activePreviewedProject.scorecard
  },

  previewCategoryDetails (state) {
    if (!state.activePreviewedProject) {
      return null
    }

    return state.activePreviewedProject.categoryDetails
  },

  getPreviewCategoryDetailsValue: state => detailsKey => {
    return state.previewCategoryDetails[detailsKey]
  },

  previewCategoryProductsNames: (_, getters) => (categoryDetailsKey) => {
    const productShIds = getters.getPreviewCategoryDetailsValue(categoryDetailsKey)

    if (productShIds) {
      return productShIds.map(productShId => {
        const product = getters.previewProductByShId(productShId)
        return product ? product.name : ''
      })
    }
    return []
  },

  previewProductByShId: (state) => (productShId) => {
    return state.previewedProductsForCategory.find(product => product.selecthub_id === productShId)
  },

  previewedCategoryProjects (state, getters) {
    return getters.getProjectsForCategory(state.previewCategoryShId)
  },
}

const mutations = {
  setPreviewCategoryShId (state, categoryShId) {
    state.previewCategoryShId = categoryShId
  },

  setPreviewCategoryDetailsFetchFinished (state, bool) {
    state.previewCategoryDetailsFetchFinished = bool
  },

  setPreviewCategoryDetails (state, categoryDetails) {
    state.previewCategoryDetails = categoryDetails
  },

  setActivePreviewedProject (state, project) {
    state.activePreviewedProject = project
  },

  closeProject (state, { index, isActive }) {
    state.previewedProjects.splice(index, 1)
    state.activePreviewedProject = isActive ? state.previewedProjects[0] : state.activePreviewedProject
  },

  clearPreviewedProjects (state) {
    state.previewedProjects = []
  },

  addPreviewedProject (state, project) {
    state.previewedProjects.push(project)
  },

  setShouldPreviewProject (state, bool) {
    state.shouldPreviewProject = bool
  },

  setPreviewedProductsForCategory (state, products) {
    state.previewedProductsForCategory = products
  },

  setLoadingPreview (state, bool) {
    state.loadingPreview = bool
  },

  setLoadingDashboard (state, bool) {
    state.loadingDashboard = bool
  },
}

export default {
  actions,
  getters,
  mutations,
  state,
}
