import Vue from 'vue'
import App from './App.vue'

import store from './store'
import './quasar'

import VueScrollTo from 'vue-scrollto'
Vue.use(VueScrollTo)

// development purpose only
if (process.env.NODE_ENV === 'development') {
  require('./store/payloads/')
}

Vue.config.productionTip = false

new Vue({
  store,
  render: h => h(App),
}).$mount('#app')
