import { templateChannel } from '../../../../socket'
import Vue from 'vue'

export const state = {
  currentUser: {},
  createPasswordModalNeeded: false,
  firstTimeUser: false,
  initialGroupsOnboardingNeeded: false,
  passwordChangeProcessing: false,
  neverEnteredTemplateSettings: true,
  seenScorecardHelpTooltip: false,
  seenSuggestAProductSearchInputTooltip: false,
  disabledGroupListExclusionWarningModal: false,
  showGroupListExclusionWarning: true,
  userManuallyToggledGroupList: false,
  userManuallyToggledScorecardGroupList: false,
  productsSuggestedToAnalyst: [],
  recentlyOpenedProjects: {},
  userClosedLeftSidebar: false,
  userOpenedLeftSidebar: false,
  ungatedDownloadUserSeenDownloadModal: false
}

export const actions = {
  downloadCurrentUser ({commit}) {
    return new Promise((resolve, reject) => {
      templateChannel.push('current_user:load', {})
        .receive('ok', payload => {
          // For debugging
          // const config = {
          //   first_time_user: true,
          //   temporary_password: true,
          //   initial_groups_onboarding_needed: true
          // }
          // templateChannel.push('user:update_config', config)
          if ('products_suggested_to_analyst' in payload.config) commit('setProductsSuggestedToAnalyst', payload.config.products_suggested_to_analyst)
          if ('opened_left_sidebar' in payload.config) commit('markLeftSidebarOpened')
          if ('closed_left_sidebar' in payload.config) commit('markLeftSidebarClosed')
          if ('ungated_download_user_seen_download_modal' in payload.config) {
            commit('setUngatedUserSeenDownloadModal', payload.config.ungated_download_user_seen_download_modal)
          }
          if ('recently_opened_projects' in payload.config) {
            commit('setRecentlyOpenedProjects', payload.config.recently_opened_projects)
          }
          commit('loadCurrentUser', payload)
          resolve()
        })
        .receive('error', reason => {
          console.log('Unable to download user', reason)
          reject(reason)
        })
    })
  },

  updatePassword ({commit}, password) {
    return new Promise((resolve, reject) => {
      templateChannel.push('user:set_password', {
        password: password,
        password_confirmation: password
      }).receive('ok', payload => {
        commit('updatePassword', password)
        const config = {
          first_time_user: false,
          temporary_password: false
        }
        templateChannel.push('user:update_config', config)
        resolve()
      }).receive('error', reason => {
        console.log('Unable to update password: ', reason)
      })
    })
  },

  changePassword ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      templateChannel.push('user:change_password', {
        old_password: payload.oldPassword,
        password: payload.password,
        password_confirmation: payload.password,
      }).receive('ok', () => {
        commit('updatePassword', payload.password)
        resolve()
      }).receive('error', reason => {
        reject(reason)
      })
    })
  },

  markScorecardHelpTooltipSeen ({commit}) {
    return new Promise((resolve, reject) => {
      commit('markScorecardHelpTooltipSeen')
      templateChannel.push('user:update_config', {seen_scorecard_help_tooltip: true})
        .receive('ok', payload => {
          resolve()
        })
    })
  },

  markProductsSuggestedToAnalyst ({state, commit}, suggestedProducts) {
    templateChannel.push('user:update_config', {products_suggested_to_analyst: suggestedProducts})
      .receive('ok', () => {
        commit('setProductsSuggestedToAnalyst', suggestedProducts)
      })
  },

  markUngatedUserSeenDownloadModal ({ commit }) {
    templateChannel.push('user:update_config', { ungated_download_user_seen_download_modal: true })
      .receive('ok', () => {
        commit('setUngatedUserSeenDownloadModal', true)
      })
  },

  handleLeftSidebarToggle ({commit}, sidebarCollapsed) {
    // for grepping: closed_left_sidebar opened_left_sidebar
    const prop = {
      [`${sidebarCollapsed ? 'closed' : 'opened'}_left_sidebar`]: true
    }
    return new Promise((resolve, reject) => {
      templateChannel.push('user:update_config', prop)
        .receive('ok', payload => {
          sidebarCollapsed ? commit('markLeftSidebarClosed') : commit('markLeftSidebarOpened')
          resolve()
        })
    })
  },

  markRecentlyOpenedProject ({ commit, state }, projectData) {
    const userConfig = state.currentUser.config

    let recentlyOpenedProjectForCategory = {}
    recentlyOpenedProjectForCategory[projectData.category_id] = projectData.id

    let recentlyOpenedProjects = {}
    if (userConfig.hasOwnProperty('recently_opened_projects')) {
      recentlyOpenedProjects = userConfig.recently_opened_projects
    }

    return new Promise(resolve => {
      const updatedRecentProjects = Object.assign(recentlyOpenedProjects, recentlyOpenedProjectForCategory, {})
      // recently_opened_projects = {
      //   projectCategoryId: projectId,
      //   projectCategoryId: projectId
      // }

      templateChannel.push('user:update_config', { recently_opened_projects: updatedRecentProjects })
        .receive('ok', res => {
          commit('setRecentlyOpenedProjects', res.config.recently_opened_projects)
          resolve()
        })
    })
  }
}

export const mutations = {
  loadCurrentUser (state, user) {
    state.currentUser = user
    state.firstTimeUser = user.config.first_time_user
    if (user.config.temporary_password !== undefined) {
      state.createPasswordModalNeeded = user.config.temporary_password
    }

    if (user.config.initial_groups_onboarding_needed !== undefined) {
      state.initialGroupsOnboardingNeeded = user.config.initial_groups_onboarding_needed
    }

    if (user.config.never_entered_template_settings !== undefined) {
      state.neverEnteredTemplateSettings = user.config.never_entered_template_settings
    }

    if (user.config.seen_scorecard_help_tooltip !== undefined) {
      state.seenScorecardHelpTooltip = user.config.seen_scorecard_help_tooltip
    }

    if (user.config.show_group_list_exclusion_warning !== undefined) {
      state.showGroupListExclusionWarning = user.config.show_group_list_exclusion_warning
    }

    if (user.config.disabled_group_list_exclusion_warning_modal !== undefined) {
      state.disabledGroupListExclusionWarningModal = user.config.disabled_group_list_exclusion_warning_modal
    }

    state.userManuallyToggledGroupList = !!user.config.user_manually_toggled_group_list
    state.userManuallyToggledScorecardGroupList =
      !!user.config.user_manually_toggled_scorecard_group_list

    // Please leave this commented - it can help restore tracking quicker in the future.
    // window.heap.identify(user.email)
    // window.heap.addUserProperties({
    //   'Name': user.name,
    //   'Email': user.email,
    //   'Onboarded at': user.inserted_at,
    //   'Sign in count': user.sign_in_count,
    //   'ID': user.id})
  },

  markUserFirstTime (state) {
    Vue.set(state.currentUser, 'first_time_user', false)
    const config = { first_time_user: false }
    templateChannel.push('user:update_config', config)
    state.createPasswordModalNeeded = false
  },

  updatePassword (state, password) {
    // Please leave this commented - it can help restore tracking quicker in the future.
    // if (state.currentUser.config.first_time_user) {
    //   window.heap.track('Password updated upon first prompt')
    // } else {
    //   window.heap.track('Password updated later')
    // }

    Vue.set(state.currentUser, 'password', password)
    Vue.set(state.currentUser.config, 'first_time_user', false)
    Vue.set(state.currentUser.config, 'temporary_password', false)
  },

  disableInitialGroupsOnboarding (state) {
    state.initialGroupsOnboardingNeeded = false
    const config = {initial_groups_onboarding_needed: false}
    templateChannel.push('user:update_config', config)
  },

  maybeMarkSettingsEntered (state) {
    if (state.neverEnteredTemplateSettings) {
      templateChannel.push('user:update_config', {never_entered_template_settings: false})
      state.neverEnteredTemplateSettings = false
    }
  },

  markUserManuallyToggledGroupList (state) {
    templateChannel.push('user:update_config', {user_manually_toggled_group_list: true})
    state.userManuallyToggledGroupList = true
  },

  markUserManuallyToggledScorecardGroupList (state) {
    templateChannel.push('user:update_config', {user_manually_toggled_scorecard_group_list: true})
    state.userManuallyToggledScorecardGroupList = true
  },

  markScorecardHelpTooltipSeen (state) {
    state.seenScorecardHelpTooltip = true
  },

  hideGroupListExclusionWarning (state) {
    templateChannel.push('user:update_config', {show_group_list_exclusion_warning: false})
    state.showGroupListExclusionWarning = false
  },

  disableGroupListExclusionWarningModal (state) {
    templateChannel.push('user:update_config', {disabled_group_list_exclusion_warning_modal: true})
    state.disabledGroupListExclusionWarningModal = true
  },

  setProductsSuggestedToAnalyst (state, suggestedProductsIds) {
    state.productsSuggestedToAnalyst = suggestedProductsIds
  },

  markLeftSidebarOpened (state) {
    state.userOpenedLeftSidebar = true
  },

  markLeftSidebarClosed (state) {
    state.userClosedLeftSidebar = true
  },

  setSeenSuggestAProductSearchInputTooltip (state, bool) {
    state.seenSuggestAProductSearchInputTooltip = bool
  },

  setUngatedUserSeenDownloadModal (state, bool) {
    state.ungatedDownloadUserSeenDownloadModal = bool
  },

  setRecentlyOpenedProjects (state, recentlyOpenedProjects) {
    state.recentlyOpenedProjects = recentlyOpenedProjects
  }
}

export const getters = {
  selecthubUser (state) {
    if (!!state.currentUser.email && !!state.currentUser.email.trim()) {
      return state.currentUser.email.includes('@selecthub')
    }
    return false

  },
  createPasswordModalNeeded (state) {
    return state.createPasswordModalNeeded
  },

  currentUser (state) {
    return state.currentUser
  },

  initialGroupsOnboardingNeeded (state) {
    return state.initialGroupsOnboardingNeeded
  },

  userSignInCount (state) {
    return state.currentUser.sign_in_count
  },

  neverEnteredTemplateSettings (state) {
    return state.neverEnteredTemplateSettings
  },

  userManuallyToggledGroupList (state) {
    return state.userManuallyToggledGroupList
  },

  userManuallyToggledScorecardGroupList (state) {
    return state.userManuallyToggledScorecardGroupList
  },

  seenScorecardHelpTooltip (state) {
    return state.seenScorecardHelpTooltip
  },

  showGroupListExclusionWarning (state, rootGetters) {
    return state.showGroupListExclusionWarning && !rootGetters.neverSeenScorecard
  },

  disabledGroupListExclusionWarningModal (state) {
    return state.disabledGroupListExclusionWarningModal
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
