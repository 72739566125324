<template>
  <div
    class="scorecard-product-requirement"
    @mouseover="maybeShowDescriptionPopup"
    @mouseleave="hideDescriptionPopup"
  >
    <div
      ref="overflowableTitle"
      class="scorecard-requirement-title"
    >
      {{ requirement.title }}
    </div>

    <div class="scorecard-requirement-description-container">
      <div
        ref="overflowableDescription"
        class="scorecard-requirement-description"
      >
        {{ requirement.description }}
      </div>
      <div class="scorecard-description-gradient" />
    </div>

    <div
      v-if="showOverflowableDescription"
      :class="wholeTooltipInViewport ? 'tooltip-bottom' : 'tooltip-top'"
      class="scorecard-description-popup"
      @mouseleave="hideDescriptionPopup"
    >
      <div
        class="scorecard-description-popup-header"
      >
        {{ requirement.title }}
      </div>

      <div
        class="scorecard-description-popup-body"
      >
        {{ requirement.description }}
      </div>
    </div>

    <div class="scorecard-requirement-priority">
      <div
        v-if="isSeoPrerenderedScorecard"
        class="scorecard-requirement-priority-label"
        disabled
      >
        {{ priorityMapped.label }}
      </div>

      <select-dropdown
        v-else
        :options="availablePriorities"
        :value="requirement.priority"
        select-size="small"
        :input-style="{ fontSize: '0.9em', fontStyle: 'italic' }"
        @click="changePriority"
      />
    </div>

  </div>
</template>

<script>
import SelectDropdown from 'components/main_app/shared/SelectDropdown'

import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState
} from 'vuex'

export default {
  name: 'scorecard-requirement',

  components: {
    SelectDropdown
  },

  props: {
    requirement: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      intersectionObserver: null,
      showOverflowableDescription: false,
      wholeTooltipInViewport: false
    }
  },

  computed: {
    ...mapGetters([
      'isSeoPrerenderedScorecard'
    ]),

    ...mapState({
      availablePriorities: state => state.productDetails.priorityMatrix
    }),

    priorityMapped () {
      return this.availablePriorities.find(pr => pr.value === this.requirement.priority)
    }
  },

  methods: {
    ...mapActions([
      'calculateProductScores',
      'updateRequirement'
    ]),

    ...mapMutations([
      'setShowProductScoreSpinner'
    ]),

    changePriority (newVal) {
      const requirementCopy = Object.assign({}, this.requirement)
      this.setShowProductScoreSpinner(true)
      this.requirement.priority = newVal
      this.$nextTick(() => {
        this.updateRequirement({ newRequirement: this.requirement, oldRequirement: requirementCopy })
          .then(() => {
            this.calculateProductScores()
          })
      })
    },

    maybeShowDescriptionPopup () {
      const descTitle = this.$refs.overflowableTitle
      const descEl = this.$refs.overflowableDescription
      this.$nextTick(() => {
        if (descTitle.scrollWidth > descTitle.clientWidth) {
          this.showDescriptionPopup()
        } 
        else if (descEl.scrollHeight > descEl.clientHeight) {
          this.showDescriptionPopup()
        }
      })
    },

    showDescriptionPopup () {
      this.showOverflowableDescription = true
      this.checkIfWholeInViewport()
    },

    hideDescriptionPopup () {
      this.showOverflowableDescription = false
    },

    checkIfWholeInViewport () {
      const tooltipBoundingRect = this.$refs.overflowableDescription.getBoundingClientRect()
      const vwHeightPercentage = 0.81
      this.wholeTooltipInViewport = tooltipBoundingRect.top >= 0 && tooltipBoundingRect.bottom <= ((window.innerHeight || document.documentElement.clientHeight) * vwHeightPercentage)
    }
  }
}
</script>

<style scoped lang="stylus">
  @import '~variables'

  .scorecard-product-requirement
    position: relative

    .scorecard-requirement-title
      font-weight: 500
      font-size: 1em
      margin-bottom: 0.5em
      overflow: hidden
      white-space: nowrap
      text-overflow: ellipsis

    .scorecard-requirement-description-container
      position: relative
      height: 50%
      overflow: hidden

      .scorecard-requirement-description
        font-weight: 400
        font-size: 0.85em
        height: 100%

      .scorecard-description-gradient
        position: absolute
        bottom: 0
        left: 0
        width: 100%
        padding: 0.3em 0
        background-image: -webkit-gradient(
          linear,
          left top,
          left bottom,
          color-stop(0, rgba(255, 255, 255, 0)),
          color-stop(1, rgba(255, 255, 255, 100)))

    .scorecard-requirement-priority
      width: 50%
      margin-bottom: 1em
      font-style: italic
      font-size: 0.85em

      .scorecard-requirement-priority-label
        font-size: 0.9em
        margin-bottom: 0.2em
        padding-top: 0

      .scorecard-requirement-priority-label
        margin-top: 0.2em

        >>>.q-field__control,
        >>>.q-field__native,
        >>>.q-field__marginal
          min-height: 0
          height: 100%

        >>>.q-field__control
          &::before
            content: none

        >>>.fa-caret-down
          font-size: 0.6em

    .scorecard-description-popup
      z-index: 102
      position: absolute
      left: -1vw
      top: 7.7em
      height: auto
      width: 25vw
      background-color: white
      padding: 0.7em 0.8vw
      color: #000000
      /* border-radius */
      -webkit-border-radius: 0.3em
      -moz-border-radius: 0.3em
      border-radius: 0.3em
      /* box-shadow */
      -moz-box-shadow: 0 0 3px #000
      -webkit-box-shadow: 0 0 3px #000
      box-shadow: 0 0 3px #000

      &.tooltip-top
        top: initial
        bottom: 8em

        .scorecard-description-popup-header
          position: static

          &:before,
          &:after
            bottom: -1.2em

          &:before
            border-bottom-color: transparent
            border-top-color: $sh-dark-6

          &:after
            border-bottom-color: transparent
            border-top-color: white
            border-width: 0.7em
            margin-left: -0.7em

      .scorecard-description-popup-header
        position: relative
        font-size: 0.7em
        font-style: italic
        font-weight: 500
        border-bottom: 0.03em solid $sh-dark-4
        margin-bottom: 1.5em
        padding-bottom: 0.4em

        &:after,
        &:before
          bottom: 160%
          left: 50%
          border: solid transparent
          content: " "
          height: 0
          width: 0
          position: absolute
          pointer-events: none

        &:after
          border-color: rgba(255, 255, 255, 0)
          border-bottom-color: #ffffff
          border-width: 0.5em
          margin-left: -0.5em

        &:before
          border-color: rgba(0, 0, 0, 0)
          border-bottom-color: $sh-dark-6
          border-width: 0.6em
          margin-left: -0.6em

    .scorecard-description-popup-body
        font-size: 0.8em
        font-weight: 300

.scorecard-tr:hover
  .scorecard-description-gradient
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0, rgba(221, 238, 248, 0)),
      color-stop(1, rgba(221, 238, 248, 100)))
</style>
