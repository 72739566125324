import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import constants from 'src/store/modules/constants'
import ctas from './modules/ctas'
import groups from 'src/store/modules/main_app/requirements/groups'
import layoutStore from 'src/store/modules/main_app/layout_store'
import productDetails from 'src/store/modules/main_app/product_details/product_details'
import products from 'src/store/modules/main_app/product_list/products'
import requirements from 'src/store/modules/main_app/requirements/requirements'
import scorecard from 'src/store/modules/main_app/scorecard/scorecard'
import subgroups from 'src/store/modules/main_app/requirements/subgroups'

export default new Vuex.Store({
  modules: {
    constants,
    ctas,
    groups,
    layoutStore,
    productDetails,
    products,
    requirements,
    scorecard,
    subgroups
  }
})
