export const state = {
  showWelcomeCard: true
}

export const getters = {
  shouldShowWelcomeCard: state => {
    return state.showWelcomeCard
  }
}

export const mutations = {
  enableWelcomeCard (state) {
    state.showWelcomeCard = true
  },

  disableWelcomeCard (state) {
    state.showWelcomeCard = false
  }
}

export default {
  getters,
  state,
  mutations
}
