import { projectChannel, templateChannel } from '../../../../socket'
import WorkingHours from '../../../services/working_hours_fetcher'

export const state = {
  bestTimeToCallCm: null,
  callBoxClosed: true,
  callCMnow: false,
  sendingRequest: false,
  phoneNumber: null,
  platformSalesCallRequestDetails: null,
  platformSalesCallRequestDetailsLocal: null,
  fileName: null
}

export const getters = {
  afterTomorrowHours: () => {
    return WorkingHours.mappedDayAfterTomorrowHours()
  },

  bestTimeToCallCm: state => {
    if (state.bestTimeToCallCm) {
      return WorkingHours.timeFromTimestamp(state.bestTimeToCallCm)
    } else {
      return null
    }
  },

  callBoxClosed: state => {
    return state.callBoxClosed
  },

  callCMnow: state => {
    return state.callCMnow
  },

  currentDayName: () => {
    return WorkingHours.dayName(WorkingHours.currentTime.valueOf())
  },

  dayNameForHour: () => hour => {
    return WorkingHours.dayName(hour.unixTimestamp)
  },

  isAfterWork: () => {
    return WorkingHours.isAfterWork
  },

  isOutsideWorkingHours: () => {
    return WorkingHours.isOutsideWorkingHours
  },

  isWeekend: () => {
    return WorkingHours.isWeekend
  },

  prefixedDayName: state => {
    return WorkingHours.prefixedDayName(state.bestTimeToCallCm)
  },

  phoneNumber: state => {
    return state.phoneNumber
  },

  sendingRequest: state => {
    return state.sendingRequest
  },

  thisVeryMoment: () => {
    return WorkingHours.valueOf()
  },

  todayHours: () => {
    return WorkingHours.mappedTodayHours()
  },

  tomorrowHours: () => {
    return WorkingHours.mappedTomorrowHours()
  },

  userCallRequestDetails (state, getters, rootState) {
    let user_name = null
    let phone_number = null
    let additional_note = null
    let preffered_time = null

    if (!state.platformSalesCallRequestDetails) {
      user_name = rootState.currentUser.currentUser.first_name
      phone_number = state.phoneNumber || rootState.layoutStore.phoneNumberPrimary || rootState.layoutStore.phoneNumberSecondary
      preffered_time = state.bestTimeToCallCm
    } else {
      user_name = state.platformSalesCallRequestDetails.user_name
      phone_number = state.platformSalesCallRequestDetails.phone_number
      additional_note = state.platformSalesCallRequestDetails.additional_note
      preffered_time = state.platformSalesCallRequestDetails.preffered_time
    }

    return {
      user_name,
      phone_number,
      additional_note,
      preffered_time
    }
  }
}

export const actions = {
  initialDownload ({ commit }) {
    return new Promise((resolve, reject) => {
      projectChannel.push('project:get', [])
        .receive('ok', data => {
          commit('updateBTTC', data.project.contact_info.bttc)
          commit('updatePhoneNumber', data.project.contact_info.primary_phone_number)
          resolve()
        })
    })
  },

  sendPlatformSalesCallDetails ({ state, getters }, isPrefferedTimeNow) {
    let additionalNote = ''
    if ('additional_note' in state.platformSalesCallRequestDetails) {
      additionalNote = state.platformSalesCallRequestDetails.additional_note
    }

    let timeDay = getters.prefixedDayName
    if (isPrefferedTimeNow) {
      timeDay = 'now'
    }

    return new Promise(resolve => {
      templateChannel.push('message:platform_sales_schedule_a_call', {
        user_name: state.platformSalesCallRequestDetails.user_name,
        file_name: state.platformSalesCallRequestDetails.file_name,
        time_day: timeDay,
        time: WorkingHours.timeHourPart(state.platformSalesCallRequestDetails.preffered_time),
        time_zone: WorkingHours.userTimeZoneAbrrev,
        user_phone_number: state.platformSalesCallRequestDetails.phone_number,
        additional_notes: additionalNote
      })
        .receive('ok', () => {
          resolve()
        })
    })
  },

  updateBTTC ({ commit, dispatch, state }, bttcTimestamp) {
    return new Promise((resolve, reject) => {
      dispatch('updateUserContactInfo', {bttc: bttcTimestamp}).then(() => {
        // const isItReschedule = !!state.bestTimeToCallCm
        commit('toggleCallBox')
        commit('updateBTTC', bttcTimestamp)
        // if (isItReschedule) {
        //   window.heap.track('Re-scheduled a call')
        // } else {
        //   window.heap.track('Scheduled a call')
        // }
        resolve()
      })
    })
  },

  updateUserContactInfo ({ commit }, details) {
    return new Promise(resolve => {
      projectChannel.push('project:contact_info:update', details)
        .receive('ok', res => {
          resolve(res)
        })
    })
  },

  updateUserCallRequestDetails ({ commit, dispatch }, details) {
    return new Promise(resolve => {
      dispatch('updateUserContactInfo', { call_request_details_for_platform_sales: details }).then((res) => {
        commit('setPlatformSalesCallRequestDetails', res.contact_info.call_request_details_for_platform_sales)
        resolve()
      })
    })
  }
}

export const mutations = {
  updateBTTC (state, bttcTimestamp) {
    state.bestTimeToCallCm = bttcTimestamp
  },

  updatePhoneNumber (state, phone) {
    state.phoneNumber = phone
  },

  toggleCallBox (state) {
    state.callBoxClosed = !state.callBoxClosed
  },

  toggleSendingRequest (state) {
    state.sendingRequest = !state.sendingRequest
  },

  setFileName (state, details) {
    state.fileName = details
  },

  setPlatformSalesCallRequestDetails (state, details) {
    state.platformSalesCallRequestDetails = details
  },

  setPlatformSalesCallRequestDetailsLocal (state, details) {
    state.platformSalesCallRequestDetailsLocal = details
  }
}

export default {
  getters,
  state,
  mutations,
  actions
}
