import {
  mapGetters,
  mapMutations,
  mapState
} from 'vuex'

export default {
  created () {
    if (this.productSHId && this.areThereMissingVendorAnswers &&
      !this.firstReqWithoutAnswerThatWillAppearInViewport && !this.requirementAnswerObj) {
      this.setFirstReqWithoutAnswerThatWillAppearInViewport(this.requirement.pathKey)
    }
  },

  mounted () {
    if (this.firstReqWithoutAnswerThatWillAppearInViewport === this.requirement.pathKey) {
      this.mountIntersectionObserver()
    }
  },

  beforeDestroy () {
    if (this.observer) {
      this.unmountIntersectionObserver()
    }
  },

  data () {
    return {
      observer: null
    }
  },

  computed: {
    ...mapState({
      firstReqWithoutAnswerThatWillAppearInViewport: state => state.abTestingThemeStore.firstReqWithoutAnswerThatWillAppearInViewport,
      userSeenFirstReqWithoutAnswer: state => state.abTestingThemeStore.userSeenFirstReqWithoutAnswer,
      showContactBar: state => state.abTestingThemeStore.showContactBar
    }),

    ...mapGetters([
      'abTestingComponentCondition',
      'areThereMissingVendorAnswers',
      'includedRequirementsInIncludedSubGroupsInIncludedGroups'
    ]),

    shouldHighlightMissingAnswer () {
      return this.abTestingComponentCondition && this.showContactBar && !this.requirementAnswerObj && !!this.productSHId
    }
  },

  methods: {
    ...mapMutations([
      'setFirstReqWithoutAnswerThatWillAppearInViewport',
      'setUserSeenFirstReqWithoutAnswer'
    ]),

    mountIntersectionObserver () {
      var options = {
        threshold: 1.0
      }

      var callback = (entries, _observer) => {
        if (entries[0].intersectionRatio === 1) {
          this.unmountIntersectionObserver()
          this.setUserSeenFirstReqWithoutAnswer(entries[0].intersectionRatio === 1)
        }
      }
      this.observer = new IntersectionObserver(callback, options)

      this.observer.observe(this.$refs[this.reqPathKeyWithProductSHId])
    },

    unmountIntersectionObserver () {
      this.observer.disconnect()
      this.observer = null
    }
  }
}
