<template>
  <div class="tooltip">
    {{ productReplyMessage }}
    <q-tooltip
      anchor="top middle"
      content-style="font-size: 1em !important"
    >
      {{ tooltipText }}
    </q-tooltip>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'scorecard-demo-or-pricing-tooltip',

    props: {
      shId: {
        type: Number,
        default: 0
      }
    },

    computed: {
      ...mapGetters([
        'awaitingProductTooltipText',
        'awaitingProductActionText'
      ]),

      tooltipText () {
        return this.awaitingProductTooltipText(this.shId)
      },

      productReplyMessage () {
        return this.awaitingProductActionText(this.shId)
      }

    }
  }
</script>

<style lang='stylus' scoped>
  .tooltip
    font-size: 0.9em
    margin-bottom: 0.5em
    cursor: pointer
    text-align: center

    &:hover
      color: #3582c4
</style>
