import iziToast from 'izitoast'
import store from '../store'
const closeButton =
  [
    '<button class="toast__close-button button"></button>',
    (instance, toast) => {
      instance.hide({}, toast)
    }
  ]
const createUndoButton = productSHId => {
  return [
    '<button class="toast__undo-button button">Undo</button>',
    function (instance, toast) {
      instance.hide({
        onClosing: () => {
          store.commit('setAddingProductTrue')
        },
        onClosed: () => {
          store.dispatch('addProductToScorecard', productSHId)
        }
      }, toast)
    }]
}
iziToast.settings({
  transitionIn: 'fadeInLeft',
  transitionOut: 'fadeOutRight',
  animateInside: false,
  position: 'topRight',
  backgroundColor: '#4c4c4c',
  titleColor: 'white',
  messageColor: 'white',
  icon: false,
  pauseOnHover: false,
  progressBar: false,
  close: false
})
export const mapNotification =
  ({ title, message, type, timeout, productSHId, undoButtonPlease }) => {
    let buttons = [closeButton]
    if (undoButtonPlease) buttons = [createUndoButton(productSHId), ...buttons]
    return iziToast.show({ type, title, message, timeout, buttons })
  }
