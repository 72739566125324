// this store module defines global constants
// only getters should be implemented

export const state = {
  AB_TESTING_CONTACT_BAR_THEME_NAME: '2step',
  AB_TESTING_PREFERRED_WAY_TO_CONTACT_MODAL_THEME_NAME: '3step',
  AB_TESTING_VENDOR_THEME_NAME: 'vendor',
  ACCOUNT_SETTINGS: 'accountSettings',
  PRODUCT_DETAILS_CHARTS_GROUP_KEY: 'implementation',
  REQUIREMENT_COLLAPSE: 'collapse',
  REQUIREMENT_EXPAND: 'expand',
  SCORECARD_EXPAND_ALL: 'expand-all',
  SCORECARD_EXPAND_SCORES_ONLY: 'expand-scores-only',
  SCORECARD_SHOW_COLLAPSED_VIEW: 'show-collapsed-view',
  SCORECARD_SORT_ORDER_TITLE: 'title',
  SCORECARD_SORT_ORDER_PRIORITY: 'priority',
  SCORECARD_PREFERRED_CONTACT_NO_CONTACT: 'no_contact',
  SCORECARD_PREFERRED_CONTACT_PHONE: 'phone',
  SCORECARD_PREFERRED_CONTACT_EMAIL: 'email',
  PHONE_MASK: '(000) 000-0000',
  SCORECARD_NO_ANSWER_REQUIREMENT_CLASS: 'scorecard-vendor-answer-container-no-answer',
  SCORECARD_PDF_EXPORT_URL_BASE: '/export/pdf/scorecard/',
  SCORECARD_XLS_EXPORT_URL_BASE: '/export/xls/scorecard/',
  SCORECARD_ZIP_EXPORT_URL_BASE: '/export/zip/scorecard/',
  SUGGEST_PRODUCT_MODAL_BACK_TO_LIST_ACTION: 'back-to-list',
  SUGGEST_PRODUCT_MODAL_CLOSE_ACTION: 'close',
  SUGGEST_PRODUCT_MODAL_SUBMIT_ACTION: 'submit',
  REQUIREMENTS_GROUP_LABEL: 'group',
  REQUIREMENTS_SUB_GROUP_LABEL: 'sub group',
  REQUIREMENTS_REQUIREMENT_LABEL: 'requirement',
  SIDEBAR_DIALOG_BOX_PRODUCTS_ROUTE_NAME: 'productList',
  SIDEBAR_DIALOG_BOX_SETTINGS_ROUTE_NAME: 'templateSettings',
  DASHBOARD_ROUTE_NAME: 'dashboard',
  GROUP_LIST_ROUTE_NAME: 'groupList',
  GROUPS_WITH_SUBGROUP_LIST_ROUTE_NAME: 'groupsWithSubGroupList',
  SCORECARD_ROUTE_NAME: 'scorecard',
  PRODUCT_DETAILS_ROUTE_NAME: 'productDetails',
  PROJECT_SUMMARY_ROUTE_NAME: 'projectSummary',
  ONE_CARD_SURVEY_TYPE_SLUG: 'one-card-survey',
  COMPANY_SIZE_FIELD_SLUG: 'company_size',
  INDUSTRY_FIELD_SLUG: 'main_industry',
  MODULES_FIELD_SLUG: 'relevant_modules',
  LOADING_PROJECT_ROUTE_NAME: 'loadingProject',
  MAX_SCORECARD_PRODUCTS: 5,
  MAX_AVAILABLE_SETTINGS_ON_COMPANY_SETTINGS_CARD: 3,
  TECHNICAL_REQUIREMENTS_NAME: 'Technical Requirements',
  FUNCTIONAL_REQUIREMENTS_NAME: 'Functional Requirements',
  MAX_VISIBLE_PROJECT_TABS: 11,
  PROJECT_TAB_WIDTH: 7,
}

export const getters = {
  SCORECARD_EXPAND_ALL (state) {
    return state.SCORECARD_EXPAND_ALL
  },

  SCORECARD_EXPAND_SCORES_ONLY (state) {
    return state.SCORECARD_EXPAND_SCORES_ONLY
  },

  SCORECARD_SHOW_COLLAPSED_VIEW (state) {
    return state.SCORECARD_SHOW_COLLAPSED_VIEW
  },

  SCORECARD_SORT_ORDER_TITLE (state) {
    return state.SCORECARD_SORT_ORDER_TITLE
  },

  SCORECARD_SORT_ORDER_PRIORITY (state) {
    return state.SCORECARD_SORT_ORDER_PRIORITY
  },

  SCORECARD_PREFERRED_CONTACT_NO_CONTACT (state) {
    return state.SCORECARD_PREFERRED_CONTACT_NO_CONTACT
  },

  SCORECARD_PREFERRED_CONTACT_PHONE (state) {
    return state.SCORECARD_PREFERRED_CONTACT_PHONE
  },

  SCORECARD_PREFERRED_CONTACT_EMAIL (state) {
    return state.SCORECARD_PREFERRED_CONTACT_EMAIL
  },

  SCORECARD_NO_ANSWER_REQUIREMENT_CLASS (state) {
    return state.SCORECARD_NO_ANSWER_REQUIREMENT_CLASS
  },
}

export default {
  getters,
  state,
}
