//
//
//
// 12.12.2018 Jarek
// Logic was not defined properly.
// After figuring it out this module and related components require refactoring
//
//
//
const getDefaultState = () => {
  return {
    deselectedOnSelectedView: [],
    // close || back-to-list || submit
    messageAction: '',
    messageVisible: false,
    searchingFor: '',
    selectedProducts: [],
    showSuggestProductFirstTimeTootlip: true,
    showNothingFoundCopy: false,
    showOnlySelected: false,
    showSubmitMessage: false,
    showSuggestProductsModal: false,
    textareaEmpty: true,
    userMadeFirstSelection: false,
    userMadeFirstDeselection: false,
    timeUpForSelectionTooltip: false,
    timeUpForDeselectionTooltip: false
  }
}

const state = getDefaultState()

const actions = {
  handleSearchInput ({commit, getters}, val) {
    commit('setSearchingFor', val)
    if (!getters.productsToShow.length) {
      setTimeout(() => {
        commit('setShowNothingFoundCopy', true)
      }, 1000)
    }
    else {
      commit('setShowNothingFoundCopy', false)
    }
  },

  handleCloseClick ({commit, state, rootState}, e) {
    if ((!state.selectedProducts.length || state.showSubmitMessage) && !state.deselectedOnSelectedView.length) {
      commit('closeSuggestProductsModal')
    }
    else {
      commit('setMessageAction', rootState.constants.SUGGEST_PRODUCT_MODAL_CLOSE_ACTION)
      commit('showMessage')
    }
  },

  deselectSuggestion ({commit, state}, [SHId, isDeselectedLocally]) {
    if (!state.userMadeFirstDeselection) commit('markFirstDeselection')
    commit('setShowSuggestProductFirstTimeTooltip', false)

    if (!state.showOnlySelected) {
      commit(
        'setSelectedProducts',
        state.selectedProducts.filter(p => p.selecthub_id !== SHId))
    }
    else {
      if (isDeselectedLocally) {
        commit('setDeselectedOnSelectedView', [...state.deselectedOnSelectedView, SHId])
      }
      else {
        commit(
          'setDeselectedOnSelectedView',
          state.deselectedOnSelectedView.filter(pSHId => pSHId !== SHId))
      }
    }
  },

  messageBackToListAction ({commit, state}, saveChanges) {
    if (saveChanges) {
      state.deselectedOnSelectedView.forEach(SHId => {
        commit('setSelectedProducts', state.selectedProducts.filter(p => p.selecthub_id !== SHId))
      })
    }

    commit('setDeselectedOnSelectedView', [])
    commit('setShowOnlySelected', false)
    commit('hideMessage')
  },

  submitSuggestedProducts ({commit, dispatch, state, rootState}) {
    if (state.deselectedOnSelectedView.length) {
      commit('setMessageAction', rootState.constants.SUGGEST_PRODUCT_MODAL_SUBMIT_ACTION)
      commit('setMessageVisible', true)
      return
    }

    if (!state.messageVisible || state.messageAction === rootState.constants.SUGGEST_PRODUCT_MODAL_SUBMIT_ACTION) {
      const selectedProductsIds = state.selectedProducts.map(p => p.selecthub_id)

      // Backend call - for grepping: push templateChannel user:update_config
      dispatch(
        'markProductsSuggestedToAnalyst',
        [...rootState.currentUser.productsSuggestedToAnalyst, ...selectedProductsIds],
        { root: true })

      commit('setShowSubmitMessage', true)
      setTimeout(() => {
        // Please leave this commented - it can help restore tracking quicker in the future.
        // window.heap.track(
        //   'Suggest a Product request sent',
        //   {number_of_products_requested: selectedProductsIds.length})

        commit('closeSuggestProductsModal')
      }, 6000)
    }
  },

  toggleOnlySelected ({commit, state, rootState}) {
    if (state.deselectedOnSelectedView.length) {
      commit(
        'setMessageAction',
        rootState.constants.SUGGEST_PRODUCT_MODAL_BACK_TO_LIST_ACTION)
      commit('showMessage')
      return
    }
    commit('setShowOnlySelected', !state.showOnlySelected)
  },

  selectSuggestion ({commit, state, rootGetters}, SHId) {
    if (!state.userMadeFirstSelection) commit('markFirstSelection')
    const selectedProduct =
      rootGetters.productsWithoutResponses.find(p => p.selecthub_id === SHId)

    if (state.selectedProducts.includes(selectedProduct)) {
      commit(
        'setSelectedProducts',
        state.selectedProducts.filter(p => p.selecthub_id !== SHId))
    }
    else {
      commit(
        'setSelectedProducts',
        [...state.selectedProducts, selectedProduct])
    }
  }
}

const mutations = {
  openSuggestProductsModal (state) {
    state.showSuggestProductsModal = true
    // Please leave this commented - it can help restore tracking quicker in the future.
    // window.heap.track('Suggest a Product opened')
  },

  closeSuggestProductsModal (state) {
    Object.assign(state, getDefaultState())
  },

  handleTextareaInput (state, val) {
    state.textareaEmpty = !val
  },

  hideMessage (state) {
    state.messageVisible = false
  },

  setDeselectedOnSelectedView (state, products) {
    state.deselectedOnSelectedView = products
  },

  setMessageAction (state, action) {
    state.messageAction = action
  },

  setMessageVisible (state, bool) {
    state.messageVisible = bool
  },

  setShowSuggestProductFirstTimeTooltip (state, bool) {
    state.showSuggestProductFirstTimeTootlip = bool
  },

  setShowNothingFoundCopy (state, bool) {
    state.showNothingFoundCopy = bool
  },

  setShowOnlySelected (state, bool) {
    state.showOnlySelected = bool
  },

  setShowSubmitMessage (state, bool) {
    state.showSubmitMessage = bool
  },

  setSearchingFor (state, val) {
    state.searchingFor = val
  },

  setSelectedProducts (state, products) {
    state.selectedProducts = products
  },

  markFirstDeselection (state) {
    state.userMadeFirstDeselection = true
  },

  markFirstSelection (state) {
    state.userMadeFirstSelection = true
  },

  showMessage (state) {
    state.messageVisible = true
  },

  markTimeUpForSelectionTooltip (state) {
    state.timeUpForSelectionTooltip = true
  },

  markTimeUpForDeselectionTooltip (state) {
    state.timeUpForDeselectionTooltip = true
  }
}

const getters = {
  activeViewProducts (state, rootGetters) {
    if (state.showOnlySelected) {
      return state.selectedProducts.sort((a, b) => b.name < a.name)
    }
    else {
      return rootGetters.productsWithoutResponses.sort((a, b) => b.name < a.name)
    }
  },

  productsToShow (state, getters) {
    if (!state.searchingFor.length) {
      return getters.activeViewProducts
    }
    else {
      return getters.activeViewProducts.filter(p => p.name.toLowerCase().includes(state.searchingFor.toLowerCase()))
    }
  },

  computedSelectedProductsCount (state) {
    return state.selectedProducts.length - state.deselectedOnSelectedView.length
  }
}

export default {
  actions,
  getters,
  mutations,
  state
}
