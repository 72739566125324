import { projectChannel } from '../../../../../socket'

export const state = {
  all: {}
}

export const actions = {
  downloadProductsReviews ({commit}) {
    return new Promise((resolve, reject) => {
      projectChannel.push('extended_reviews:load')
        .receive('ok', payload => {
          commit('attachProductReviews', payload)
          resolve()
        })
    })
  }
}

export const mutations = {
  attachProductReviews (state, payload) {
    state.all = payload
  }
}

export const getters = {
  getReviewsBySHId: (state) => (productSHId) => {
    return state.all[productSHId]
  }
}

export default {
  actions,
  getters,
  mutations,
  state
}
