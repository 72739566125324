// IMPORTANT! cardsForUser contain cards as references to cards
// dryRunCardsForUser contain cards as references to dryRunCards

import { cardsChannel } from '../../../../socket'
import cardAnswerText from '../../../services/cards_service'

import Vue from 'vue'

export const state = {
  precard: {},
  cards: [],
  dryRunCards: [],
  cardsForUser: [],
  dryRunCardsForUser: [],
  currentIndex: null,
  flows: [],
  layoutFlags: {},
  objectsForEval: {},
  totalRequirementsForCards: 0,
  matchedRequirements: 0,
  templateSettingsValid: true,
  surveyType: null,
  highlightedCardName: null
}

export const actions = {
  downloadCards ({commit, rootState}) {
    return new Promise(resolve => {
      cardsChannel.push('cards:get')
        .receive('ok', payload => {
          commit('updateTotalRequirementCountersForCards', payload.total_requirements_count)
          commit('updateMatchedRequirementCounters', payload.included_requirements_count || 0)
          payload.currentUser = rootState.currentUser.currentUser
          commit('downloadCards', payload)
          commit('setSurveyType', payload.type || '')
          resolve()
        })
    })
  },

  markCardsFinished ({commit}) {
    return new Promise((resolve, reject) => {
      commit('markCardsFinished')
      resolve()
    })
  },

  markTemplateReady ({commit}) {
    return new Promise((resolve, reject) => {
      commit('markTemplateReady')
      resolve()
    })
  },

  showTemplate ({commit}) {
    return new Promise((resolve, reject) => {
      commit('showTemplate')
      resolve()
    })
  },

  maybeGiveDefaultAnswer ({ dispatch, state }) {
    const currentCard = state.cardsForUser[state.currentIndex]
    if ((currentCard.lastAnswerKey === undefined || currentCard.lastAnswerKey === null || currentCard.lastAnswerKey.length === 0) && currentCard.defaultAnswerKey !== undefined) {
      dispatch('storeAnswer', {card: currentCard, answer: currentCard.defaultAnswerKey})
    }
  },

  storeAnswer ({commit, state}, answer) {
    return new Promise((resolve, reject) => {
      commit('storeAnswer', answer)

      cardsChannel.push('cards:update', {cards: state.cards})
        .receive('ok', payload => {
          commit('updateMatchedRequirementCounters', payload.included_requirements_count)
          resolve()
        })
        .receive('error', payload => {
          console.log('error', payload)
        })
    })
  },

  dryRunStoreAnswer ({commit, dispatch, state}, answer) {
    return new Promise((resolve, reject) => {
      answer.dryRun = true // crucial flag for dry run
      commit('storeAnswer', answer)

      cardsChannel.push('filters:dry-run', {cards: state.dryRunCards})
        .receive('ok', payload => {
          // Dispatching action from remote module
          dispatch('dryRunUpdateMatchedRequirementCounters', payload.included_requirements_count, { root: true })
          resolve()
        })
        .receive('error', payload => {
          console.log('error', payload)
        })
    })
  },

  persistDryRunCards ({commit, state}) {
    return new Promise((resolve, reject) => {
      cardsChannel.push('cards:update', {cards: state.dryRunCards})
        .receive('ok', () => {
          resolve()
        })
        .receive('error', payload => {
          console.log('error', payload)
        })
    })
  },

  resetDryRunCardsForUser ({commit}) {
    commit('resetDryRunCardsForUser')
    commit('dryRunUpdateMatchedRequirementCounters', null, { root: true })
  }
}

export const getters = {
  layoutFlagsReady: (state) => {
    // Check if object is empty meaning no data came in yet
    // It's not checking flag - it only check the type - true here is meaningless
    return typeof state.layoutFlags.templateReady === typeof true &&
      typeof state.layoutFlags.cardsFinished === typeof true &&
      typeof state.layoutFlags.showTemplate === typeof true
  },

  cardsForUser: (state) => {
    return state.cardsForUser
  },

  cardsForUserForFlow: (state, getters) => (flowName) => {
    const flow = state.flows.find(f => f.flowName === flowName)
    return flow.cards.map(cN => getters.cardsForUser.find(c => c.cardName === cN))
  },

  findCardByKey: (state) => (cardName) => {
    if (state.cards.length === 0) {
      return {}
    } else {
      return state.cards.find(card => card.cardName === cardName)
    }
  },

  findCardByFieldName: (state) => (fieldName) => {
    if (state.cards.length === 0) {
      return {}
    } else {
      return state.cards.find(card => card.fieldName === fieldName)
    }
  },

  precard: (state) => {
    return state.precard
  },

  cardsFinished: (state) => {
    return state.layoutFlags.cardsFinished
  },

  templateReady: (state) => {
    return state.layoutFlags.templateReady
  },

  showTemplate: (state) => {
    return state.layoutFlags.showTemplate
  },

  cardsCurrentIndex: (state) => {
    return state.currentIndex
  },

  cardsLength: (state) => {
    return state.cardsForUser.length
  },

  totalRequirementsCountForCards: (state) => {
    return state.totalRequirementsForCards
  },

  matchedRequirementsCountForCards: (state) => {
    return state.matchedRequirements
  },

  dryRunCards: (state) => {
    return state.dryRunCards
  },

  dryRunCardsForUser: (state) => {
    return state.dryRunCardsForUser
  },

  dryRunSameAsOriginal: (state) => {
    let dryRunAnswers = state.dryRunCards.map(c => {
      return (c.lastAnswerKey || '').toString().split(',').sort()
    }).join()

    let cardsAnswers = state.cards.map(c => {
      return (c.lastAnswerKey || '').toString().split(',').sort()
    }).join()

    return dryRunAnswers === cardsAnswers
  },

  templateSettingsValid () {
    return state.templateSettingsValid
  },

  getCardAnswerText: state => cardSlug => {
    return cardAnswerText(state.cards, cardSlug)
  }
}

export const mutations = {
  downloadCards (state, payload) {
    state.objectsForEval = payload.objectsForEval
    let cardSet = payload.cards
    if (payload.precard) {
      cardSet = [payload.precard].concat(payload.cards)
    }
    (cardSet).forEach(card => {
      // eslint-disable-next-line no-new-func
      card.onShowCallback = Function(card.onShowCallback)
      // eslint-disable-next-line no-new-func
      card.onAnswerSelectionCallback = Function(card.onAnswerSelectionCallback)

      card.flowName =
        (payload
          .flows
          .find(flow => flow.cards.find(fcN => fcN === card.cardName)) || {})
          .flowName

      if (card.vars) {
        Object.entries(card.vars).forEach((objArr) => {
          let attr = objArr[0]
          let vars = objArr[1]
          Object.entries(vars).forEach((subObjArr) => {
            let key = subObjArr[0]
            let value = subObjArr[1]
            // TODO: Make it use the real user name
            // eslint-disable-next-line no-unused-vars
            let currentUser = payload.currentUser
            // eslint-disable-next-line no-eval
            value = eval(value)
            let regex = new RegExp('{{' + key + '}}')
            let newAttrVal = card[attr].replace(regex, value)
            card[attr] = newAttrVal
          })
        })
      }

      if (card.answersEvalFunction) {
        // eslint-disable-next-line no-unused-vars
        let objectsForEval = payload.objectsForEval
        // eslint-disable-next-line no-eval
        card.answers = eval(card.answersEvalFunction)
      }
    })

    // cardsChannel.push('cards:update', {cardsFinished: false})
    // cardsChannel.push('cards:update', {templateReady: false})
    // cardsChannel.push('cards:update', {showTemplate: false})
    // cardsChannel.push('cards:update', {showWelcomeCard: false})
    Vue.set(state.layoutFlags, 'cardsFinished', payload.cardsFinished)
    Vue.set(state.layoutFlags, 'templateReady', payload.templateReady)
    Vue.set(state.layoutFlags, 'showTemplate', payload.showTemplate)

    state.precard = payload.precard
    state.cards = payload.cards
    state.flows = payload.flows

    // Duplication - ref 1.
    state.cardsForUser =
      state
        .flows.find(f => f.flowName === 'default')
        .cards
        .map(cN => state.cards.find(card => card.cardName === cN))

    // //////////////////////////////////////////////
    // add cards from subflows - way too complex
    let subflowCardsMissing =
      state.cardsForUser
        .map(c => {
          if (c.subflows) {
            const matchingSF = c.subflows.find(sf => sf.answerKey === c.lastAnswerKey)
            if (matchingSF !== undefined) {
              const subflow = state.flows.find(f => f.flowName === matchingSF.subflow)
              return {
                index: state.cardsForUser.indexOf(c),
                cards: subflow.cards.map(cN => state.cards.find(c => c.cardName === cN))
              }
            }
            return undefined
          }
        })
        .filter(n => n !== undefined)

    // We are modifying array which indexes were counted before, so we need to,
    // add the index of each missing card to splice
    subflowCardsMissing.forEach((sCM, i) => {
      state.cardsForUser.splice(sCM.index + 1 + i, 0, ...sCM.cards)
    })
    // End of add cards from subflows
    // //////////////////////////////////////////////

    state.currentIndex = 0
  },

  markCardsFinished (state) {
    Vue.set(state.layoutFlags, 'cardsFinished', true)
    cardsChannel.push('cards:update', {cardsFinished: state.layoutFlags.cardsFinished})
    // Please leave this commented - it can help restore tracking quicker in the future.
    // window.heap.track('Finished cards')
  },

  markTemplateReady (state) {
    Vue.set(state.layoutFlags, 'templateReady', true)
    cardsChannel.push('cards:update', {templateReady: state.layoutFlags.templateReady})
  },

  showTemplate (state) {
    Vue.set(state.layoutFlags, 'showTemplate', true)
    cardsChannel.push('cards:update', {showTemplate: state.layoutFlags.showTemplate})
  },

  enableWelcomeCard (state) {
    Vue.set(state.layoutFlags, 'showWelcomeCard', true)
  },

  disableWelcomeCard (state) {
    Vue.set(state.layoutFlags, 'showWelcomeCard', false)
    cardsChannel.push('cards:update', {showWelcomeCard: state.layoutFlags.showWelcomeCard})
  },

  storeAnswer (state, payload) {
    const dryRun = payload.dryRun
    let cardFromStore
    let cardIndex

    if (dryRun) {
      cardFromStore = state.dryRunCardsForUser.find(c => c === payload.card)
      cardIndex = state.dryRunCardsForUser.indexOf(cardFromStore)
    } else {
      cardFromStore = state.cardsForUser.find(c => c === payload.card)
      cardIndex = state.cardsForUser.indexOf(cardFromStore)
    }

    if (!cardFromStore) return

    let oldAnswer = cardFromStore.lastAnswerKey
    let oldAnswerSubFlow
    if (oldAnswer && cardFromStore.subflows) {
      oldAnswerSubFlow = cardFromStore.subflows.find(answ => {
        return answ.answerKey === oldAnswer
      })
    }
    // store single answer
    Vue.set(cardFromStore, 'lastAnswerKey', payload.answer)

    // TODO: Temporary fix to ease backend - refactor
    let answerByKey
    let answersByKeys = []
    if (cardFromStore.answers) {
      if (cardFromStore.type === 'checkbox') {
        const answersArr = cardFromStore.lastAnswerKey.split(',').map(item => {
          if (isNaN(parseInt(item))) {
            return item
          } else {
            return parseInt(item)
          }
        })
        answersByKeys = cardFromStore.answers.filter(ans => answersArr.find(pa => pa === ans.answerKey))
      //
      } else {
        answerByKey = cardFromStore.answers.find(ans => ans.answerKey === payload.answer)
      }
    }

    if (answerByKey) {
      Vue.set(cardFromStore, 'lastAnswerKey_BE', answerByKey.answerKey)
      Vue.set(cardFromStore, 'lastAnswerText_BE', answerByKey.answerText)
    //
    } else if (answersByKeys.length > 0) {
      Vue.set(cardFromStore, 'lastAnswerKey_BE', answersByKeys.map(ans => ans.answerKey))
      Vue.set(cardFromStore, 'lastAnswerText_BE', answersByKeys.map(ans => ans.answerText))
    //
    } else {
      // Need to clear any previously stored keys
      Vue.set(cardFromStore, 'lastAnswerKey_BE', '')
      Vue.set(cardFromStore, 'lastAnswerText_BE', payload.answer)
    }
    // end of temp fix for backed

    // update set of all cards with new / updated answer - for backend
    if (dryRun) {
      Vue.set(state.dryRunCards, state.dryRunCards.indexOf(cardFromStore), cardFromStore)
    //
    } else {
      Vue.set(state.cards, state.cards.indexOf(cardFromStore), cardFromStore)
    }

    // check for sub-flow change - for front-end  - subflow-triggering answers
    // have to have answerKey
    if (cardFromStore.subflows) {
      if (oldAnswerSubFlow && oldAnswerSubFlow.subflow) {
        // insert card from subflow to user's cards
        if (dryRun) {
          state.dryRunCardsForUser.splice(cardIndex + 1, 1)
        //
        } else {
          state.cardsForUser.splice(cardIndex + 1, 1)
        }
      }
      const answerSubFlow = cardFromStore.subflows.find(answ => {
        return answ.answerKey === payload.answer
      })
      //
      if (answerSubFlow) {
        let subFlow = state.flows.find(f => f.flowName === answerSubFlow.subflow)

        if (dryRun) {
          state.dryRunCardsForUser
            .splice(
              cardIndex + 1,
              0,
              ...subFlow.cards.map(cN => state.dryRunCards.find(c => c.cardName === cN)))
        //
        } else {
          state.cardsForUser
            .splice(
              cardIndex + 1,
              0,
              ...subFlow.cards.map(cN => state.cards.find(c => c.cardName === cN)))
        }
      }
    }

    // This should not count answers changed in template settings!
    // Please leave this commented - it can help restore tracking quicker in the future.
    // window.heap.addUserProperties({
    //   'Number of answers given on cards': state.cardsForUser.filter(c =>
    //     c.lastAnswerKey !== undefined && c.lastAnswerKey !== null).length
    // })
  },

  storeCurrentIndex (state, index) {
    state.currentIndex = index
  },

  updateTotalRequirementCountersForCards (state, count) {
    state.totalRequirementsForCards = count
  },

  updateMatchedRequirementCounters (state, count) {
    state.matchedRequirements = count
  },

  dryRunCompileCards (state) {
    state.dryRunCards = JSON.parse(JSON.stringify(state.cards))
  },

  dryRunCompileCardsForUser (state) {
    // Duplication - ref 2.
    state.dryRunCardsForUser =
      state
        .flows.find(f => f.flowName === 'default')
        .cards
        .map(cN => state.dryRunCards.find(card => card.cardName === cN))

    // //////////////////////////////////////////////
    // add cards from subflows - way too complex
    let subflowCardsMissing =
      state.dryRunCardsForUser
        .map(c => {
          if (c.subflows) {
            const matchingSF = c.subflows.find(sf => sf.answerKey === c.lastAnswerKey)
            if (matchingSF !== undefined) {
              const subflow = state.flows.find(f => f.flowName === matchingSF.subflow)
              return {
                index: state.dryRunCardsForUser.indexOf(c),
                cards: subflow.cards.map(cN => state.dryRunCards.find(c => c.cardName === cN))
              }
            }
            return undefined
          }
        })
        .filter(n => n !== undefined)

    // We are modifying array which indexes were counted before, so we need to,
    // add the index of each missing card to splice
    subflowCardsMissing.forEach((sCM, i) => {
      state.dryRunCardsForUser.splice(sCM.index + 1 + i, 0, ...sCM.cards)
    })
    // End of add cards from subflows
    // //////////////////////////////////////////////
  },

  addCardsInfoToHeapUserAttributes (state) {
    // Please leave this commented - it can help restore tracking quicker in the future.
    // window.heap.addUserProperties({
    //   'Budget size': cardAnswerText(state.cards, 'estimated_budget'),
    //   'Company size': cardAnswerText(state.cards, 'company_size'),
    //   'Industry': cardAnswerText(state.cards, 'main_industry'),
    //   'Modules': cardAnswerText(state.cards, 'relevant_modules'),
    //   'Current product name': cardAnswerText(state.cards, 'current_system'),
    //   'Purchase role': cardAnswerText(state.cards, 'role'),
    //   'Purchase type': cardAnswerText(state.cards, 'replacement_bi_system'),
    //   'Timeline for the purchase': cardAnswerText(state.cards, 'purchase_timeline'),
    // })
  },

  setValidationForTemplateSettings (state, valid) {
    state.templateSettingsValid = valid
  },

  resetDryRunCardsForUser (state) {
    Vue.set(state, 'dryRunCardsForUser', state.cardsForUser)
  },

  setSurveyType (state, surveyType) {
    state.surveyType = surveyType
  },

  setHighilightedCardName (state, cardName) {
    state.highlightedCardName = cardName
  }
}

export default {
  actions,
  getters,
  state,
  mutations
}
