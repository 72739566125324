<template>
  <div class="sidebar__container">
    <img
      class="sh-logo"
      src="../../../js/src/statics/logo_tag-white.svg"
    />

    <ul class="sidebar__groups-list">
      <li
        v-for="group in includedGroups"
        :key="`scorecard${group.id}`"
        class="groups-list__single"
        :class="{highlighted: highlightedGroupId === `anchor-${group.id}`}"
        @click="scrollTo(`anchor-${group.id}`)"
      >
        {{group.name}}
      </li>
    </ul>

    <div class="dropdown-ctas__list">
      <dropdown-cta position="dropdown_1" />

      <dropdown-cta position="dropdown_2" />
    </div>

    <div class="banner-ctas__list">
      <banner-cta position="banner_1" />

      <banner-cta position="banner_2" />
    </div>
  </div>
</template>

<script>
import {
  mapState
} from 'vuex'

import BannerCta from './ctas/BannerCta'
import DropdownCta from './ctas/DropdownCta'

export default {
  name: 'sidebar',

  components: {
    BannerCta,
    DropdownCta
  },

  created () {
    console.log("tested")
    window.addEventListener('ungated_leaderboard_data_ready', this.mountIntersectionObserver)
  },

  beforeDestroy () {
    this.unmountIntersectionObserver()
  },

  data () {
    return {
      intersectionObserver: null,
      lastTwoObservedIntersections: []
    }
  },

  computed: {
    ...mapState({
      includedGroups: (state, getters) => getters.includedGroups('name')
    }),

    highlightedGroupId () {
      return this.lastTwoObservedIntersections[this.lastTwoObservedIntersections.length - 1]
    },

    scrollToOffset () {
      const el = document.getElementById('group-list-offset-calculation-helper')
      if (el) {
        return el.getBoundingClientRect().y
      }
      else {
        return 0
      }
    }
  },

  methods: {
    intersectionObserverCallback (entries) {
      const targetId = entries[0].target.id

      if (entries[0].isIntersecting) {
        this.lastTwoObservedIntersections.push(targetId)

        if (this.lastTwoObservedIntersections.length > 2) {
          this.lastTwoObservedIntersections.shift()
        }
      } else {
        this.lastTwoObservedIntersections = this.lastTwoObservedIntersections.filter(id => {
          return id !== targetId
        })
      }
    },

    mountIntersectionObserver () {
      this.$nextTick(() => {
        if (this.intersectionObserver) return

        this.intersectionObserver = new IntersectionObserver(
          this.intersectionObserverCallback,
          {
            root: document.getElementById('app'),
            rootMargin: '-10% 0% 0% 0%'
          }
        )

        this.includedGroups.forEach(group => {
          const target = document.getElementById(`anchor-${group.id}`)
          this.intersectionObserver.observe(target)
        })
      })
    },

    scrollTo (anchorId) {
      this.$scrollTo(`#${anchorId}`, 500, {
        offset: (-1 * this.scrollToOffset),
        container: '#prerendered-scorecard-scroll-container'
      })
    },

    unmountIntersectionObserver () {
      this.intersectionObserver = null
    }
  }
}
</script>

<style lang="stylus" scoped>
@import '~variables'

.sidebar__container
  display: flex
  flex-direction: column
  height: 100%
  background: $sh-dark
  color: $sh-dark-7
  overflow-y: auto

  .sh-logo
    max-width: 11vw;
    max-height: 5em;
    padding: 0 1vw;
    margin-bottom: 1.4em;

  .sidebar__groups-list
    padding: 0
    margin: 0 1vw
    list-style-type: none
    min-height: 10em
    max-height: 14em
    overflow-y: auto
    border-bottom: 0.1em solid $sh-dark-7
    
    .groups-list__single
      padding: 0.2em 0
      font-size: "calc(0.92 * %s)" % $express_rem
      color: #bdbdbd
      cursor: pointer

      &:hover,
      &.highlighted
        color: white
        font-weight: 500

.dropdown-ctas__list
  margin-top: 1em
  margin-bottom: 1em

.banner-ctas__list
  min-height: 30em

>>>.button-cta__container
  z-index: 2
</style>
