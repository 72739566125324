<template>
  <div>
    <q-btn
      :text-color="menu ? 'white' : 'blue'"
      :class="{ 'add__product--blue': menu }"
      class="add__product"
      outlined
      rounded
      label="+ Add product"
      padding="0.3rem 0"
      @click.native="menu = !menu"
    />

    <div
      v-if="menu"
      class="add-product__menu"
      v-sh-click-outside="handleClickOutside"
    >
      <div
        v-if="!isVendorTheme"
        class="menu-list__header"
      >
        Matching products
      </div>

      <div
        v-if="availableProductsMatching.length === 0 && !isVendorTheme"
        class="menu-list__subheader"
      >
        No matching products
      </div>

      <div v-else>
        <div
          v-for="(product) in availableProductsMatching"
          :key="product.id"
          class="list__element"
          @click="addProduct(product.selecthub_id)"
        >
          <div>{{ product.name }}</div>
        </div>
      </div>

      <div class="spacer" />

      <div class="other-products__background">
        <div
          v-if="!isVendorTheme"
          class="menu-list__header"
        >
          Other products
        </div>

        <div
          v-if="availableProductsNotMatching.length === 0"
          class="menu-list__subheader"
        >
          No matching products
        </div>

        <div v-else>
          <div
            v-for="(product) in availableProductsNotMatching"
            :key="product.id"
            class="list__element"
            @click="addProduct(product.selecthub_id)"
          >
            <div>{{ product.name }}</div>
          </div>
        </div>

        <div class="spacer" />
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapGetters
} from 'vuex'

import ClickOutside from 'src/directives/click_outside'

export default {
  name: 'add-product',

  directives: {
    ClickOutside
  },

  props: {
    productColumn: {
      type: Number,
      required: true,
    },
  },

  data () {
    return {
      menu: false,
    }
  },

  computed: {
    ...mapGetters([
      'isVendorTheme',
      'productsMatching',
      'productsNotMatching',
      'scorecardProductSHIds',
    ]),

    availableProductsMatching () {
      return this.availableProductsFor(this.productsMatching)
    },

    availableProductsNotMatching () {
      return this.availableProductsFor(this.productsNotMatching)
    },
  },

  methods: {
    addProduct (productSHId) {
      if (!process.env.VUE_APP_IS_SEO_PRERENDERED) {
        this.$store.commit('setAddingProductTrue')
        this.$store.dispatch('addProductToScorecard', productSHId)
        // Please leave this commented - it can help restore tracking quicker in the future.
        // window.heap.track('Adding products to scorecard - Add product dropdown on scorecard')
      }
      else {
        this.$store.commit('replaceScorecard', [...this.$store.state.scorecard.scorecardProductSHIds, productSHId])
      }

      this.menu = false
    },

    availableProductsFor (productList) {
      return productList.filter((p) => {
        return !this.scorecardProductSHIds.find(spSHID => p.selecthub_id.toString() === spSHID.toString())
      })
    },

    handleClickOutside () {
      this.menu = false
    },
  },
}
</script>

<style scoped lang="stylus">
  @import '~variables'

  .add-product__menu
    position: absolute
    top: 70%
    background-color: white
    max-height: 40rem
    width: 14vw
    z-index: 1
    box-shadow: 0 1px 5px rgba(0,0,0,0.2), 0 2px 2px rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12);
    overflow-y: auto

    .menu-list__header
      color: $sh-dark-5
      padding: 0.6rem 1vw
      font-weight: bold

    .other-products__background
      background-color: $sh-dark-0

    .list__element
      color: $sh-dark-5
      padding: 0.6rem 1vw
      cursor: pointer

      &:hover
        background-color: $sh-light-blue

    .menu-list__subheader
      color: $sh-dark-5
      font-style: italic
      padding: 1rem 1vw 0.5rem

    .spacer
      padding-bottom: 0.8rem

  .add__product
    transition: all 0.2s
    border: solid 0.1em $sh-hover-blue
    font-size: 0.9rem
    width: 9vw
    text-transform: initial
    background-color: white

    &.add__product--blue
      background-color: $sh-hover-blue

  .scorecard-add-product
    background: $sh-dark-0
    margin-top: 10%
    height: 84%
    display: flex
    justify-content: center
    align-items: center
    color: $sh-blue-3
    overflow: visible
    position: relative

  .fade-enter-active, .fade-leave-active
    transition: opacity .4s

  .fade-enter, .fade-leave-to
    opacity: 0
</style>
