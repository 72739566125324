export const state = {
  accountSettings: 'Account Settings',
  scorecardTitle: 'Comparison Report',
  dashboardTitle: 'Dashboard',
  projectSummaryTitle: 'Project Summary',
  inDepthReportTitle: 'In-Depth Report',
  projectSettingsTitle: 'Project Settings',
  compareProductsTitle: 'Compare Products',
  loadingProjectTitle: 'Loading your project.',
  loadingNewProjectTitle: 'Creating your new project.',
  dashboardSubtitle: 'Your active projects at a glance.',
  scorecardSubtitle: 'Perform vendor comparisons with scorecards.',
  inDepthReportSubtitle: 'See under the hood and how far "out of the box" gets you.',
  projectSettingsSubtitle: 'Set your preferences for Industry, Company Size and more.',
  compareProductsSubtitle: 'Detailed comparison of products',
  loadingProjectSubtitle: 'Wait a second...',
  requirementsTemplateSubtitle: 'Based on industry-standard best-practices, making sure nothing gets missed.',
}

export default {
  state,
}
