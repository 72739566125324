<template>
  <svg class="lock__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.09 25.13"><path class="cls-1" d="M16.29,11H14.53a.51.51,0,0,0,.09-.27V5.11a5.07,5.07,0,1,0-10.14,0V10.7a.5.5,0,0,0,.08.27H2.81A2.79,2.79,0,0,0,0,13.74v8.62a2.8,2.8,0,0,0,2.81,2.77H16.29a2.8,2.8,0,0,0,2.8-2.77V13.74A2.79,2.79,0,0,0,16.29,11ZM5.48,10.7V5.11a4.07,4.07,0,1,1,8.14,0V10.7a.5.5,0,0,0,.08.27H5.39A.51.51,0,0,0,5.48,10.7ZM18.09,22.36a1.79,1.79,0,0,1-1.8,1.77H2.81A1.79,1.79,0,0,1,1,22.36V13.74A1.79,1.79,0,0,1,2.81,12H16.29a1.79,1.79,0,0,1,1.8,1.77Z"/></svg>
</template>

<script>
export default {
  name: 'icon-lock'
}
</script>

<style lang="stylus" scoped>
.lock__icon
  width: 1.5vw
  height: 1.5vw

  path
    fill: white
</style>
