<template>
  <div>
    <select-label
      :label="label"
      :error-message="errorMessage"
      :error="error"
    />

    <q-select
      ref="selectDropdown"
      :class="{
        'select__dropdown--dense': selectSize === 'small',
        'select__dropdown--medium': selectSize === 'medium',
        'select__dropdown--regular': selectSize === 'regular',
      }"
      class="select__dropdown"
      :borderless="borderless"
      :dense="dense"
      :disabled="disabled"
      emit-value
      :error="error"
      hide-bottom-space
      hide-dropdown-icon
      map-options
      :options="options"
      :outlined="outlined"
      stack-label
      :style="inputStyle"
      :value="value"
      @popup-show="menuOpen = true"
      @popup-hide="menuOpen = false"
      @click.native="listenForScroll"
    >
      <template #selected-item="{ opt }">
        <div :class="{ 'dropdown__active': menuOpen }">
          {{ opt.label }}
        </div>
      </template>

      <template #append>
        <q-icon
          v-if="clearable && value"
          name="r_cancel"
          :size="iconSize"
          @click.stop="selectElement('')"
        />

        <q-icon
          :class="{ 'dropdown__active arrow arrow--open': menuOpen, 'arrow arrow--close': !menuOpen }"
          :size="iconSize"
          name="r_expand_more"
        />
      </template>

      <template #option="{ opt, selected }">
        <q-item
          v-ripple
          class="dropdown__menu"
          active-class="dropdown__background"
          :active="selected"
          clickable
          @click="selectElement(opt.value)"
        >
          <q-item-section>{{ opt.label }}</q-item-section>
        </q-item>
      </template>
    </q-select>
  </div>
</template>

<script>
import SelectLabel from './SelectLabel.vue'

export default {
  name: 'select-dropdown',

  components: {
    SelectLabel,
  },

  props: {
    borderless: {
      type: Boolean,
      default: true,
    },

    clearable: {
      type: Boolean,
      default: false,
    },

    containerClass: {
      type: String,
      default: '',
    },

    dense: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    error: {
      type: Boolean,
      default: false,
    },

    errorMessage: {
      type: String,
      default: '',
    },

    iconSize: {
      type: String,
      default: '1em',
    },

    inputStyle: {
      type: [String, Object],
      default: '',
    },

    label: {
      type: String,
      required: false,
    },

    options: {
      type: Array,
      required: true,
    },

    outlined: {
      type: Boolean,
      default: false,
    },

    selectSize: {
      type: String,
      default: 'normal',
    },

    useInput: {
      type: Boolean,
      default: false,
    },

    value: {
      type: [Number, String],
      required: true,
    },

    width: {
      type: String,
      default: '100%',
    },
  },

  data () {
    return {
      menuOpen: false,
    }
  },

  methods: {
    handleScroll () {
      if (this.$refs.selectDropdown) {
        this.$refs.selectDropdown.hidePopup()
        this.$refs.selectDropdown.focused = false
        document.removeEventListener('scroll', this.handleScroll)
      }
    },

    listenForScroll () {
      if (this.$refs.selectDropdown) {
        document.addEventListener('scroll', this.handleScroll)
      }
    },

    selectElement (value) {
      this.$emit('click', value)
      this.$refs.selectDropdown.hidePopup()
    },
  },
}
</script>

<style lang='stylus' scoped>
  .select__dropdown
    &:hover
      .dropdown__icon,
      .dropdown__text
        color: $sh-hover-blue

  .dropdown__icon,
  .dropdown__text
    transition: color 0.2s

  .dropdown__menu
    padding: 0.5em 0.8vw
    min-height: 1em

  .background
    background-color: $sh-hover-blue

  .select__dropdown--dense
    margin-bottom: 0.2em
    padding-top: 0

    >>>.q-field__control,
    >>>.q-field__native
      min-height: 0
      padding: 0.2em 0

    >>>.q-field__append
      height: 100%

    >>>.q-field__marginal
      font-size: 2em

  .select__dropdown--regular
    font-size: 1.1rem

    >>>.q-field--outlined,
    >>>.q-field__control
      padding: 0 1vw
      min-height: 0

    >>>.q-field__native
      font-weight: bold
      height: 3rem
      min-height: 0
      line-height: 1rem
      padding: 0.2rem 0

    >>>.q-field__append
      height: 100%

    >>>.q-field__marginal
      font-size: 2rem

  .select__dropdown--medium
    >>>.q-field--outlined,
    >>>.q-field__control
      padding: 0 0.5vw
      min-height: 0

    >>>.q-field__control-container
      padding-top: 0

    >>>.q-field__native
      height: 2.6rem
      min-height: 0
      line-height: 1rem
      padding: 0.2rem 0

    >>>.q-field__append
      height: 100%

    >>>.q-field__marginal
      font-size: 1.3rem

  .dropdown__active
    color: $sh-hover-blue
    transition: transform 0.2s

  .arrow
    transition: transform 0.2s

    &.arrow--open
      transform: rotate(180deg)

    &.arrow--close
      transform: rotate(0deg)
</style>
