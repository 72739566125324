import { projectChannel } from '../../../../../socket'
import constants from 'src/store/modules/constants'
import Vue from 'vue'

const requirementAnswerKey = (productSHId, pathKey) => {
  return `${productSHId}-${pathKey}`
}

const calculateScore = (answers) => {
  const sumOfScores =
    answers.reduce((acc, answ) => acc + (answ.answerScore * (answ.priority + 1)), 0)

  const sumOfPriorities =
    answers.reduce((acc, answ) => acc + (answ.priority + 1), 0)
  
  return Math.round(sumOfScores / sumOfPriorities) || 0
}

export const state = {
  awaitingReplySHProductIds: [],
  awaitingDemoSHProductIds: [],
  awaitingPricingSHProductIds: [],
  calculatedProductScores: {},
  modalSelectedSHProductIds: [],
  modalDemoOption: false,
  modalPricingOption: false,
  hoverOverSHProductId: null,
  scorecardProductSHIds: [],
  scorecardAnswers: {},
  scorecardCalculationData: {},
  scorecardNumberOfColumns: 5,
  scorecardPage: false,
  scorecardTableSetting: constants.state.SCORECARD_EXPAND_SCORES_ONLY,
  showScorecardHelpTooltip: false,
  showScorecardContactModalDemo: false,
  showScorecardContactModalPricing: false,
  scorecardCacheDirty: true,
  scorecardContactModalProductSHId: null,
  userInteractedWithProductList: false
}

export const actions = {
  calculateProductScores ({ commit, state, rootGetters }) {
    return new Promise(resolve => {
      const reqs = rootGetters.includedRequirementsInIncludedSubGroupsInIncludedGroups
      let reqsPerPathCache = {}
      const answersByProdIds = Object.keys(state.scorecardAnswers).reduce((
        productsAnswers, currentAnswerPathKey
      ) => {
        const [prodId, reqPathKey] = currentAnswerPathKey.split('-')
        const requirement = reqsPerPathCache[reqPathKey] || reqs.find(req => {
          if (req.pathKey !== reqPathKey) { return false } 
          reqsPerPathCache[reqPathKey] = req
          return true
        })

        productsAnswers[prodId] = [
          ...productsAnswers[prodId] || [],
          {
            answerScore: state.scorecardAnswers[currentAnswerPathKey][0],
            priority: requirement.priority,
            requirementID: requirement.id
          }
        ]

        commit('updateCalculationData', {
          pathKey: reqPathKey,
          productSHId: prodId,
          score: state.scorecardAnswers[currentAnswerPathKey][0],
          priority: requirement.priority,
          requirementID: requirement.id
        })

        return productsAnswers
      }, {})
      
      const scoresByProdIds = Object.keys(answersByProdIds).reduce((
        productScores, prodId
      ) => {
        productScores[prodId] = calculateScore(answersByProdIds[prodId])
        return productScores
      }, {})

      commit('setCalculatedProductScores', scoresByProdIds)
      commit('setShowProductScoreSpinner', false, { root: true })
      resolve()
    })
  },

  downloadScorecard ({commit, dispatch}) {
    return new Promise((resolve, reject) => {
      projectChannel.push('scorecard:load', [])
        .receive('ok', payload => {
          commit('replaceScorecard', payload.selecthub_product_ids)
          commit('setAwaitingReplySHIds', payload.awaiting_reply_from_selecthub_product_ids)
          commit('setAwaitingDemoSHIds', payload.awaiting_demo_from_selecthub_product_ids)
          commit('setAwaitingPricingSHIds', payload.awaiting_pricing_from_selecthub_product_ids)
          projectChannel.push('vendor_responses_cache:load', [])
            .receive('ok', answersPayload => {
              commit('setupAnswers', answersPayload)
              commit('unmarkScorecardCacheDirty')
              dispatch('calculateImplementationData', {}, {root: true})
            })
          resolve()
        })
    })
  },

  addProductToScorecard ({commit, dispatch, state, rootState, rootGetters}, productSHId) {
    return new Promise((resolve, reject) => {
      projectChannel.push('scorecard:add_product', productSHId)
        .receive('ok', payload => {
          if (state.scorecardProductSHIds) {
            commit('markUserInteractedWithProductList')
          }
          commit('trackProductAddition', rootGetters.productBySHId(productSHId).name)

          projectChannel.push('vendor_responses_cache:load', [])
            .receive('ok', answersPayload => {
              commit('setupAnswers', answersPayload)
              commit('replaceScorecard', payload.selecthub_product_ids)
              commit('setAddingProductFalse', null, { root: true })
              dispatch('calculateProductScores')
              dispatch('calculateImplementationData', {}, {root: true})
            })
          resolve()
        })
    })
  },

  removeProductFromScorecard ({commit, dispatch, state, rootState, rootGetters}, productSHId) {
    return new Promise((resolve, reject) => {
      projectChannel.push('scorecard:remove_product', productSHId)
        .receive('ok', payload => {
          commit('trackProductRemoval', rootGetters.productBySHId(productSHId).name)
          projectChannel.push('vendor_responses_cache:load', [])
            .receive('ok', answersPayload => {
              commit('setupAnswers', answersPayload)
              commit('replaceScorecard', payload.selecthub_product_ids)
            })
          resolve()
        })
    })
  },

  downloadScorecardAnswers ({commit, dispatch}) {
    return new Promise((resolve, reject) => {
      projectChannel.push('vendor_responses_cache:load', [])
        .receive('ok', answersPayload => {
          commit('setupAnswers', answersPayload)
          commit('unmarkScorecardCacheDirty')
          dispatch('calculateProductScores')
          dispatch('calculateImplementationData', {}, {root: true})
          resolve()
        })
    })
  },

  changeScorecardTableSettings ({commit}, selectedSetting) {
    commit('setScorecardTableSetting', selectedSetting)
  },

  calculateProductScoresAsync ({commit, getters, rootGetters}, payload) {
    return new Promise((resolve, reject) => {
      rootGetters.includedRequirementsInIncludedSubGroupsInIncludedGroups.forEach(req => {
        getters.scorecardProductSHIds.forEach(pSHId => {
          const scReqAns = getters.scorecardRequirementAnswerObj(req.pathKey, pSHId)

          if (scReqAns) {
            const scReqAnsObj = {
              pathKey: req.pathKey,
              productSHId: pSHId,
              score: scReqAns[0],
              priority: req.priority,
              requirementID: req.id
            }
            commit('updateCalculationData', scReqAnsObj)
          }
        })
      })
      resolve()
    })

  },

  saveAwaitingReplySHProductIds ({commit}, selecthubProductIds) {
    projectChannel.push(
      'scorecard:config:update',
      {awaiting_reply_from_selecthub_product_ids: selecthubProductIds})
    commit('setAwaitingReplySHIds', selecthubProductIds)
  },

  submitDemoPricingModalRequest ({commit, state}) {
    const uniqArrayFromArrays = (arr) => {
      return [...new Set([].concat(...arr))]
    }

    return new Promise((resolve, reject) => {
      let awaitingDemoSHIds = state.awaitingDemoSHProductIds
      let awaitingPricingSHIds = state.awaitingPricingSHProductIds

      if (state.modalDemoOption) {
        awaitingDemoSHIds = uniqArrayFromArrays(
          [state.awaitingDemoSHProductIds, state.modalSelectedSHProductIds])
      }

      if (state.modalPricingOption) {
        awaitingPricingSHIds = uniqArrayFromArrays(
          [state.awaitingPricingSHProductIds, state.modalSelectedSHProductIds])
      }


      projectChannel.push(
        'scorecard:config:update',
        {
          awaiting_demo_from_selecthub_product_ids: awaitingDemoSHIds,
          awaiting_pricing_from_selecthub_product_ids: awaitingPricingSHIds
        })
      commit('setAwaitingDemoSHIds', awaitingDemoSHIds)
      commit('setAwaitingPricingSHIds', awaitingPricingSHIds)
      commit('clearModalAwaitingSHProductIDs')
      resolve()
    })
  }
}

export const mutations = {
  markScorecardPage (state) {
    state.scorecardPage = true
  },

  unmarkScorecardPage (state) {
    state.scorecardPage = false
  },

  replaceScorecard (state, productSHIds) {
    state.scorecardProductSHIds = productSHIds
  },

  setupAnswers (state, payload) {
    state.scorecardAnswers = payload
  },

  addScorecardProductSHId (state, productSHId) {
    state.scorecardProductSHIds.push(productSHId)
  },

  removeScorecardProductSHId (state, productSHId) {
    state.scorecardProductSHIds.push(productSHId)
    state.scorecardProductSHIds = state.scorecardProductSHIds.filter((spID) => {
      return spID.toString() !== productSHId.toString()
    })
  },

  setCalculatedProductScores (state, calculatedProductScores) {
    state.calculatedProductScores = calculatedProductScores
  },

  setScorecardTableSetting (state, selectedSetting) {
    state.scorecardTableSetting = selectedSetting
  },

  updateCalculationData (state, payload) {
    if (!Number.isInteger(payload.score)) { return }

    Vue.set(
      state.scorecardCalculationData,
      requirementAnswerKey(payload.productSHId, payload.pathKey),
      {
        answerScore: payload.score,
        priority: payload.priority,
        requirementID: payload.requirementID})
  },

  closeScorecardHelpTooltip (state) {
    state.showScorecardHelpTooltip = false
  },

  openScorecardHelpTooltip (state) {
    state.showScorecardHelpTooltip = true
  },

  markScorecardHelpTooltipSeen (state) {
    state.seenScorecardHelpTooltip = true
  },

  setAwaitingReplySHIds (state, selecthubProductIds) {
    state.awaitingReplySHProductIds = selecthubProductIds || []
  },

  setAwaitingDemoSHIds (state, selecthubProductIds) {
    state.awaitingDemoSHProductIds = selecthubProductIds
  },

  setAwaitingPricingSHIds (state, selecthubProductIds) {
    state.awaitingPricingSHProductIds = selecthubProductIds
  },

  toggleModalSelectedSHProductId (state, selecthubProductId) {
    const index = state.modalSelectedSHProductIds.findIndex(id => id === selecthubProductId)
    if (index === -1) {
      state.modalSelectedSHProductIds.push(selecthubProductId)
    //
    } else {
      state.modalSelectedSHProductIds.splice(index, 1)
    }
  },

  clearModalAwaitingSHIds (state) {
    state.modalSelectedSHProductIds = []
  },

  toggleModalDemoOption (state) {
    if (state.awaitingDemoSHProductIds.find(SHId => SHId === state.scorecardContactModalProductSHId)) { return }
    state.modalDemoOption = !state.modalDemoOption
  },

  toggleModalPricingOption (state) {
    if (state.awaitingPricingSHProductIds.find(SHId => SHId === state.scorecardContactModalProductSHId)) { return }
    state.modalPricingOption = !state.modalPricingOption
  },

  markHoverOverSHProductId (state, selecthubProductId) {
    state.hoverOverSHProductId = selecthubProductId
  },

  unmarkHoverOverSHProductId (state) {
    state.hoverOverSHProductId = null
  },

  openScorecardContactModalDemo (state, selecthubProductId) {
    state.scorecardContactModalProductSHId = selecthubProductId
    state.showScorecardContactModalDemo = true
  },

  closeScorecardContactModal (state) {
    state.showScorecardContactModalDemo = false
    state.modalDemoOption = false
    state.showScorecardContactModalPricing = false
    state.modalPricingOption = false
    state.scorecardContactModalProductSHId = null
  },

  openScorecardContactModalPricing (state, selecthubProductId) {
    state.scorecardContactModalProductSHId = selecthubProductId
    state.showScorecardContactModalPricing = true
  },

  clearModalAwaitingSHProductIDs (state) {
    state.modalAwaitingDemoSHProductIds = []
    state.modalAwaitingPricingSHProductIds = []
  },

  markScorecardCacheDirty (state) {
    state.scorecardCacheDirty = true
  },

  unmarkScorecardCacheDirty (state) {
    state.scorecardCacheDirty = false
  },

  markUserInteractedWithProductList (state) {
    state.userInteractedWithProductList = true
  },

  trackProductAddition (state, productName) {
    Vue.gtm.trackEvent({
      'event': 'gaEvent',
      'eventCategory': 'Scorecard',
      'eventAction': 'Add to',
      'eventLabel': productName
    })
  },

  trackProductRemoval (state, productName) {
    Vue.gtm.trackEvent({
      'event': 'gaEvent',
      'eventCategory': 'Scorecard',
      'eventAction': 'Remove from',
      'eventLabel': productName
    })
  }
}

export const getters = {
  getProductCalculatedScore: state => prodId => {
    return state.calculatedProductScores[prodId]
  },

  scorecardPage (state) {
    return state.scorecardPage
  },

  scorecardProductSHIds (state) {
    return state.scorecardProductSHIds
  },

  scorecardRequirementPathKeys (state, getters, rootState, rootGetters) {
    return [].concat(...rootGetters.includedRequirementsInIncludedSubGroupsInIncludedGroups
      .map(r => r.pathKey))
  },

  scorecardAnswers (state) {
    return state.scorecardAnswers
  },

  requirementAnswerFor: (state) => (reqPathKey, productSHId) => {
    return state.scorecardAnswers[requirementAnswerKey(productSHId, reqPathKey)]
  },

  scorecardCalculationData (state) {
    return state.scorecardCalculationData
  },

  scorecardTableSetting (state) {
    return state.scorecardTableSetting
  },

  scoreForProductRequirements: (state) => (productSHId, reqPathKeys) => {
    const answers = reqPathKeys
      .map(key => {
        return state.scorecardCalculationData[requirementAnswerKey(productSHId, key)]
      })
      .filter(answer => answer)

    return calculateScore(answers)
  },

  showScorecardHelpTooltip: (state) => {
    return state.showScorecardHelpTooltip
  },

  awaitingReplySHProductIds (state) {
    return state.awaitingReplySHProductIds
  },

  awaitingDemoSHProductIds (state) {
    return state.awaitingDemoSHProductIds
  },

  awaitingPricingSHProductIds (state) {
    return state.awaitingPricingSHProductIds
  },

  modalSelectedSHProductIds (state) {
    return state.modalSelectedSHProductIds
  },

  hoverOverSHProductId (state) {
    return state.hoverOverSHProductId
  },

  showScorecardContactModalDemo (state) {
    return state.showScorecardContactModalDemo
  },

  showScorecardContactModalPricing (state) {
    return state.showScorecardContactModalPricing
  },

  scorecardContactModalProductSHId (state) {
    return state.scorecardContactModalProductSHId
  },

  modalDemoOption (state) {
    return state.modalDemoOption
  },

  modalPricingOption (state) {
    return state.modalPricingOption
  },

  scorecardAnswersPresent (state) {
    return Object.keys(state.scorecardAnswers).length > 0
  },

  isScorecardFull (state) {
    return state.scorecardProductSHIds.length > 4
  },

  isAwaitingDemoOrPricingOrReply: (state, getters) => (productSHId) => {
    return getters.isAwaitingReply(productSHId) ||
      getters.isAwaitingDemo(productSHId) ||
      getters.isAwaitingPricing(productSHId)
  },

  isAwaitingReply: (state) => (productSHId) => {
    return !!state.awaitingReplySHProductIds.find(SHid => SHid === productSHId)
  },

  isAwaitingDemo: (state) => (productSHId) => {
    return !!state.awaitingDemoSHProductIds.find(SHid => SHid === productSHId)
  },

  isAwaitingPricing: (state) => (productSHId) => {
    return !!state.awaitingPricingSHProductIds.find(SHid => SHid === productSHId)
  },

  awaitingProductTooltipText: (state, getters) => (productSHId) => {
    if (getters.isAwaitingReply(productSHId)) {
      return 'This vendor will be in touch soon to give more answers.'
    //
    } else if (getters.isAwaitingDemo(productSHId) && getters.isAwaitingPricing(productSHId)) {
      return 'The vendor will be in touch soon to discuss pricing and to schedule your live demo.'
    //
    } else if (getters.isAwaitingDemo(productSHId)) {
      return 'This vendor will be in touch soon to schedule a live demo.'
    //
    } else if (getters.isAwaitingPricing(productSHId)) {
      return 'This vendor will be in touch soon to discuss pricing.'
    }
  },

  awaitingProductActionText: (state, getters) => (productSHId) => {
    if (getters.isAwaitingReply(productSHId)) {
      return 'Waiting for Vendor\'s reply'
    //
    } else if (getters.isAwaitingDemo(productSHId) && getters.isAwaitingPricing(productSHId)) {
      return 'Waiting for demo and price'
    //
    } else if (getters.isAwaitingDemo(productSHId)) {
      return 'Waiting for demo'
    //
    } else if (getters.isAwaitingPricing(productSHId)) {
      return 'Waiting for price'
    }
  },

  scorecardCacheDirty (state) {
    return state.scorecardCacheDirty
  },

  userInteractedWithProductList (state) {
    return state.userInteractedWithProductList
  },

  scorecardRequirementAnswerObj: (state, getters) => (pathKey, productSHId) => {
    return getters.requirementAnswerFor(pathKey, productSHId)
  },

  isRequestingDemo: state => productSHId => {
    return !!state.awaitingDemoSHProductIds.find(pSHId => pSHId === productSHId)
  },

  isRequestingPricing: state => productSHId => {
    return !!state.awaitingPricingSHProductIds.find(pSHId => pSHId === productSHId)
  },

  isRequestingReply: state => productSHId => {
    return !!state.awaitingReplySHProductIds.find(pSHId => pSHId === productSHId)
  }
}

export default {
  actions,
  getters,
  mutations,
  state
}
