import Vue from 'vue'
import { categoriesChannel, projectChannel } from '../../../../socket'

const state = {
  activeCategorySHId: null,
  activeCategoryDetails: {
    acronym: '',
    seo_category: {
      article_count: 0,
      url: ''
    }
  },
  activeCategoryDetailsFetchFinished: false,
  categories: false,
  currentProjectId: null,
  editedProjectId: null,
  projectScorecard: {
    awaiting_demo_from_selecthub_product_ids: [],
    awaiting_pricing_from_selecthub_product_ids: [],
    matching_products: [],
    not_matching_products: [],
    scorecard_products: []
  },
  projectDetails: {},
  projectImplementationOverview: {},
  userProjects: [],
}

const actions = {
  async archiveProject ({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      projectChannel.push('project:archive', {id: payload.id})
        .receive('ok', project => {
          commit('updateProject', project)
          resolve(project)
        })
        .receive('error', errors => {
          reject(errors[0])
        })
    })

    const updatedUserProjects = state.userProjects.filter(project => {
      return project.id !== id
    })

    commit('setUserProjects', updatedUserProjects)
  },

  fetchCategoryDetails ({ commit }, shCategoryId = null) {
    return new Promise(resolve => {
      categoriesChannel.push('category:details', {selecthub_category_id: shCategoryId})
        .receive('ok', res => {
          commit('setCategoryDetails', res)
          commit('setCategoryDetailsFetchFinished', true)
          resolve(res)
        })
    })
  },

  fetchProjectScorecard ({ commit }, projectId) {
    return new Promise((resolve, reject) => {
      projectChannel.push('project:get_scorecard', { project_id: projectId })
        .receive('ok', scorecard => {
          commit('setProjectScorecard', {...scorecard, projectId})
          resolve(scorecard)
        })
        .receive('error', err => {
          console.error(err)
          reject(err)
        })
    })
  },

  fetchUserProjectById ({ commit, state }, projectId = null) {
    return new Promise((resolve, reject) => {
      projectChannel.push('project:get', { project_id: `${projectId}` })
        .receive('ok', project => {
          commit('updateProject', project)
          resolve(project)
        })
        .receive('error', errors => {
          reject(errors[0])
        })
    })
  },

  fetchProjectDetails ({ commit }, projectId = null) {
    return new Promise((resolve, reject) => {
      projectChannel.push('project:details', { project_id: projectId })
        .receive('ok', projectDetails => {
          commit('setProjectDetails', projectDetails)
          resolve(projectDetails)
        })
        .receive('error', errors => {
          reject(errors[0])
        })
    })
  },

  fetchUserProjects ({ commit, dispatch, state }) {
    return new Promise(resolve => {
      projectChannel.push('projects:get')
        .receive('ok', async res => {
          commit('setUserProjects', res.user_projects)
          const currentProject = res.user_projects.find(project => project.id === state.currentProjectId)
          commit('setActiveCategorySHId', currentProject.selecthub_category_id)
          await dispatch('fetchCategoryDetails', currentProject.selecthub_category_id)
          resolve()
        })
    })
  },

  changeProjectChannel ({ commit, dispatch }, projectId ) {
    return new Promise(resolve => {
      projectChannel.push('project:set', projectId)
        .receive('ok', async (response) => {
          await dispatch('downloadEverything', null, { root: true })
          resolve()
        })
    })
  },

  setProjectScorecardProductSHIds ({ commit }, {projectId, selectedProductsSHIds}) {
    return new Promise((resolve, reject) => {
      projectChannel.push('scorecard:set_products', {
        project_id: projectId,
        selecthub_product_ids: selectedProductsSHIds
      })
        .receive('ok', res => {
          resolve(res)
        })
        .receive('error', errors => {
          reject(errors[0])
        })
    })
  },
}

const mutations = {
  setActiveCategorySHId (state, shCategoryId) {
    state.activeCategorySHId = shCategoryId
  },

  setCategoryDetails (state, categoryDetails) {
    state.activeCategoryDetails = categoryDetails
  },

  setCategoryDetailsFetchFinished (state, bool) {
    state.activeCategoryDetailsFetchFinished = bool
  },

  setCurrentProjectId (state, projectId) {
    state.currentProjectId = projectId
  },

  setEditedProjectId (state, projectId) {
    state.editedProjectId = projectId
  },

  setProjectDetails (state, projectDetails) {
    state.projectDetails = projectDetails
  },

  setProjectImplementationOverview (state, overview) {
    state.projectImplementationOverview = overview
  },

  setProjectScorecard (state, scorecard) {
    state.projectScorecard = scorecard
  },

  setUserProjects (state, projects) {
    state.userProjects = projects
  },

  updateProject (state, updatedProject) {
    const projectIndex = state.userProjects.findIndex(project => {
      return project.id === updatedProject.id
    })

    Vue.set(state.userProjects, projectIndex, updatedProject)
  }
}

const getters = {
  activeCategoryProjects: (state, getters) => {
    return getters.getProjectsForCategory(state.activeCategorySHId)
  },

  getArcColor: () => (score) => {
    if (score >= 90) {
      return 'sh-circle-green'
    }
    if (score >= 80 && score < 90) {
      return 'sh-circle-yellow'
    }
    if (score >= 50 && score < 80) {
      return 'sh-circle-orange'
    }
    return 'sh-circle-red'
  },

  currentProjectData: (state, getters) => {
    return getters.getProjectDataById(state.currentProjectId)
  },

  getProjectDataById: state => projectId => {
    return state.userProjects.find(project => {
      return project.id === Number(projectId)
    }) || {
      id: null,
      included_reqs: {
        by_group: [],
        by_priority: []
      }
    }
  },

  getProjectsForCategory: state => shCategoryId => {
    return state.userProjects.filter(project => {
      return project.selecthub_category_id === shCategoryId &&
        project.archived === false
    })
  },

  getProjectIncludedReqsCount: (state, getters) => projectId => {
    const project = getters.getProjectDataById(projectId)

    return project.included_reqs.by_priority.reduce((reqsCount, currentGroup) => {
      return reqsCount + currentGroup.included_reqs_count
    }, 0)
  },

  projectsCategories: state => {
    const projectsCategories = state.userProjects.map(project => {
      return {
        name: project.category_name,
        selecthub_id: project.selecthub_category_id
      }
    })

    const uniqueCategories = [...new Map(projectsCategories.map(item => [item['selecthub_id'], item])).values()]

    return uniqueCategories.sort((a, b) => a.name < b.name ? -1 : 0)
  }
}

export default {
  actions,
  getters,
  mutations,
  state
}
