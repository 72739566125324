import { projectChannel } from '../../../../../socket'

function mapProductsRatingAndReviews (products) {
  return products.map(product => {
    return Object.assign({}, product, {
      computed_rating_average: product.extended_reviews_count ? product.extended_rating_average : product.rating_average,
      computed_number_of_reviews: product.extended_reviews_count || product.reviews_count
    })
  })
}

export const state = {
  productsFetchFinished: false,
  productsMatching: [],
  productsNotMatching: [],
  productsWithoutResponses: [],
  productListPage: false,
  reviewFeedTooltipSeen: false
}

export const actions = {
  downloadProducts ({ commit }) {
    return new Promise((resolve, reject) => {
      // commit('attachMatchingProducts', productSource.products.matched_products)
      // commit('attachOtherProducts', productSource.products.other_products)
      projectChannel.push('products:filtered')
        .receive('ok', payload => {
          commit('attachMatchingProducts', payload.matched_products)
          commit('attachNotMatchingProducts', payload.unmatched_products)
          commit('markProductsFetched')
          resolve()
        })
    })
  },

  downloadProductsWithoutResponses ({commit}) {
    return new Promise((resolve) => {
      projectChannel.push('products:without_responses', [])
        .receive('ok', payload => {
          commit('attachProductsWithoutResponses', payload.products)
          resolve()
        })
    })
  },
}

export const mutations = {
  attachMatchingProducts (state, payload) {
    state.productsMatching = mapProductsRatingAndReviews(payload)
  },

  attachNotMatchingProducts (state, payload) {
    state.productsNotMatching = mapProductsRatingAndReviews(payload)
  },

  attachProductsWithoutResponses (state, payload) {
    state.productsWithoutResponses = mapProductsRatingAndReviews(payload)
  },

  markProductList (state) {
    state.productListPage = true
  },

  unmarkProductList (state) {
    state.productListPage = false
  },

  markReviewFeedTooltipSeen (state) {
    state.reviewFeedTooltipSeen = true
  },

  markProductsFetched (state) {
    state.productsFetchFinished = true
  },

  markProductsFetching (state) {
    state.productsFetchFinished = false
  }
}

export const getters = {
  productsMatching (state) {
    return state.productsMatching
  },

  productsNotMatching (state) {
    return state.productsNotMatching
  },

  productListPage (state) {
    return state.productListPage
  },

  productBySHId: (state) => (productSHId) => {
    return state.productsMatching.concat(state.productsNotMatching)
      .find(p => p.selecthub_id === productSHId)
  },

  productBySlug: state => productSlug => {
    return state.productsMatching.concat(state.productsNotMatching)
      .find(p => p.slug === productSlug)
  },

  productsNames: (_, getters) => (productIds) => {
    return productIds.map(productShId => {
      const product = getters.productBySHId(productShId)
      return product ? product.name : ''
    })
  },

  allProducts (state) {
    return state.productsMatching.concat(state.productsNotMatching)
  },

  productsWithoutResponses (state) {
    return state.productsWithoutResponses
  }
}

export default {
  actions,
  getters,
  mutations,
  state
}
