// import Vue from 'vue'
import {
  cardsChannel,
  categoriesChannel,
  projectChannel
} from "../../../../../socket";

const state = {
  cardsForModal: [],
  cardsForProject: [],
  isButtonSpinnerVisible: false,
  projectId: null,
  selectedCategorySurvey: {},
}

export const actions = {
  downloadCardsForProject ({commit, rootState}, projectSHId) {
    return new Promise(resolve => {
      cardsChannel.push('cards:get_for_project', {project_id: projectSHId})
        .receive('ok', payload => {
          commit('compileCardsForProject', payload)
          resolve()
        })
    })
  },

  fetchCategories ({ commit }) {
    return new Promise(resolve => {
      categoriesChannel.push('categories:get', {})
        .receive('ok', res => {
          commit('setCategories', res.categories)
          resolve(res.categories)
        })
    })
  },

  fetchCategorySurvey ({ commit }, shCategoryId) {
    return new Promise((resolve, reject) => {
      categoriesChannel.push('category:survey', { selecthub_category_id: shCategoryId })
        .receive('ok', res => {
          commit('setSelectedCategorySurvey', res)
          commit('compileCardsForProject', res)
          resolve()
        })
        .receive('error', err => {
          reject(err)
        })
    })
  },

  submitProject ({ commit, rootState, state }, projectPayload) {
    return new Promise(resolve => {
      // objectsForEval go to trash
      const { surveyId, objectsForEval, ...cardResponses } = state.selectedCategorySurvey

      Object.assign(cardResponses.cards, projectPayload.cards)

      const payload = {
        survey_id: surveyId,
        card_responses: cardResponses,
        selecthub_category_id: projectPayload.shCategoryId,
        contact_info: {
          first_name: rootState.currentUser.currentUser.first_name,
          primary_phone_number: rootState.layoutStore.phoneNumberPrimary,
          secondary_phone_number: rootState.layoutStore.phoneNumberSecondary,
          preferredWayToContact: rootState.layoutStore.preferredWayToContact
        },
        creator_id: rootState.currentUser.currentUser.id,
        name: projectPayload.name
      }

      projectChannel.push('project:create', payload)
        .receive('ok', project => {
          const updatedProjects = [
            ...rootState.projects.userProjects,
            project
          ]
          commit('setUserProjects', updatedProjects)
          resolve(project)
        })
    })
  },

  updateProject ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      Object.assign(state.cardsForProject, state.cardsForModal)
      projectChannel.push('project:update', {
        card_responses: state.cardsForProject,
        id: payload.id,
        name: payload.name
      })
        .receive('ok', project => {
          commit('updateProject', project, { root: true })
          resolve(project)
        })
        .receive('error', errors => {
          reject(errors[0])
        })
    })
  },

  persistCards ({ commit, state }) {
    return new Promise((resolve, reject) => {
      cardsChannel.push('cards:update', {cards: state.cardsForProject})
        .receive('ok', (payload) => {
          commit('updateMatchedRequirementCounters', payload.included_requirements_count, { root: true })
          resolve()
        })
        .receive('error', payload => {
          console.log('error', payload)
        })
    })
  },

  persistCardsForProject ({ commit, state }, projectId) {
    return new Promise((resolve, reject) => {
      cardsChannel.push('cards:update', {project_id: projectId, cards: state.cardsForProject})
        .receive('ok', () => {
          resolve()
        })
        .receive('error', payload => {
          console.log('error', payload)
        })
    })
  }
}

export const mutations = {
  compileCardsForProject (state, payload) {
    const objectsForEval = payload.objectsForEval

    payload.cards.forEach(card => {
      if (card.answersEvalFunction) {
        // eslint-disable-next-line no-unused-vars
        let objectsForEval = payload.objectsForEval
        // eslint-disable-next-line no-eval
        card.answers = eval(card.answersEvalFunction)
      }
    })

    state.cardsForProject = payload.cards
    state.cardsForModal = payload.cards.filter(card => card.required)
  },

  setButtonSpinnerVisible (state, bool) {
    state.isButtonSpinnerVisible = bool
  },

  setCards (state, cards) {
    state.cardsForProject = cards
    state.cardsForModal = cards.filter(card => card.required)
  },

  setCategories (state, categories) {
    state.categories = categories
  },

  setSelectedCategorySurvey (state, survey) {
    state.selectedCategorySurvey = survey
  },
}

export const getters = {
  editedProjectData: (state, getters, rootState) => {
    return rootState.projects.userProjects.find(project => {
      return project.id === rootState.projects.editedProjectId
    })
  }
}

export default {
  actions,
  getters,
  mutations,
  state
}
