<template>
  <div>
    <div
      class="scorecard-tr scorecard-subgroup-row"
      :class="{'scorecard-subgroup-row-fullscreen': isFullScreen}"
      @click.prevent="toggleRequirements"
    >
      <div
        class="scorecard-th scorecard-subgroup-header"
        :class="{'scorecard-subgroup-header-collapsed': !visibleRequirements}"
      >
        <div class="scorecard-subgroup-name">
          {{subgroup.name}} ({{requirementsCount}})
        </div>
        <q-icon name="r_keyboard_arrow_up"/>
      </div>
      <div
        class="scorecard-th scorecard-subgroup-score"
        v-for="(productColumn, index) in Array(numberOfColumns)"
        :key="`subgroup-${index}`"
      >
        <div
          @mouseover="markHoverOverProductColumn(index)"
          @mouseleave="unmarkHoverOverSHProductId"
        >
          <div>
            {{subGroupScores[index]}}
          </div>
        </div>
      </div>
    </div>
    <transition name="rollup">
      <div class="scorecard-subgroup-requirements-container" v-show="visibleRequirements">
        <template v-for="requirement in includedRequirements">
          <div
            class="scorecard-tr scorecard-requirement-row"
            :class="scorecardReqRowClass"
            :key="requirement.id"
          >
            <scorecard-requirement
              class="scorecard-td scorecard-requirement"
              :requirement="requirement"
            />

            <reqs-row-wrapper :requirementId="requirement.id">
              <div
                v-for="(productColumn, index) in Array(numberOfColumns)"
                class="scorecard-td scorecard-requirement-score-container"
                :key="`product-${index}`"
                @mouseover="markHoverOverProductColumn(index)"
                @mouseleave="unmarkHoverOverSHProductId"
              >
                <scorecard-product-requirement
                  v-if="scorecardAnswersPresent"
                  class="scorecard-product-requirement"
                  :requirement="requirement"
                  :index="index"
                  :numberOfColumns="numberOfColumns"
                  :productSHId="scorecardProductSHIds[index]"
                />
              </div>
            </reqs-row-wrapper>
          </div>
        </template>
      </div>
    </transition>
  </div>

</template>

<script>
  import {
    mapActions,
    mapGetters,
    mapMutations
  } from 'vuex'

  import ScorecardProductRequirement from './ScorecardProductRequirement'
  import ScorecardRequirement from './ScorecardRequirement'

  export default {
    name: 'scorecard-subgroup-requirement-list',

    components: {
      ReqsRowWrapper: () => import('./ReqsRowWrapper'),
      ScorecardProductRequirement,
      ScorecardRequirement
    },

    props: {
      subgroup: {
        type: Object,
        required: true
      }
    },

    data () {
      return {
        numberOfColumns: 5,
        visibleRequirements: true
      }
    },

    computed: {
      ...mapGetters([
        'includedRequirementsForSubGroup',
        'isFullScreen',
        'scorecardAnswersPresent',
        'scorecardProductSHIds',
        'scorecardTableSetting',
        'SCORECARD_EXPAND_ALL',
        'SCORECARD_EXPAND_SCORES_ONLY',
        'SCORECARD_SORT_ORDER_TITLE',
        'SCORECARD_SORT_ORDER_PRIORITY',
        'scoreForProductRequirements',
        'sortOrder'
      ]),

      includedRequirements () {
        var unsortedRequirements =
          this.$store.getters.includedRequirementsForSubGroup(this.subgroup.id)
        return unsortedRequirements.sort((a, b) => {
          if (this.sortOrder === this.SCORECARD_SORT_ORDER_TITLE) {
            return (a.title > b.title) ? 1 : ((a.title < b.title) ? -1 : 0)
          }
          else if (this.sortOrder === this.SCORECARD_SORT_ORDER_PRIORITY) {
            return b.priority - a.priority
          }
        })
      },

      requirementsCount () {
        return this.includedRequirements.length
      },

      scorecardReqRowClass () {
        return {
          'scorecard-requirement-row-scores-only': !this.visibleRequirements
        }
      },

      subGroupScores () {
        const reqPathKeys =
          this.includedRequirementsForSubGroup(this.subgroup.id)
            .map(r => r.pathKey)

        return this.scorecardProductSHIds
          .map(pSHId => {
            return this.scoreForProductRequirements(pSHId, reqPathKeys)
          })
      }
    },

    methods: {
      ...mapActions([
        'changeScorecardTableSettings'
      ]),

      ...mapMutations([
        'markHoverOverSHProductId',
        'unmarkHoverOverSHProductId'
      ]),

      toggleRequirements () {
        this.visibleRequirements = !this.visibleRequirements
        this.changeScorecardTableSettings('')
      },

      markHoverOverProductColumn (index) {
        this.markHoverOverSHProductId(this.scorecardProductSHIds[index])
      }
    },

    watch: {
      scorecardTableSetting: function (newVal) {
        if (newVal === this.SCORECARD_EXPAND_ALL || newVal === this.SCORECARD_EXPAND_SCORES_ONLY) {
          this.visibleRequirements = true
        }
      }
    }
  }
</script>

<style scoped lang="stylus">
@import '~variables'

  .scorecard-tr
    display: flex
    flex-direction: row
    flex-wrap: nowrap
    justify-content: flex-start

    &.scorecard-subgroup-row
      background: #E9E9E9

    &.scorecard-requirement-row
      height: 8em

      &.scorecard-requirement-row-scores-only
        height: 3em

    &:hover
      .scorecard-requirement,
      .vendor-answer-container.answer
        background: $sh-blue-1

  .scorecard-subgroup-score
    width: 15%

  .scorecard-requirement-score-container
    width: 20%

  .scorecard-th
  .scorecard-td
    display: inline-block

    &.scorecard-subgroup-header
    &.scorecard-requirement
      width: 25%

  .scorecard-subgroup-score
    text-align: center

    & > div
      width: 100%
      height: 100%
      display: flex
      justify-content: center
      align-content: center

      & > div
        align-self: center

  .scorecard-subgroup-name
    display: inline-block

  .scorecard-subgroup-header
    height: 1.8em
    font-size: 0.8em
    font-weight: 400
    padding: 0 1vw
    display: flex
    flex-direction: row
    flex-wrap: nowrap
    justify-content: space-between
    align-items: center
    cursor: pointer

  .q-icon
    transition: all 400ms linear

  &.scorecard-subgroup-header-collapsed
    .q-icon
      transform: rotate(180deg)

  .scorecard-subgroup-score:not(:empty)
  // .scorecard-requirement-score-container:not(:empty)
    border-left: 0.01vw solid #DADADA

  .scorecard-requirement-row
    border-bottom: 0.3em solid #EEEEEE

  .scorecard-requirement
    background: #FFFFFF
    padding: 0.5em 1vw

  .scorecard-subgroup-row
    height: $scorecard-subgroup-row-height
    top: "calc(%s + %s + %s)" % ($scorecard-table-header-height $scorecard-table-header-top $scorecard-group-row-height)
    position: sticky
    position: -webkit-sticky
    z-index: 99

    &.scorecard-subgroup-row-fullscreen
      top: 19.73em

</style>
