import {projectChannel, templateChannel} from '../../../../socket'

const rightDrawerFormDefault = {
  formSlug: null,
  groupId: null,
  subGroupId: null,
  requirementId: null
}

export const state = {
  addingProduct: false,
  campaign: null,
  categoryName: null,
  dataDownloaded: false,
  exclusionItem: null,
  initialRenderForUser: true,
  neverSeenScorecard: null,
  phoneNumberPrimary: '',
  phoneNumberSecondary: '',
  preferredWayToContact: null,
  purchases: {
    requirements_page: {
      xls: null
    },
    scorecard_page: {
      pdf: null,
      xls: null
    }
  },
  scorecardBannerDisabled: true,
  showEditForm: false,
  showCards: true,
  showPasswordModal: false,
  showPoliciesModal: false,
  showTemplateSettings: false,
  showTemplateSettingsConfirmation: false,
  templateID: null,
  expandedChartsPanelsInDepthReport: {
    overview: true,
  },
  exportURL: null,
  isFullScreen: false,
  templateSettingsExitDestination: {},
  isIE11: !!window.MSInputMethodContext && !!document.documentMode,
  scorecardDownloadModalOpened: false,
  showGroupListExclusionWarningModal: false,
  sidebarActiveRequirementGroupId: null,
  sidebarHoveredRequirementGroupId: null,
  leftAsideCollapsed: false,
  generatingScorecard: false,
  userScorecardLastDownloadedFiles: [],
  userClosedSidebarDialogBox: [],
  showSidebarDialogBox: null,
  userSawProductDetails: false,
  productListSettingTooltipBlocked: null,
  userUsedProductListSettingTooltip: false,
  rightDrawerForm: rightDrawerFormDefault,
  requestACallModalOpened: false,
  rightDrawerFormChanged: false,
  scorecardProdReqsRenderQueue: [],
  showProductScoresSpinner: true,
  lazyLoadIntersectionObserver: null,
  lazyLoadReadyItems: [],
  showDeleteProjectModal: false,
  allowEmptyScorecardRedirect: false,
  projectsNavigationOpened: false,
  projectModalOpened: false,
  newProjectNotificationVisible: false,
  userResearchedInDepthReport: false,
}

export const actions = {
  async downloadEverything ({ state, commit, dispatch }) {
    commit('markDataRequested')
    await dispatch('downloadCurrentUser', null, { root: true })
    await dispatch('downloadCards', null, { root: true })
    await dispatch('downloadProjectData')
    await dispatch('downloadRequirements', null, { root: true })
    await dispatch('loadSubGroups', null, { root: true })
    await dispatch('loadGroups', null, { root: true })
    await dispatch('downloadProducts', null, { root: true })
    await dispatch('downloadScorecard', null, { root: true })
    await dispatch('downloadScorecardAnswers', null, { root: true })
    await dispatch('getExportURL')
    await dispatch('initialDownload', null, { root: true })
    await dispatch('fetchUserProjects', null, { root: true })
    commit('markDataDownloaded')
  },

  downloadProjectData ({state, commit}) {
    return new Promise((resolve, reject) => {
      projectChannel.push('project:get', [])
        .receive('ok', payload => {
          commit('setABTestingTheme', payload.project.theme_config.theme || process.env.AB_TESTING_THEME, {root: true})
          commit('updateProjectInfo', payload)
          commit('setPreferredWayToContact', payload.project.contact_info.preferred_way_to_contact || null)
          commit('setPhoneNumberPrimary', payload.project.contact_info.primary_phone_number)
          commit('setPhoneNumberSecondary', payload.project.contact_info.secondary_phone_number)
          commit('setUserSeenContactBarAfterScroll', payload.project.contact_info.user_seen_contact_bar_after_scroll || false, {root: true})
          commit('setLeftAsideCollapsed', payload.project.contact_info.left_aside_collapsed)
          commit('setSeenSuggestAProductSearchInputTooltip', payload.project.contact_info.user_seen_suggest_products_search_input_tooltip || false, {root: true})
          commit('setCompleteScorecardStickyClosedProducts', payload.project.contact_info.complete_scorecard_closed_products || [], {root: true})
          commit('setUserScorecardLastDownloadedFiles', payload.project.contact_info.user_scorecard_last_downloaded_files)
          commit('setSidebarDialogBoxClosed', payload.project.contact_info.user_closed_sidebar_dialog_box || [])
          commit('setProductDetailsSeen', payload.project.contact_info.user_saw_product_details || false)
          commit('setPlatformSalesCallRequestDetails', payload.project.contact_info.call_request_details_for_platform_sales || null)
          commit('setCurrentProjectId', payload.project.id, { root: true })
          commit('setUserResearchedInDepthReport', payload.project.contact_info.user_researched_in_depth_report || false)
          resolve()
        })
    })
  },

  getExportURL ({state, commit}) {
    return new Promise((resolve, reject) => {
      templateChannel.push('template:info')
        .receive('ok', payload => {
          commit('setExportURL', payload.export_url)
          commit('setTemplateID', payload.id)
          resolve()
        })
        .receive('error', payload => {
          console.log('error', payload)
        })
    })
  },

  markScorecardSeen ({commit}) {
    return new Promise((resolve, reject) => {
      projectChannel.push('project:config:update', {visited_scorecard: true})
        .receive('ok', _payload => {
          commit('markScorecardSeen')
          resolve()
        })
    })
  },

  savePhoneNumberPrimary ({commit}, phoneNo) {
    return new Promise((resolve, reject) => {
      projectChannel.push('project:contact_info:update', {primary_phone_number: phoneNo})
      commit('setPhoneNumberPrimary', phoneNo)
    })
  },

  savePhoneNumberSecondary ({commit}, phoneNo) {
    return new Promise((resolve, reject) => {
      projectChannel.push('project:contact_info:update', {secondary_phone_number: phoneNo})
      commit('setPhoneNumberSecondary', phoneNo)
    })
  },

  savePreferredWayToContact ({commit, rootState}, preferredWayToContact) {
    projectChannel.push('project:contact_info:update', {preferred_way_to_contact: preferredWayToContact})
    commit('setPreferredWayToContact', preferredWayToContact)
    // Please leave this commented - it can help restore tracking quicker in the future.
    // if (rootState.productDetailsPage) {
    //   window.heap.track('Set preferred way to contact - Product Details')
    // }
  },

  disableScorecardBanner ({commit}) {
    projectChannel.push('project:config:update', {scorecard_banner_disabled: true})
    commit('disableScorecardBanner')
  },

  storeRequirementsXlsStripeToken ({commit, state}, tokenObj) {
    return new Promise((resolve) => {
      const purchasesObj =
        Object.assign(
          {},
          state.purchases,
          {requirements_page: {xls: {stripe_token: tokenObj}}})

      projectChannel.push('project:config:update', { purchases: purchasesObj })
      commit('setRequirementsXlsStripeToken', tokenObj)
      resolve()
    })
  },

  storeScorecardStripeToken ({commit, state}, tokenObj) {
    return new Promise((resolve) => {
      const purchasesObj =
        Object.assign(
          {},
          state.purchases,
          {
            scorecard_page: tokenObj
          }
        )

      projectChannel.push('project:config:update', { purchases: purchasesObj })
      commit('setScorecardPurchaseTokens', purchasesObj.scorecard_page)
      resolve()
    })
  },

  markUserSeenContactBarAfterScroll ({commit}) {
    projectChannel.push('project:contact_info:update', {user_seen_contact_bar_after_scroll: true})
      .receive('ok', () => {
        commit('setUserSeenContactBarAfterScroll', true, {root: true})
      })
  },

  markSeenSuggestAProductSearchInputTooltip ({commit}) {
    projectChannel.push('project:contact_info:update', {user_seen_suggest_products_search_input_tooltip: true})
      .receive('ok', () => {
        commit('setSeenSuggestAProductSearchInputTooltip', true, {root: true})
      })
  },

  toggleLeftAsideCollapsed ({commit}, bool) {
    return new Promise((resolve) => {
      projectChannel.push('project:contact_info:update', {left_aside_collapsed: bool})
        .receive('ok', () => {
          commit('setLeftAsideCollapsed', bool)
          resolve()
        })
    })
  },

  saveUserScorecardLastDownloadedFiles ({commit}, selectedOptions) {
    projectChannel.push('project:contact_info:update', {user_scorecard_last_downloaded_files: selectedOptions})
      .receive('ok', () => {
        commit('setUserScorecardLastDownloadedFiles', selectedOptions)
      })
  },

  markSidebarDialogBoxClosed ({commit, state}, location) {
    // location = productList || groupList
    const dialogBoxClosedLocations = state.userClosedSidebarDialogBox
    return new Promise((resolve) => {
      projectChannel.push('project:contact_info:update', {user_closed_sidebar_dialog_box: [...dialogBoxClosedLocations, location]})
        .receive('ok', () => {
          commit('setSidebarDialogBoxClosed', [...dialogBoxClosedLocations, location])
          resolve()
        })
    })
  },

  markProductDetailsSeen ({commit}) {
    return new Promise(resolve => {
      projectChannel.push('project:contact_info:update', {user_saw_product_details: true})
        .receive('ok', () => {
          commit('setProductDetailsSeen', true)
          resolve()
        })
    })
  },

  markUserResearchedInDepthReport ({commit}) {
    return new Promise(resolve => {
      projectChannel.push('project:contact_info:update', {user_researched_in_depth_report: true})
        .receive('ok', () => {
          commit('setUserResearchedInDepthReport', true)
          resolve()
        })
    })
  }
}

export const mutations = {
  addToScorecardProdReqsRenderQueue (state, reqId) {
    return new Promise(resolve => {
      state.scorecardProdReqsRenderQueue.push(reqId)
      resolve()
    })
  },

  removeFromScorecardProdReqsRenderQueue (state, keyToRemove) {
    return new Promise(resolve => {
      state.scorecardProdReqsRenderQueue = state.scorecardProdReqsRenderQueue.filter(itemKey => {
        return itemKey !== keyToRemove
      })
      resolve()
    })
  },

  expandPanelInDepthReport (state, payload) {
    state.expandedChartsPanelsInDepthReport = payload
  },

  markDataDownloaded (state) {
    state.dataDownloaded = true
  },

  markDataRequested (state) {
    state.dataDownloaded = false
  },

  markFullScreen (state) {
    state.isFullScreen = true
  },

  unmarkFullScreen (state) {
    state.isFullScreen = false
  },

  toggleFullScreen (state) {
    state.isFullScreen = !state.isFullScreen
  },

  updateProjectInfo (state, payload) {
    state.categoryName = payload.category_name
    state.campaign = payload.project.campaign

    if (typeof payload.project.visited_scorecard === typeof true) {
      state.neverSeenScorecard = !payload.project.visited_scorecard
    }

    if (typeof payload.project.scorecard_banner_disabled === typeof true) {
      state.scorecardBannerDisabled = payload.project.scorecard_banner_disabled
    }
    else {
      state.scorecardBannerDisabled = false
    }

    if (payload.project.purchases.requirements_page &&
      payload.project.purchases.requirements_page.xls &&
      payload.project.purchases.requirements_page.xls.stripe_token) {
      state.purchases.requirements_page.xls =
        payload.project.purchases.requirements_page.xls
    }

    if (payload.project.purchases.scorecard_page &&
      payload.project.purchases.scorecard_page.xls &&
      payload.project.purchases.scorecard_page.xls.stripe_token) {
      state.purchases.scorecard_page.xls =
        payload.project.purchases.scorecard_page.xls
    }

    if (payload.project.purchases.scorecard_page &&
      payload.project.purchases.scorecard_page.pdf &&
      payload.project.purchases.scorecard_page.pdf.stripe_token) {
      state.purchases.scorecard_page.pdf =
        payload.project.purchases.scorecard_page.pdf
    }
  },

  openEditFormDrawer (state) {
    state.showEditForm = true
  },

  closeEditFormDrawer (state) {
    state.showEditForm = false
  },

  openPasswordModal (state) {
    state.showPasswordModal = true
  },

  closePasswordModal (state) {
    state.showPasswordModal = false
  },

  setExportURL (state, exportURL) {
    state.exportURL = exportURL
  },

  setTemplateID (state, templateID) {
    state.templateID = templateID
  },

  openPoliciesModal (state) {
    state.showPoliciesModal = true
  },

  closePoliciesModal (state) {
    state.showPoliciesModal = false
  },

  openTemplateSettings (state) {
    state.showTemplateSettings = true
  },

  closeTemplateSettings (state) {
    state.showTemplateSettings = false
  },

  openTemplateSettingsConfirmation (state) {
    state.showTemplateSettingsConfirmation = true
  },

  closeTemplateSettingsConfirmation (state) {
    state.showTemplateSettingsConfirmation = false
  },

  setTemplateSettingsExitDestination (state, routeObj) {
    state.templateSettingsExitDestination = routeObj
  },

  markScorecardSeen (state) {
    state.neverSeenScorecard = false
  },

  setPreferredWayToContact (state, preferredWayToContact) {
    state.preferredWayToContact = preferredWayToContact
  },

  setPhoneNumberPrimary (state, phoneNo) {
    let phoneToSet = ''
    if (phoneNo) phoneToSet = phoneNo
    state.phoneNumberPrimary = phoneToSet
  },

  setPhoneNumberSecondary (state, phoneNo) {
    let phoneToSet = ''
    if (phoneNo) phoneToSet = phoneNo
    state.phoneNumberSecondary = phoneToSet
  },

  disableScorecardBanner (state) {
    state.scorecardBannerDisabled = true
  },

  markInitialRenderForUser (state) {
    state.initialRenderForUser = false
  },

  setRequirementsXlsStripeToken (state, tokenObj) {
    state.purchases.requirements_page.xls = {
      stripe_token: tokenObj
    }
  },

  setScorecardPurchaseTokens (state, tokens) {
    state.purchases.scorecard_page = tokens
  },

  openGroupListExclusionWarningModal (state, exclusionItem) {
    state.showGroupListExclusionWarningModal = true
    state.exclusionItem = exclusionItem
  },

  closeGroupListExclusionWarningModal (state) {
    state.showGroupListExclusionWarningModal = false
  },

  setAddingProductTrue (state) {
    state.addingProduct = true
  },

  setAddingProductFalse (state) {
    state.addingProduct = false
  },

  setScorecardDownloadModalOpened (state, bool) {
    state.scorecardDownloadModalOpened = bool
  },

  setSidebarActiveRequirementGroupId (state, groupId) {
    state.sidebarActiveRequirementGroupId = groupId
  },

  setSidebarHoveredRequirementGroupId (state, groupId) {
    state.sidebarHoveredRequirementGroupId = groupId
  },

  setLeftAsideCollapsed (state, bool) {
    state.leftAsideCollapsed = bool
  },

  setGeneratingScorecard (state, bool) {
    state.generatingScorecard = bool
  },

  setUserScorecardLastDownloadedFiles (state, fileTypes) {
    state.userScorecardLastDownloadedFiles = fileTypes
  },

  setShowSidebarDialogBox (state, location) {
    state.showSidebarDialogBox = location
  },

  setSidebarDialogBoxClosed (state, locations) {
    state.userClosedSidebarDialogBox = locations
  },

  setProductDetailsSeen (state, bool) {
    state.userSawProductDetails = bool
  },

  markProductListSettingTooltipBlocked (state, settingName) {
    state.productListSettingTooltipBlocked = settingName
  },

  markUserUsedProductListSettingTooltip (state) {
    state.userUsedProductListSettingTooltip = true
  },

  setRightDrawerForm (state, {
    formSlug,
    groupId = null,
    subGroupId = null,
    requirementId = null
  }) {
    state.rightDrawerForm = {
      formSlug,
      groupId,
      subGroupId,
      requirementId
    }
  },

  resetRightDrawerForm (state) {
    state.rightDrawerForm = rightDrawerFormDefault
  },

  setRequestACallModalOpened (state, bool) {
    state.requestACallModalOpened = bool
  },

  setRightDrawerFormChanged (state, bool) {
    state.rightDrawerFormChanged = bool
  },

  setShowProductScoreSpinner (state, bool) {
    state.showProductScoresSpinner = bool
  },

  createLazyLoadIntersectionObserver (state) {
    const observerOptions = {
      rootMargin: '25%'
    }

    const observerCallback = (entries, observer) => {
      entries.forEach(item => {
        if (!item.isIntersecting) return

        const itemRef = item.target.getAttribute('data-ref')
        state.lazyLoadIntersectionObserver.unobserve(item.target)
        state.lazyLoadReadyItems = [...new Set(
          [...state.lazyLoadReadyItems, itemRef]
        )]
      })
    }

    state.lazyLoadIntersectionObserver = new IntersectionObserver(observerCallback, observerOptions)
  },

  markLazyLoadItemRendered (state, itemSlug) {
    const filtered = state.lazyLoadReadyItems.filter(slug => {
      return slug !== itemSlug
    })

    state.lazyLoadReadyItems = filtered
  },

  setShowDeleteProjectModal (state, bool) {
    state.showDeleteProjectModal = bool
  },

  setProjectModalOpened (state, bool) {
    state.projectModalOpened = bool
  },

  setAllowEmptyScorecardRedirect (state, bool) {
    state.allowEmptyScorecardRedirect = bool
  },

  setNewProjectNotificationVisible (state, bool) {
    state.newProjectNotificationVisible = bool
  },

  setUserResearchedInDepthReport (state, bool) {
    state.userResearchedInDepthReport = bool
  },

  setProjectsNavigationOpened (state, bool) {
    state.projectsNavigationOpened = bool
  }
}

export const getters = {
  isEdge: state => {
    return !state.isIE11 && !!window.StyleMedia
  },

  isFullScreen: state => {
    return state.isFullScreen
  },

  categoryName: state => {
    return state.categoryName
  },

  checkIfIsFirstInRenderQueue: state => itemKey => {
    return state.scorecardProdReqsRenderQueue[0] === itemKey
  },

  showEditForm (state) {
    return state.showEditForm
  },

  showPasswordModal (state) {
    return state.showPasswordModal
  },

  showPoliciesModal (state) {
    return state.showPoliciesModal
  },

  showTemplateSettings (state) {
    return state.showTemplateSettings
  },

  showTemplateSettingsConfirmation (state) {
    return state.showTemplateSettingsConfirmation
  },

  exportURL (state) {
    return state.exportURL
  },

  templateSettingsExitDestination (state) {
    return state.templateSettingsExitDestination
  },

  neverSeenScorecard (state) {
    return state.neverSeenScorecard
  },

  preferredWayToContact (state) {
    return state.preferredWayToContact
  },

  phoneNumberPrimary (state) {
    return state.phoneNumberPrimary
  },

  phoneNumberSecondary (state) {
    return state.phoneNumberSecondary
  },

  scorecardBannerDisabled (state) {
    return state.scorecardBannerDisabled
  },

  initialRenderForUser (state) {
    return state.initialRenderForUser
  },

  isSeoPrerenderedScorecard () {
    return process.env.VUE_APP_IS_SEO_PRERENDERED === 'true'
  },

  isScorecardCampaign (state) {
    return state.campaign === 'free_custom_scorecard'
  },

  isProductPrecomparisonCampaign (state) {
    return state.campaign === 'product_precomparison'
  },

  isUngatedProductPrecomparisonCampaign (state) {
    return state.campaign === 'ungated_product_precomparison'
  },

  isProductComparisonCampaign (state) {
    return state.campaign === 'product_comparison'
  },

  isUngatedScorecardDownloadCampaign (state) {
    return state.campaign === 'ungated_scorecard_download'
  },

  isProductReviewsModalOpen (state) {
    return !!state.productReviewsModalProductSHId
  },

  requirementsXlsStripeToken (state) {
    const purchase = state.purchases.requirements_page.xls
    if (purchase) {
      return purchase.stripe_token
    }
  },

  getScorecardStripeToken: state => fileType => {
    const purchase = state.purchases.scorecard_page[fileType]
    if (purchase) {
      return purchase.stripe_token
    }

    return null
  },

  userChoseNoContact (state, rootState) {
    return state.preferredWayToContact === rootState.SCORECARD_PREFERRED_CONTACT_NO_CONTACT
  },

  preferredWayToContactUnset (state) {
    return state.preferredWayToContact === null
  },

  userChoseSomeContactWay (state, getters) {
    return !getters.preferredWayToContactUnset && !getters.userChoseNoContact
  },

  templateID (state) {
    return state.templateID
  }
}

export default {
  actions,
  getters,
  mutations,
  state
}
