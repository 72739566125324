export const state = {
  ABTestingTheme: '',
  animateCompleteScorecardButton: false,
  requiredTimeAfterPasswordCardPassed: false,
  contactBarPhoneEdit: false,
  firstReqWithoutAnswerThatWillAppearInViewport: null,
  productsAddedInTimeRangeCount: 0,
  showContactBar: false,
  showCompleteScorecardButton: false,
  showProductDetailsPreferredWayToContactModal: false,
  userSeenFirstReqWithoutAnswer: false,
  userSeenContactBarAfterScroll: false
}

export const mutations = {
  markRequiredTimeAfterPasswordCardPassed (state) {
    state.requiredTimeAfterPasswordCardPassed = true
  },

  setABTestingTheme (state, ABTestingTheme) {
    state.ABTestingTheme = ABTestingTheme
  },

  setAnimateCompleteScorecardButton (state, bool) {
    state.animateCompleteScorecardButton = bool
  },

  setProductsAddedInTimeRange (state, int) {
    state.productsAddedInTimeRangeCount = int
  },

  setShowContactBar (state, bool) {
    state.showContactBar = bool
  },

  setShowCompleteScorecardButton (state, bool) {
    state.showCompleteScorecardButton = bool
  },

  setShowProductDetailsPreferredWayToContactModal (state, bool) {
    state.showProductDetailsPreferredWayToContactModal = bool
  },

  setFirstReqWithoutAnswerThatWillAppearInViewport (state, string) {
    state.firstReqWithoutAnswerThatWillAppearInViewport = string
  },

  setUserSeenFirstReqWithoutAnswer (state, bool) {
    state.userSeenFirstReqWithoutAnswer = bool
  },

  setUserSeenContactBarAfterScroll (state, bool) {
    state.userSeenContactBarAfterScroll = bool
  }
}

export const getters = {
  abTestingComponentName (state, getters, rootState) {
    if (state.ABTestingTheme === rootState.constants.AB_TESTING_CONTACT_BAR_THEME_NAME) {
      return 'contact-bar-container'
    }

    if (state.ABTestingTheme === rootState.constants.AB_TESTING_PREFERRED_WAY_TO_CONTACT_MODAL_THEME_NAME) {
      return 'preferred-way-to-contact-modal'
    }

    return null
  },

  abTestingComponentCondition(state, getters, rootState, rootGetters) {
    if (getters.abTestingComponentName === 'contact-bar-container') {
      return !!rootState.scorecard.scorecardProductSHIds.length
    }

    if (getters.abTestingComponentName === 'preferred-way-to-contact-modal' && state.requiredTimeAfterPasswordCardPassed) {
      return rootGetters.preferredWayToContactUnset
    }

    return false
  },

  areThereMissingVendorAnswers (state, getters, rootState, rootGetters) {
    const includedReqsCount =
      rootGetters.includedRequirementsInIncludedSubGroupsInIncludedGroups.length
    const prodCount = rootGetters.scorecardProductSHIds.length
    const vendorAnswerRoughCount =
      Object.keys(rootGetters.scorecardAnswers).map((key) => {
        return key.split('-')[0] // getting product id from pathKey
      }).length / prodCount

    return vendorAnswerRoughCount < includedReqsCount
  },

  isVendorTheme (state, getters, rootState) {
    return state.ABTestingTheme === rootState.constants.AB_TESTING_VENDOR_THEME_NAME
  }
}

export default {
  getters,
  mutations,
  state
}
