<template>
  <div class="dropdown-ctas__single-wrapper">
    <div
      class="dropdown-ctas__single"
      :class="{empty: !cta.landing_page_url}"
      @click="openCtaModal(cta)"
    >
      <div class="cta__title">
        {{cta.label}}
      </div>

      <div
        v-if="cta.label"
        class="arrows__container"
      >
        <q-icon name="r_expand_less" />

        <q-icon name="r_expand_more" />
      </div>
    </div>

    <div
      v-if="cta.label"
      class="single__icon"
    >
      <lock-icon />
    </div>
  </div>
</template>

<script>
import {
  mapGetters,
  mapMutations
} from 'vuex'

import LockIcon from '../icons/Lock'

export default {
  name: 'dropdown-cta',

  props: {
    position: {
      type: String,
      required: true
    }
  },

  components: {
    LockIcon
  },

  computed: {
    ...mapGetters([
      'getCtaByPosition'
    ]),

    cta () {
      return this.getCtaByPosition(this.position)
    }
  },

  methods: {
    ...mapMutations([
      'openCtaModal'
    ])
  }
}
</script>

<style lang="stylus" scoped>
@import '~variables'

.dropdown-ctas__single-wrapper
  display: flex
  align-items: center
  margin-top: 0.5em
  cursor: pointer

.dropdown-ctas__single
  display: flex
  align-items: center
  height: 2.22em
  width: 75%
  margin: 0 0.6vw 0 1vw
  padding: 0 0.8vw
  border-radius: 0.3em
  font-size: 0.95em
  color: $sh-dark-7
  text-decoration: none

  &:not(.empty)
    border: 1px solid $sh-dark-14

  .arrows__container
    margin-left: auto
    transform: translateY(-0.4em)

    .q-icon
      display: block
      height: 0.4em
      font-size: 1.4em
</style>