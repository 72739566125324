<template>
  <div class="spinner">
    <q-spinner
      :color="color"
      size="3rem"
      :thickness="2"
    />
  </div>
</template>

<script>
export default {
  name: 'spinner',

  props: {
    color: {
      type: String,
      default: 'primary',
    },
  },
}
</script>

<style lang="stylus" scoped>
  .spinner
    position: relative
    display: flex
    justify-content: center
    align-items: center
</style>
