<template>
  <div
    class="scorecard-header-product-container"
    @mouseover="markHoverOverProductColumn"
    @mouseleave="unmarkHoverOverSHProductId"
    @dblclick="goToProductDetails"
  >
    <div class="product-identification">
      <div
        v-if="isSeoPrerenderedScorecard"
        class="product-logo-container"
      >
        <img
          :src="product.normal_logo"
          class="product-logo"
        >
      </div>

      <router-link
        v-else
        class="product-logo-container product-logo-container--link"
        tag="div"
        :to="{
          name: PRODUCT_DETAILS_ROUTE_NAME,
          params: {
            productSlug: product.slug
          }
        }"
      >
        <img
          :src="product.normal_logo"
          class="product-logo"
        >
      </router-link>

      <div
        v-if="!showProductCtas && !showProductCtasWhenSeoPrerendered"
        class="product-and-company-name"
      >
        {{ product.name }} by {{ product.company_name }}
      </div>

      <div
        v-else-if="showProductCtas || showProductCtasWhenSeoPrerendered"
        class="product-ctas"
      >
        <div
          :class="{'sh-btn-disabled': awaitingPricing}"
          class="sh-btn contact-modal-btn-price"
          @click="openScorecardContactModalPricing"
        >
          Price
        </div>

        <div
          :class="{'sh-btn-disabled': awaitingDemo}"
          class="sh-btn contact-modal-btn-demo"
          @click="openScorecardContactModalDemo"
        >
          Demo
        </div>
      </div>
    </div>

    <div class="product-score">
      <div
        v-if="showProductScoresSpinner"
        class="spinner-wrapper"
      >
        <spinner />
      </div>

      <product-score-svg
        v-else
        :rating="productScore"
        :meaningful-score="true"
        class="product-scoring"
      />
    </div>

    <div
      :class="{disabled: !scorecardAnswersPresent}"
      class="product-details-link"
    >
      <div v-if="isSeoPrerenderedScorecard">
        <chart-icon />
      </div>

      <router-link
        v-else
        :to="{name: 'productDetails', params: {productSlug: product.slug}}"
        @click.native="trackProductDetailsLink"
      >
        <chart-icon />
      </router-link>
    </div>
  </div>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapState
} from 'vuex'

import Notifications from 'src/mixins/notifications'
import ChartIcon from 'components/main_app/shared/ChartIcon'
import ProductScoreSvg from 'components/main_app/shared/ProductScoreSvg'
import ScorecardRemoveButton from '../scorecard_header/ScorecardRemoveButton'
import Spinner from 'components/shared/Spinner'

export default {
  name: 'scorecard-product',

  components: {
    ChartIcon,
    ProductScoreSvg,
    Spinner,
  },

  mixins: [ Notifications ],

  props: {
    productShId: {
      type: Number,
      required: true
    }
  },

  created () {
    this.$emit('productAdded')
  },

  computed: {
    ...mapGetters([
      'getProductCalculatedScore',
      'hoverOverSHProductId',
      'isAwaitingDemo',
      'isAwaitingPricing',
      'isSeoPrerenderedScorecard',
      'productBySHId',
      'scorecardAnswersPresent',
      'scorecardCalculationData',
      'scorecardContactModalProductSHId'
    ]),

    ...mapState({
      showProductScoresSpinner: state => state.layoutStore.showProductScoresSpinner,
      PRODUCT_DETAILS_ROUTE_NAME: state => state.constants.PRODUCT_DETAILS_ROUTE_NAME,
    }),

    product () {
      return this.productBySHId(this.productShId)
    },

    productScore () {
      return this.getProductCalculatedScore(this.productShId)
    },

    productColumnHovered () {
      return this.hoverOverSHProductId === this.productShId
    },

    awaitingDemo () {
      return !!this.isAwaitingDemo(this.productShId)
    },

    awaitingPricing () {
      return !!this.isAwaitingPricing(this.productShId)
    },

    seoPrerenderedScorecardCta () {
      if ('getCtaByPosition' in this.$store.getters) {
        return this.$store.getters.getCtaByPosition('demo_price_1')
      }

      return null
    },

    showProductCtas () {
      let isVendorTheme = false
      if ('isVendorTheme' in this.$store.getters) {
        isVendorTheme = this.$store.getters.isVendorTheme
      }

      return this.productColumnHovered &&
        !(this.awaitingDemo && this.awaitingPricing) &&
        !isVendorTheme &&
        !this.isSeoPrerenderedScorecard
    },

    showProductCtasWhenSeoPrerendered () {
      return this.productColumnHovered &&
        !(this.awaitingDemo && this.awaitingPricing) &&
        this.isSeoPrerenderedScorecard &&
        !!this.seoPrerenderedScorecardCta.landing_page_url
    }
  },

  methods: {
    ...mapMutations([
      'markHoverOverSHProductId',
      'unmarkHoverOverSHProductId'
    ]),

    goToProductDetails () {
      if (!this.scorecardAnswersPresent) return

      this.$router.push(`products/${this.product.slug}`)
    },

    openScorecardContactModalDemo () {
      if (this.awaitingDemo) { return }
      if (this.isSeoPrerenderedScorecard) return this.openSeoPrerenderedScorecardContactModalDemo()
      this.$store.commit('openScorecardContactModalDemo', this.productShId)

      this.$gtm.trackEvent({
        'event': 'gaEvent',
        'eventCategory': 'Generate Scorecard',
        'eventAction': 'Pricing Prompt',
        'eventLabel': 'Clicked Live Demo'
      })
    },

    openSeoPrerenderedScorecardContactModalDemo () {
      this.$store.commit('openScorecardContactModalDemo', this.productShId)
      this.$store.commit('openCtaModal', this.seoPrerenderedScorecardCta)
    },

    openScorecardContactModalPricing () {
      if (this.awaitingPricing) { return }
      if (this.isSeoPrerenderedScorecard) return this.openSeoPrerenderedScorecardContactModalPricing()
      this.$store.commit('openScorecardContactModalPricing', this.productShId)

      this.$gtm.trackEvent({
        'event': 'gaEvent',
        'eventCategory': 'Generate Scorecard',
        'eventAction': 'Pricing Prompt',
        'eventLabel': 'Clicked Pricing'
      })
    },

    openSeoPrerenderedScorecardContactModalPricing () {
      this.$store.commit('openScorecardContactModalPricing', this.productShId)
      this.$store.commit('openCtaModal', this.seoPrerenderedScorecardCta)
    },

    markHoverOverProductColumn () {
      this.markHoverOverSHProductId(this.productShId)
    },

    trackProductDetailsLink () {
      // Please leave this commented - it can help restore tracking quicker in the future.
    //   window.heap.track('Product Details link clicked - scorecard product icon')
    }
  }
}
</script>

<style lang="stylus">
  .product-action-or-status-bar
    transform: translate(0, -12px);

    .product-action
      &:hover
        .button__close
          font-weight: bold
          color: $sh-blue-3

      .button__close
        font-size: 1.4em
</style>

<style scoped lang="stylus">
@import '~variables'

  .scorecard-header-product-container
    position: relative
    background: white

  .product-action-or-status-bar
    padding: 0 0.3vw

  .product-action
  .product-awaiting-response
    height: 2em
    text-align: center

  .product-action
    font-size: 0.7em
    cursor: pointer

    .remove-action
      margin: 0 auto

  .product-awaiting-response
    color: $sh-dark-3
    font-size: 0.7em
    font-weight: 700
    padding: 0.3em 0

    &:hover
      color: $sh-blue-4

  .product-identification
    font-size: 0.8em
    font-weight: 200
    border-left: 0.01vw solid #DADADA
    display: flex;
    flex-direction: column;
    align-items: center;

    .product-logo-container
      $max-image-height = 2.5em
      width: 90%
      height: $max-image-height + 1em
      display: inline-block
      margin-top: 1em
      margin-bottom: 1em
      text-align: center
      position: relative
      padding: 0.5em 0.5vw

      &.product-logo-container--link
        cursor: pointer
        transition: all 0.2s

        &:hover
          border-radius: 5px
          box-shadow: 0px 0.2px 2px 0px #008ddd, 0 0 0 0.2px #008ddd;

      .product-logo
        display: inline-block
        max-width: 100%
        max-height: $max-image-height

    .product-and-company-name
      height: 2.5em
      padding: 0.1em 0.2vw
      font-weight: 400
      font-size: 0.8em
      text-align: center

    .product-ctas
      margin: 0 auto
      height: 2em
      width: 95%
      display: flex
      flex-flow: row
      justify-content: space-between

      .sh-btn
        border-radius: 0.3em 0.3em
        cursor: pointer
        color: white
        width: 49%
        height: 100%
        font-weight: 600
        text-align: center
        font-size: 1.1em
        line-height: 1.8em

        &.contact-modal-btn-price
          background: $sh-orange-1

          &:hover
            background: lighten($sh-orange-1, 7)

        &.contact-modal-btn-demo
          background: $sh-green-1

          &:hover
            background: lighten($sh-green-1, 7)

        &.sh-btn-disabled
          cursor: default
          background: $sh-dark-4

          &:hover
            background: $sh-dark-4

  .product-score
    background: white
    position: relative

    .spinner-wrapper,
    .product-scoring
      border-left: 0.01vw solid #DADADA

    .spinner-wrapper
      display: flex
      justify-content: center
      align-items: center
      height: 5.2em

      >>>.spinner
        border-left-color: $sh-dark-4

    .product-scoring
      font-size: 2.3em
      text-align: center

  .product-details-link
    position: absolute
    bottom: 0.2em
    left: 0.4em

    &.disabled
      pointer-events: none
</style>
