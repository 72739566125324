var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('select-label',{attrs:{"label":_vm.label,"error-message":_vm.errorMessage,"error":_vm.error}}),_c('q-select',{ref:"selectDropdown",staticClass:"select__dropdown",class:{
      'select__dropdown--dense': _vm.selectSize === 'small',
      'select__dropdown--medium': _vm.selectSize === 'medium',
      'select__dropdown--regular': _vm.selectSize === 'regular',
    },style:(_vm.inputStyle),attrs:{"borderless":_vm.borderless,"dense":_vm.dense,"disabled":_vm.disabled,"emit-value":"","error":_vm.error,"hide-bottom-space":"","hide-dropdown-icon":"","map-options":"","options":_vm.options,"outlined":_vm.outlined,"stack-label":"","value":_vm.value},on:{"popup-show":function($event){_vm.menuOpen = true},"popup-hide":function($event){_vm.menuOpen = false}},nativeOn:{"click":function($event){return _vm.listenForScroll($event)}},scopedSlots:_vm._u([{key:"selected-item",fn:function(ref){
    var opt = ref.opt;
return [_c('div',{class:{ 'dropdown__active': _vm.menuOpen }},[_vm._v(" "+_vm._s(opt.label)+" ")])]}},{key:"append",fn:function(){return [(_vm.clearable && _vm.value)?_c('q-icon',{attrs:{"name":"r_cancel","size":_vm.iconSize},on:{"click":function($event){$event.stopPropagation();return _vm.selectElement('')}}}):_vm._e(),_c('q-icon',{class:{ 'dropdown__active arrow arrow--open': _vm.menuOpen, 'arrow arrow--close': !_vm.menuOpen },attrs:{"size":_vm.iconSize,"name":"r_expand_more"}})]},proxy:true},{key:"option",fn:function(ref){
    var opt = ref.opt;
    var selected = ref.selected;
return [_c('q-item',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"dropdown__menu",attrs:{"active-class":"dropdown__background","active":selected,"clickable":""},on:{"click":function($event){return _vm.selectElement(opt.value)}}},[_c('q-item-section',[_vm._v(_vm._s(opt.label))])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }