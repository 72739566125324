<template>
  <div
    id="app"
    class="main__container"
  >
    <scorecard-toolbar :class="{'ie-11': isIE11}" />
    
    <sidebar />

    <scorecard id="prerendered-scorecard-scroll-container" />
    
    <cta-modal v-if="showCtaModal" />
  </div>
</template>

<script>
import {
  mapActions,
  mapMutations,
  mapState
} from 'vuex'

import Scorecard from 'components/main_app/scorecard/Scorecard'
import ScorecardToolbar from './components/ScorecardToolbar'
import Sidebar from './components/Sidebar'

export default {
  name: 'App',

  components: {
    CtaModal: () => import('./components/ctas/CtaModal'),
    Scorecard,
    ScorecardToolbar,
    Sidebar
  },

  created () {
    window.addEventListener('ungated_leaderboard_data_ready', this.handleDataReady)
  },

  mounted () {
    this.getAsideWidth()
    window.addEventListener('resize', this.getAsideWidth)
    
    if (this.isDevEnv) {
      window.dispatchEvent(new Event('ungated_leaderboard_data_ready'))
    }
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.getAsideWidth)
    window.removeEventListener('ungated_leaderboard_data_ready', this.handleDataReady)
  },

  data () {
    return {
      isDevEnv: process.env.NODE_ENV === 'development',
      leftAsideWidth: 0,
      sidebarOpened: true,
    }
  },

  computed: {
    ...mapState({
      isIE11: state => state.layoutStore.isIE11,
      showCtaModal: state => state.ctas.showCtaModal
    })
  },

  methods: {
    ...mapActions([
      'calculateProductScores'
    ]),

    ...mapMutations([
      'attachMatchingProducts',
      'loadGroups',
      'loadRequirements',
      'loadSubGroups',
      'markProductsFetched',
      'replaceScorecard',
      'setCtas',
      'setupAnswers'
    ]),

    getAsideWidth () {
      const vWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
      const leftSidebarWidth = vWidth * 0.15
      this.leftAsideWidth = Number(leftSidebarWidth.toFixed(2))
    },

    handleDataReady () {
      this.replaceScorecard(window.scorecard_payload.selecthub_product_ids)
      this.attachMatchingProducts(window.products_payload)
      this.markProductsFetched()
      this.setupAnswers(window.vendor_responses_payload)
      this.setCtas(window.ctas_payload)

      const groupsList = this.parseGroups(window.groups_payload)
      this.loadGroups(groupsList)
      
      const subGroupsList = this.parseSubGroups(window.subgroups_payload)
      this.loadSubGroups(subGroupsList)

      const reqsList = this.parseReqs(window.reqs_payload)
      this.loadRequirements(reqsList)
      this.calculateProductScores(false)
    },

    parseGroups (groupContainers) {
      return groupContainers.map(group => {
        return {
          name: group.name,
          included: group.included,
          id: group.id
        }
      })
    },

    parseSubGroups (subGroups) {
      return subGroups.map(subGroup => {
        return {
          id: subGroup.id,
          createdBySH: subGroup.created_by_selecthub,
          included: subGroup.included,
          name: subGroup.name,
          groupId: subGroup.leaderboard_group_id,
          position: subGroup.position
        }
      })
    },

    parseReqs (reqs) {
      return reqs.map(req => {
        return {
          id: req.id,
          description: req.description,
          included: req.included,
          priority: req.priority,
          subGroupId: req.leaderboard_subgroup_id,
          title: req.name,
          pathKey: req.path_key
        }
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
@import '~variables'

.main__container
  position: relative
  transform: scale(0.9)
  overflow: auto
  z-index: 100000

  display: grid
  height: 100vh
  width: 100%
  grid-template-columns: 15% 85%
  grid-template-rows: 9em 1fr
  grid-template-areas:
    "sidebar toolbar"\
    "sidebar scorecard"
  background: $sh-dark-1
  font-size: $express_rem
  overflow-y: hidden

  >>>.product-identification .product-and-company-name
    height: 3em !important

  >>>.product-logo-container
    height: 2.5em

  >>>.sidebar__container
    grid-area: sidebar
    z-index: 999

  >>>.scorecard-toolbar__container
    grid-area: toolbar
    width: 100%
    left: 0

    &.ie-11
      position: static
      width: 100%

    .scorecard-help-tooltip-container
      z-index: 1
      height: 60vh
      position: absolute !important

      .product-support.q-card__section.q-card__section--vert
        padding: 1em

  >>>.scorecard-page-container
    grid-area: scorecard
    overflow-y: scroll

    .scorecard-table-header
      top: 0

      &.ie-11
        position: static
        width: 100%

        &::before
          content: none

      .scorecard-options-container
        input
          transform: scale(1.1)

    .ie-11-helper
      padding-top: 0

    .scorecard-header-product-container
      .product-identification
        height: 7.3em

    .scorecard-tr.scorecard-group-row
      top: 12.2em
    
    .scorecard-tr.scorecard-subgroup-row
      top: 15em

    .scorecard-options-radios
      label
        margin-bottom: 0

    .product-scoring
      height: 2.1em

      .product-score-svg
        font-size: 1.3em
  
  >>>.scorecard-product-details
    .btn__container
      .q-btn__wrapper.row
        margin: 0

  >>>.row
    margin: 0

  >>>.scorecard-add-product
    .add__product
      font-size: 0.9em

  >>>.button__close
    padding: 0
    background-color: transparent !important

    .button__close--ico
      margin: 0
 
.main-header__container
  background: transparent
  color: black

</style>
