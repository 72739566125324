<template>
  <div>
    <div
      class="scorecard-tr scorecard-group-row"
      :class="{'scorecard-group-row-fullscreen': isFullScreen}"
      @click.prevent="toggleSubgroups"
    >
      <div
        class="scorecard-th scorecard-group-header"
        :class="{'scorecard-group-header-collapsed': !visibleSubgroups}"
      >
        <div
          class="scorecard-group-name"
        >
          {{group.name}} ({{requirementsCount}})
        </div>
        <q-icon name="r_keyboard_arrow_up"/>
      </div>
      <div
        class="scorecard-th scorecard-group-score"
        v-for="productColumn, index in Array(numberOfColumns)"
        :key="`product-column-${index}`"
      >
        <div
          @mouseover="markHoverOverProductColumn(index)"
          @mouseleave="unmarkHoverOverSHProductId"
        >
          <div>
            {{groupScores[index]}}
          </div>
        </div>
      </div>
    </div>

    <transition name="rollup">
      <div class="scorecard-group-subgroups-container" v-show="visibleSubgroups">
        <scorecard-subgroup-requirement-list
          v-for="subgroup in includedSubgroups"
          :key="subgroup.id"
          :subgroup="subgroup"
        />
      </div>
    </transition>
  </div>
</template>

<script>
  import {
    mapActions,
    mapGetters,
    mapMutations
  } from 'vuex'

  import ScorecardSubgroupRequirementList from './sub_group_requirement_list/ScorecardSubgroupRequirementList'

  export default {
    name: 'scorecard-group',

    components: {
      ScorecardSubgroupRequirementList
    },

    props: {
      group: {
        type: Object,
        required: true
      }
    },

    data () {
      return {
        numberOfColumns: 5,
        visibleSubgroups: true
      }
    },

    computed: {
      ...mapGetters([
        'includedRequirementsForGroupInIncludedSubGroups',
        'isFullScreen',
        'scorecardProductSHIds',
        'scorecardTableSetting',
        'scoreForProductRequirements',
        'SCORECARD_EXPAND_ALL',
        'SCORECARD_EXPAND_SCORES_ONLY',
        'SCORECARD_SHOW_COLLAPSED_VIEW'
      ]),

      includedSubgroups () {
        return this.$store.getters.includedSubGroups(this.group.id)
      },

      requirementsCount () {
        return this.includedRequirementsForGroupInIncludedSubGroups(this.group.id).length
      },

      groupScores () {
        const reqPathKeys =
          this.includedRequirementsForGroupInIncludedSubGroups(this.group.id).map(r => r.pathKey)

        return this.scorecardProductSHIds
          .map(pSHId => {
            return this.scoreForProductRequirements(pSHId, reqPathKeys)
          })
      }
    },

    methods: {
      ...mapActions([
        'changeScorecardTableSettings'
      ]),

      ...mapMutations([
        'markHoverOverSHProductId',
        'unmarkHoverOverSHProductId'
      ]),

      toggleSubgroups () {
        this.visibleSubgroups = !this.visibleSubgroups
        this.changeScorecardTableSettings('')
      },

      markHoverOverProductColumn (index) {
        this.markHoverOverSHProductId(this.scorecardProductSHIds[index])
      }
    },

    watch: {
      scorecardTableSetting: function (newVal) {
        if (newVal === this.SCORECARD_EXPAND_ALL) {
          this.visibleSubgroups = true
        }

        if (newVal === this.SCORECARD_EXPAND_SCORES_ONLY) {
          this.visibleSubgroups = true
        }

        if (newVal === this.SCORECARD_SHOW_COLLAPSED_VIEW) {
          this.visibleSubgroups = false
        }
      }
    }
  }
</script>

<style scoped lang="stylus">
@import '~variables'

  .scorecard-group-subgroups-container
    background-color: $sh-dark-0;

  .scorecard-tr
    display: flex
    flex-direction: row
    flex-wrap: nowrap
    justify-content: center

    &.scorecard-group-row
      background: #D7D7D7

  .scorecard-th
    display: flex
    flex-direction: row
    flex-wrap: nowrap
    align-items: center

    &.scorecard-group-score:not(:empty)
      border-left: 0.01vw solid #C9C9C9

    &.scorecard-group-score
      width: 15%
      justify-content: center
      padding: 0

      & > div
        width: 100%
        height: 100%
        display: flex
        justify-content: center
        align-content: center

        & > div
          align-self: center

    &.scorecard-group-header
      width: 25%
      padding: 0.3em 1vw
      justify-content: space-between
      height: 3em
      font-size: 0.9em
      font-weight: 500
      cursor: pointer

    .scorecard-group-name
      display: inline-block

    .q-icon
      transition: all 400ms linear

  &.scorecard-group-header-collapsed
    .q-icon
      transform: rotate(180deg)

  .scorecard-group-row
    height: $scorecard-group-row-height
    top: "calc(%s + %s)" % ($scorecard-table-header-height $scorecard-table-header-top)
    position: sticky
    position: -webkit-sticky
    z-index: 100

    &.scorecard-group-row-fullscreen
      top: 17.1em

</style>
