<template>
  <div
    class="vendor-answer-container"
    :class="containerClass"
    :ref="reqPathKeyWithProductSHId"
  >
    <template v-if="requirementAnswerObj">
      <div class="product-support-info">
        <div
          class="product-support-bar"
          :style="{width: `${this.answerScore || 0}%`, background: `rgb(${supportColor}`}"
        />
        <div class="product-support-text">
          {{answerText}}
        </div>
      </div>

      <div
        class="product-score-details"
        @click.prevent="toggleAnswerTile"
      >
        <div class="product-vendor-answer-tile" v-if="answerComments && showAnswerTile">
          <div
            class="product-vendor-answer"
            ref="overflowableAnswer"
            @mouseover="maybeShowAnswerPopup"
            @mouseleave="hideAnswerPopup"
          >
            {{answerComments}}
          </div>
          <div class="product-score">
            {{answerScore}}
          </div>
        </div>

        <div class="product-score-tile" v-else>
          <div class="product-score-large-container">
            <div class="product-score-large">
              {{answerScore}}
            </div>
          </div>

          <div class="product-score-info">
            <q-icon
              name="r_info_outline"
              v-if="answerComments"
              @mouseover.native="showAnswerPopup"
              @mouseleave.native="hideAnswerPopup"
              @click.stop="toggleAnswerTile"
            />
          </div>
        </div>
      </div>

      <div
        v-if="infoIconHovered"
        ref="vendorAnswer"
        class='vendor-answer-popup'
        :class="{'align-top': answerPopupTopAligned, 'align-right': isLastColumn}"
        @mouseleave="hideAnswerPopup"
      >
        <div
          class="vendor-answer-popup-tip-helper"
          :class="{'tip-center': showAnswerTile}"
        />
        <div class='vendor-answer-popup-header' v-if="answerComments && !showAnswerTile">
          Click the 'i' icon to extend text in the field
        </div>
        <div class='vendor-answer-popup-body'>
          {{answerComments}}
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import {
    mapActions,
    mapGetters,
    mapState
  } from 'vuex'

  import abTestingMixin from 'src/mixins/abTesting/main_app/ScorecardProductRequirement/abTestingMixin'
  const usedAbTestingMixin = process.env.VUE_APP_IS_SEO_PRERENDERED === 'true' ? {} : abTestingMixin

  export default {
    name: 'scorecard-product-requirement',

    mixins: [
      usedAbTestingMixin
    ],

    props: {
      index: {
        type: Number,
        required: false
      },

      numberOfColumns: {
        type: Number,
        required: true
      },

      productSHId: {
        type: Number,
        required: false
      },

      requirement: {
        type: Object,
        required: false,
        default: () => { return {} }
      }
    },

    created () {
      if (this._localTableSetting) {
        this.showAnswerTile = true
      }
    },

    data () {
      return {
        answerPopupTopAligned: false,
        infoIconHovered: false,
        showAnswerTile: false
      }
    },

    computed: {
      ...mapGetters([
        'findGroupBySubGroup',
        'scorecardRequirementAnswerObj',
        'scorecardTableSetting',
        'SCORECARD_EXPAND_ALL',
        'SCORECARD_EXPAND_SCORES_ONLY'
      ]),

      ...mapState({
        supportMatrix: state => state.productDetails.supportMatrix
      }),

      // TODO: Jarek 14.01.2019
      //  Refactor so it won't use indexes but keys, so it's harder to break
      answerScore () {
        if (!this.requirementAnswerObj) { return }
        return this.requirementAnswerObj[0] // BE: vendor_score.score
      },

      answerText () {
        if (!this.requirementAnswerObj) { return }
        return this.requirementAnswerObj[1] // BE: vendor_score.response
      },

      answerComments () {
        if (!this.requirementAnswerObj) { return }
        return this.requirementAnswerObj[2] // BE: vendor_comments
      },

      isLastColumn () {
        return this.index === this.numberOfColumns - 1
      },

      containerClass () {
        let isHighlighted = false
        if ('shouldHighlightMissingAnswer' in this) {
          isHighlighted = this.shouldHighlightMissingAnswer
        }

        return {
          'answer': this.requirementAnswerObj,
          'highlighted': isHighlighted
        }
      },

      requirementAnswerObj () {
        return this.scorecardRequirementAnswerObj(this.requirement.pathKey, this.productSHId)
      },

      _localTableSetting () {
        if (this.scorecardTableSetting === this.SCORECARD_EXPAND_SCORES_ONLY) {
          return false
        }

        if (this.scorecardTableSetting === this.SCORECARD_EXPAND_ALL) {
          return true
        }
      },

      reqPathKeyWithProductSHId () {
        return `${this.productSHId}-${this.requirement.pathKey}`
      },

      supportObj () {
        return this.supportMatrix.filter(supportObj => {
          return supportObj.score === this.answerScore
        })
      },

      supportColor () {
        if (this.supportObj.length && 'color' in this.supportObj[0]) {
          return this.supportObj[0].color.join(',')
        }

        return ''
      }
    },

    methods: {
      ...mapActions([
        'calculateProductScores',
        'changeScorecardTableSettings'
      ]),

      alignPopupIfNotInViewport () {
        const el = this.$refs.vendorAnswer
        const elBoundingRect = el.getBoundingClientRect()
        const elInTheViewport = elBoundingRect.top >= 0 && elBoundingRect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
        if (!elInTheViewport) {
          this.answerPopupTopAligned = true
        }
      },

      maybeShowAnswerPopup () {
        const el = this.$refs.overflowableAnswer
        this.$nextTick(() => {
          if (el.scrollHeight > el.clientHeight) {
            this.showAnswerPopup()
          }
        })
      },

      toggleAnswerTile () {
        if (!this.answerComments) { return }

        this.hideAnswerPopup()
        this.showAnswerTile = !this.showAnswerTile
        this.changeScorecardTableSettings('')
      },

      showAnswerPopup () {
        this.infoIconHovered = true
        this.$nextTick(() => {
          this.alignPopupIfNotInViewport()
        })
      },

      hideAnswerPopup () {
        this.infoIconHovered = false
        this.answerPopupTopAligned = false
      }
    },

    watch: {
      _localTableSetting (newVal) {
        if (typeof newVal !== typeof true) { return }

        this.showAnswerTile = newVal
      }
    }
  }
</script>

<style lang="stylus">
  .product-score-info
    .q-icon
      vertical-align: top
      cursor: pointer

      &:hover
        font-weight: 700
        color: $sh-blue-3

  .scorecard-requirement-row-scores-only
    .product-score-large
      margin-top: 0.4em
</style>

<style scoped lang="stylus">
@import '~variables'

  .vendor-answer-container
    display: flex
    flex-direction: column
    justify-content: space-between
    flex-wrap: nowrap
    height: 100%
    position: relative

    &.answer
      background: white
      border-left: 0.01vw solid #DADADA

    &.highlighted
      background: $contact-bar-background

  .product-support-info
    padding: 0 0.3vw
    font-size: 0.7em
    font-weight: 600
    height: 20%

    .product-support-bar
      height: 0.7em
      border-radius: 0.1em

  .product-score-details
    height: 80%
    cursor: pointer;

    .product-score-tile
      height: 100%

      .product-score-large-container
        height: 75%
        width: 100%
        display: flex
        flex-direction: row
        justify-content: center
        align-items: center

        .product-score-large
          width: 100%;
          height: 100%;
          font-size: 2em
          color: $sh-dark-3
          display: flex
          flex-flow: row
          justify-content: center
          align-items: center

      .product-score-info
        height: 25%
        display: block
        text-align: right
        color: $sh-dark-3
        padding: 0 0.5vw
        font-size: 1.2em

    .product-vendor-answer-tile
      .product-vendor-answer
        font-size: 0.75em
        font-weight: 400
        height: 5.4em
        overflow: hidden
        padding: 0.5em 0.5vw 0

      .product-score
        text-align: right
        font-size: 1.1em
        padding: 0.3em 0.5vw
        position: absolute
        bottom: 0
        right: 0

  .vendor-answer-popup
    z-index: 102
    position: absolute
    left: 0
    top: 8em
    height: auto
    width: 35vw
    transform: translateX(-37.6%)
    background-color: white
    padding: 0.7em 0.8vw
    font-weight: 600
    color: #000000
    /* border-radius */
    -webkit-border-radius: 0.3em
    -moz-border-radius: 0.3em
    border-radius: 0.3em
    /* box-shadow */
    -moz-box-shadow: 0 0 3px #000
    -webkit-box-shadow: 0 0 3px #000
    box-shadow: 0 0 3px #000

    &.align-top
      top: -1em
      left: auto
      right: -40%
      transform: translateY(-100%)

      .vendor-answer-popup-tip-helper
        position: absolute
        left: 40%
        bottom: 0.7em
        margin-bottom: 0
        transform: rotate(180deg)

    &.align-right
      right: 0
      left: auto
      transform: translateX(0)

      .vendor-answer-popup-tip-helper
        &::before,
        &::after
          left: 99%

      &.align-top
        transform: translateX(0) translateY(-100%)

        .vendor-answer-popup-tip-helper
          left: -10%

          &::before,
          &::after
            left: 6%

  .vendor-answer-popup-tip-helper
    height: 1em
    width: 100%
    position: relative
    margin-bottom: -1em

    &:after,
    &:before
      bottom: 170%
      left: 70%
      border: solid transparent
      content: " "
      height: 0
      width: 0
      position: absolute
      pointer-events: none

    &:after
      border-color: rgba(255, 255, 255, 0)
      border-bottom-color: #ffffff
      border-width: 0.3em
      margin-left: -0.3em

    &:before
      border-color: rgba(0, 0, 0, 0)
      border-bottom-color: $sh-dark-6
      border-width: 0.4em
      margin-left: -0.4em

    &.tip-center
      &:after,
      &:before
        left: 50%

  .vendor-answer-popup-header
    font-size: 0.7em
    font-style: italic
    border-bottom: 0.03em solid $sh-dark-4
    margin-bottom: 1.5em
    padding-bottom: 0.4em

  .vendor-answer-popup-body
    font-size: 0.8em

  .scorecard-requirement-score-container:nth-child(6)
    .vendor-answer-popup
      left: initial
      right: 0

    .vendor-answer-popup-tip-helper
      &:before,
      &:after
        left: calc(100% - .3em)

</style>
