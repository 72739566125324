<template>
  <div>
    <div class="scorecard-options-title">Table settings</div>
    <div class="scorecard-options-radios">
      <div>
        <input
          :id="expandAll"
          :value="expandAll"
          v-model="tableSettings"
          type="radio"
          class="scorecard-th-option-radiobutton"
        >
        <label :for="expandAll">{{ tableSettingsObj[expandAll] }}</label>
      </div>
      <div>
        <input
          :id="expandScoresOnly"
          :value="expandScoresOnly"
          v-model="tableSettings"
          type="radio"
          class="scorecard-th-option-radiobutton"
        >
        <label :for="expandScoresOnly">{{ tableSettingsObj[expandScoresOnly] }}</label>
      </div>
      <div>
        <input
          :id="showCollapsedView"
          :value="showCollapsedView"
          v-model="tableSettings"
          type="radio"
          class="scorecard-th-option-radiobutton"
        >
        <label :for="showCollapsedView">{{ tableSettingsObj[showCollapsedView] }}</label>
      </div>
    </div>
    <div class="scorecard-options-checkboxes">
      <input
        id="priority-order"
        v-model="requirementsSortOrder"
        class="scorecard-th-option-checkbox"
        type="checkbox"
        @change="requirementsSortOrderChange"
      >
      <label for="priority-order">Priority order</label>
    </div>
  </div>
</template>

<script>
  import {
    mapActions,
    mapGetters,
    mapMutations
  } from 'vuex'

  export default {
    name: 'scorecard-options',

    data () {
      return {
        expandAll: this.SCORECARD_EXPAND_ALL(),
        expandScoresOnly: this.SCORECARD_EXPAND_SCORES_ONLY(),
        showCollapsedView: this.SCORECARD_SHOW_COLLAPSED_VIEW(),
        requirementsSortOrder: false,
        tableSettingsObj: {
          [this.SCORECARD_EXPAND_ALL()]: 'Expand all details',
          [this.SCORECARD_EXPAND_SCORES_ONLY()]: 'Expand all scores',
          [this.SCORECARD_SHOW_COLLAPSED_VIEW()]: 'Collapsed view'
        }
      }
    },

    computed: {
      ...mapGetters([
        'scorecardTableSetting'
      ]),

      tableSettings: {
        get () {
          return this.scorecardTableSetting
        },
        set (selectedSetting) {
          this.changeScorecardTableSettings(selectedSetting)
          this.trackSetting(this.tableSettingsObj[selectedSetting])
        }
      }
    },

    methods: {
      ...mapActions([
        'changeScorecardTableSettings'
      ]),

      ...mapGetters([
        'SCORECARD_EXPAND_ALL',
        'SCORECARD_EXPAND_SCORES_ONLY',
        'SCORECARD_SHOW_COLLAPSED_VIEW',
        'SCORECARD_SORT_ORDER_PRIORITY',
        'SCORECARD_SORT_ORDER_TITLE'
      ]),

      ...mapMutations([
        'setSortOrder'
      ]),

      requirementsSortOrderChange () {
        if (this.requirementsSortOrder) {
          this.setSortOrder(this.SCORECARD_SORT_ORDER_PRIORITY())
        } 
        else {
          this.setSortOrder(this.SCORECARD_SORT_ORDER_TITLE())
        }
        this.trackSetting('Priority order')
      },

      trackSetting (setting) {
        this.$gtm.trackEvent({
          'event': 'gaEvent',
          'eventCategory': 'Scorecard',
          'eventAction': 'Sorting & Details',
          'eventLabel': setting
        })
      }
    }
  }
</script>

<style lang="stylus">
  .scorecard-options
    .q-btn-standard
      min-height: 2em
      padding: 0 0.5vw
      font-size: 1rem

      .q-icon
        font-size: 1.2em
        margin-left: 0.5vw

</style>

<style scoped lang="stylus">
@import '~variables'

  .scorecard-options
    background: $sh-dark-0
    height: 84%
    margin-top: 10%
    padding-bottom: 1%
    padding-left: 1vw
    display: flex
    flex-direction: column
    justify-content: space-between

  .scorecard-options-title
    font-weight: 500
    margin-top: 0.5em

  .scorecard-options-radios
    & > div
      margin-bottom: 0.5em

  .scorecard-options-radios
  .scorecard-options-checkboxes
    input + label
      margin-left: 0.5vw

  .scorecard-th-option-radiobutton,
  .scorecard-th-option-checkbox
    scale = 0.8
    -ms-transform: scale(scale)
    -webkit-transform: scale(scale)
    transform: scale(scale)
</style>
