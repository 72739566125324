<template>
  <div
    class="banner-ctas__single"
    :class="{empty: !cta.landing_page_url}"
  >
    <div class="cta__title">
      {{cta.label}}
    </div>

    <div class="cta__content">
      {{cta.text_primary}}
    </div>

    <q-btn
      class="cta__btn"
      @click="openCtaModal(cta)"
      flat
    >
      {{cta.text_secondary}}
    </q-btn>
  </div>
</template>

<script>
import {
  mapGetters,
  mapMutations
} from 'vuex'

export default {
  name: 'banner-cta',

  props: {
    position: {
      type: String,
      required: true
    }
  },

  computed: {
    ...mapGetters([
      'getCtaByPosition'
    ]),

    cta () {
      return this.getCtaByPosition(this.position)
    }
  },

  methods: {
    ...mapMutations([
      'openCtaModal'
    ])
  }
}
</script>

<style lang="stylus" scoped>
@import '~variables'

.banner-ctas__single
  min-height: 10em
  margin: 0 0.8vw 0.8em
  padding: 0.6em 0.6vw
  border-radius: 0.3em
  color: $sh-dark-12
  
  &.empty
    opacity: 0
    visibility:hidden

  &:not(.empty)
    background: #C1C1C1

    .cta__btn
      background-color: $sh-blue-8 !important
      
    &:nth-child(2)
      .cta__btn
        background-color: #37c26c !important

  .cta__title
    margin-bottom: 0.6em
    font-size: 1.1em
    font-weight: bold

  .cta__content
    margin-bottom: 1.2em
    line-height: 1.2
    font-size: 0.86em
    
  .cta__btn
    padding: 0
    width: 100%
    min-height: 0
    color: white
    text-transform: initial
    font-size: 0.8em
</style>
