<template>
  <div class="scorecard-toolbar__container">
    <scorecard-help-tooltip
      class="scorecard-help-tooltip-container"
      v-if="showScorecardHelpTooltip"
    />

    <div class="toolbar--bg-dark"></div>

    <div class="toolbar-title__container">
      Compare products
    </div>

    <div class="toolbar-ctas__container">
      <bookmark-cta />

      <icon-cta />

      <div
        class="q-icon__container"
        @mouseenter="openHelpModal"
        @mouseleave="closeHelpModal"
        @click="blockOpenedHelpTooltip"
      >
        <div class="help__icon">
          ?
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapMutations,
  mapState
} from 'vuex'

import BookmarkCta from './ctas/BookmarkCta'

export default {
  name: 'scorecard-toolbar',

  components: {
    BookmarkCta,
    IconCta: () => import('./ctas/IconCta'),
    ScorecardHelpTooltip: () => import('components/main_app/scorecard/scorecard_header/ScorecardHelpTooltip')
  },

  computed: {
    ...mapState({
      isFullScreen: state => state.layoutStore.isFullScreen,
      showScorecardHelpTooltip: state => state.scorecard.showScorecardHelpTooltip
    })
  },

  data () {
    return {    
      helpTooltipBlockedOpen: false
    }
  },

  methods: {
    ...mapMutations([
      'closeScorecardHelpTooltip',
      'openScorecardHelpTooltip'
    ]),

    blockOpenedHelpTooltip () {
      if (this.helpTooltipBlockedOpen) {
        this.closeScorecardHelpTooltip()
      }
      else {
        this.openScorecardHelpTooltip()
      }

      this.helpTooltipBlockedOpen = !this.helpTooltipBlockedOpen
    },

    closeHelpModal () {
      if (this.helpTooltipBlockedOpen) return

      this.closeScorecardHelpTooltip()
    },

    openHelpModal () {
      this.openScorecardHelpTooltip()
    }
  }
}
</script>

<style lang="stylus" scoped>
@import '~variables'

.scorecard-toolbar__container
  position: fixed
  top: 0
  left: 15%
  width: 85%
  height: $scorecard-table-header-top
  display: flex
  flex-wrap: wrap
  padding-bottom: 1.4em
  background-color: white
  z-index: 999

  .toolbar--bg-dark
    height: 2.8em
    flex: 1 100%
    margin-bottom: 1.3em
    background: $sh-dark

  .toolbar-title__container
    flex: 0 82%
    margin-left: 2vw
    font-size: 2em
    font-weight: 500

  .toolbar-ctas__container
    flex: 1
    display: flex
    justify-content: flex-end

    >>>.q-icon__container,
    .q-icon__container
      position: relative
      display: flex
      height: 2vw
      width: 2vw
      justify-content: center
      align-items: center
      margin-right: 1vw
      font-size: 1.3em
      border: 0.07em solid $sh-dark-3
      border-radius: 100%
      cursor: pointer

      &:hover
        color: $primary
        border-color: $primary
        border-width: 0.1em

      .download__icon
        display: block
        width: 70%
      
      .help__icon
        font-weight: 500
      
>>>.scorecard-help-tooltip-container
  position: absolute
  right: 1.7vw
  top: 7em
</style>
